export const userActions = {
  USER_KIND_CHANGE: "USER_KIND_CHANGE",
  USER_TYPE_CHANGE: "USER_TYPE_CHANGE",
  MAP_SEARCH_RESULTS: "MAP_SEARCH_RESULTS",
  SET_DISPLAY_NAME: "SET_DISPLAY_NAME",
  ON_USER_SELECTED: "ON_USER_SELECTED",
  ON_PHONE_CHANGE: "ON_PHONE_CHANGE",
  ON_JOB_TITLE_CHANGE: "ON_JOB_TITLE_CHANGE",
  INCIDENT_RESPONSE_TEAM_SELECTED: "INCIDENT_RESPONSE_TEAM_SELECTED",
  IS_SHOWN_TO_ASSIGNED_SITES_SELECTED: "IS_SHOWN_TO_ASSIGNED_SITES_SELECTED",
  SET_ROLES: "SET_ROLES",
  ON_ROLE_SELECTED: "ON_ROLE_SELECTED",
  ON_BUSINESS_PHONE_CHANGE: "ON_BUSINESS_PHONE_CHANGE",
  SET_EDITABLE_USER: "SET_EDITABLE_USER",
  RESET_STATE: "RESET_STATE",
  SET_IS_ACTIVE: "SET_IS_ACTIVE",
};

export const userTypes = {
  office: "office",
  site: "site",
};

export const userKind = {
  primary: "primary",
  secondary: "secondary",
};

export const userKindOptions = [
  { label: "Primary", value: userKind.primary },
  { label: "Secondary", value: userKind.secondary },
];

export const userTypesOptions = [
  { label: "Office", value: userTypes.office },
  { label: "Site", value: userTypes.site },
];

export const defaultUserState = {
  id: 0,
  objectId: "",
  selectedUserType: userTypes.office,
  selectedUserKind: [],
  name: "",
  email: "",
  phone: "",
  jobTitle: "",
  isIncidentResponseTeam: false,
  isShownToAssignedSites: false,
  isActive: true,
  role: "",
  searchResults: [],
  searchUserDropdownData: [],
  roles: [],
  rolesDropdown: [],
  selectedRole: null,
  businessPhones: "",
  selectedUser: null,
};

export const userReducer = (state: any, action: any) => {
  switch (action.type) {
    case userActions.USER_KIND_CHANGE:
      return {
        ...state,
        selectedUserKind: action.value,
      };
    case userActions.USER_TYPE_CHANGE:
      let rolesDropdownOnUserTypeChange = filterUserRoles(
        action.value,
        state.roles
      );
      return {
        ...state,
        rolesDropdown: rolesDropdownOnUserTypeChange,
        selectedRole: null,
        selectedUserType: action.value,
      };
    case userActions.SET_DISPLAY_NAME:
      return {
        ...state,
        name: action.value,
      };
    case userActions.MAP_SEARCH_RESULTS:
      let searchResults = action.value;
      let searchUserDropdownData =
        searchResults.length > 0
          ? searchResults.map((user: any) => {
              return {
                label: user.displayName,
                value: user.id,
                sublabel: user.mail,
              };
            })
          : [];
      return {
        ...state,
        searchResults,
        searchUserDropdownData,
      };
    case userActions.ON_USER_SELECTED:
      let user = state.searchResults.find(
        (user: any) => user.id === action.value
      );
      return {
        ...state,
        name: user.displayName,
        email: user.mail,
        objectId: user.id,
        phone: user.mobilePhone,
        jobTitle: user.jobTitle,
        role: user.role,
        businessPhones: user.businessPhones.join(","),
        selectedUser: user,
      };
    case userActions.ON_PHONE_CHANGE:
      return {
        ...state,
        phone: action.value,
      };
    case userActions.ON_JOB_TITLE_CHANGE:
      return {
        ...state,
        jobTitle: action.value,
      };
    case userActions.INCIDENT_RESPONSE_TEAM_SELECTED:
      return {
        ...state,
        isIncidentResponseTeam: action.value,
      };
    case userActions.IS_SHOWN_TO_ASSIGNED_SITES_SELECTED:
      return {
        ...state,
        isShownToAssignedSites: action.value,
      };
    case userActions.SET_ROLES:
      let rolesDropdown = [];
      let roles = action.value;
      if (roles) {
        rolesDropdown = filterUserRoles(state.selectedUserType, roles);
      }
      return {
        ...state,
        roles: action.value,
        rolesDropdown: rolesDropdown,
      };
    case userActions.ON_ROLE_SELECTED:
      let selectedRole = state.rolesDropdown.find(
        (item: any) => item.value === action.value.value
      );
      return {
        ...state,
        selectedRole: selectedRole,
      };
    case userActions.ON_BUSINESS_PHONE_CHANGE:
      return {
        ...state,
        businessPhones: action.value,
      };
    case userActions.SET_EDITABLE_USER:
      let editableUser = action.value;
      let editableUserRoles = editableUser.roles;
      let selectedRoles = null;
      let selectedUserType = userTypes.office;
      let rolesDropdownOnEditableUserChange = state.rolesDropdown;
      if (editableUserRoles.length > 0) {
        selectedUserType =
          editableUserRoles[0].userType === "SiteUser"
            ? userTypes.site
            : userTypes.office;
        rolesDropdownOnEditableUserChange = filterUserRoles(
          selectedUserType,
          state.roles
        );
        selectedRoles = rolesDropdownOnEditableUserChange.find(
          (item: any) => item.value === editableUserRoles[0].groupId
        );
      } 
      return {
        ...state,
        id: editableUser.id,
        selectedRole: selectedRoles,
        objectId: editableUser.objectID,
        email: editableUser.email,
        name: editableUser.displayName,
        phone: editableUser.mobilePhone,
        jobTitle: editableUser.jobTitle,
        isIncidentResponseTeam: editableUser.isIncidentResponseTeam,
        isShownToAssignedSites: editableUser.isShownInSites ?? false,
        rolesDropdown: rolesDropdownOnEditableUserChange,
        selectedUserKind: editableUser.isPrimary
          ? userKind.primary
          : editableUser.isSecondary
            ? userKind.secondary
            : [],
        selectedUserType: selectedUserType,
        businessPhones: editableUser.businessPhones.join(","),
        isActive: editableUser.isActive,
      };
    case userActions.SET_IS_ACTIVE:
      return {
        ...state,
        isActive: action.value,
      };
    case userActions.RESET_STATE:
      return defaultUserState;
    default:
      return state;
  }
};

const filterUserRoles = (selectedUserType: string, roles: any) => {
  let filteredRoles = [];
  if (selectedUserType === userTypes.office) {
    filteredRoles = roles.filter((role: any) => role.userType === 1);
  } else if (selectedUserType === userTypes.site) {
    filteredRoles = roles.filter((role: any) => role.userType === 2);
  }
  let rolesDropdown = filteredRoles.map((item: any) => {
    return {
      label: item.brAppRole,
      value: item.groupId,
    };
  });
  return rolesDropdown;
};
