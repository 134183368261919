import { Box } from "@maersk-global/community-react";
import { IMcListChangeDetail } from "@maersk-global/mds-components-core/mc-list/types";
import {
  McButtonGroup,
  McButtonGroupItem,
  McNotification,
} from "@maersk-global/mds-react-wrapper";
import { useState } from "react";
import InternalContacts from "./InternalContacts";
import ExternalContacts from "./ExternalContacts";
import EmergencyContacts from "./EmergencyContacts";
import { StaticIcon } from "../../../components/common/icons";
import { siteComponentKeys } from "../../../constants/Site";
import SiteElementLayout from "../../../components/common/siteElementLayout";
import { ContactsInfo } from "../../../constants/SiteInfo";

export default function SiteContacts({
  componentStatus,
  handleStatusChange,
  internalContactsSectionId,
}: {
  componentStatus: any;
  handleStatusChange: any;
  internalContactsSectionId: number;
}) {
  const [selectedTab, setSelectedTab] = useState("internal"); // SET DEFAULT TAB TO INTERNAL
  const [showEmergencyNotification, setEmergencyNotification] = useState(false);

  const onRecommendationsClick = (event: any) => {
    const selectedValue = event.detail.item.value;
    setSelectedTab(selectedValue);
  };

  const TabItem = ({
    value,
    title,
    selected,
    componentKey,
  }: {
    value: string;
    title: string;
    selected: boolean;
    componentKey: string;
  }) => (
    <McButtonGroupItem
      value={value}
      title={title}
      selected={selected}
      fit="small"
    >
      {title}
      {!componentStatus[componentKey].status && (
        <StaticIcon
          icon="exclamation-circle"
          size="20"
          color={
            selected
              ? "var(--mds_brand_appearance_primary_default_on-background-color)"
              : ""
          } // Change color based on selection
          style={{ marginLeft: "5px" }}
        />
      )}
    </McButtonGroupItem>
  );

  const displayWarning = () => {
    return (
      <>
        <Box display="flex" style={{ padding: "5px 0 10px 0" }}>
          <McNotification appearance="warning" fit="small">
            <Box display="flex" alignItems="center">
              <StaticIcon icon="exclamation-circle" size="20" />
              <span style={{ marginLeft: "10px" }}>
                {
                  "More information is needed for internal or external or emergency contacts. Press edit to fill out the missing information."
                }
              </span>
            </Box>
          </McNotification>
        </Box>
      </>
    );
  };
  return (
    <SiteElementLayout
      heading="Contacts"
      sectionId={internalContactsSectionId}
      popoverContent={ContactsInfo.Section_Heading}
    >
      {(!componentStatus[siteComponentKeys.internalContacts].status ||
        !componentStatus[siteComponentKeys.externalContacts].status ||
        !componentStatus[siteComponentKeys.emergencyContacts].status) &&
        displayWarning()}
      <Box display="flex">
        <McButtonGroup
          fit="small"
          listchange={(event: CustomEvent<IMcListChangeDetail>) =>
            onRecommendationsClick(event)
          }
        >
          <TabItem
            value="internal"
            title="Internal Contacts"
            selected={selectedTab === "internal"}
            componentKey={siteComponentKeys.internalContacts}
          />
          <TabItem
            value="external"
            title="External Contacts"
            selected={selectedTab === "external"}
            componentKey={siteComponentKeys.externalContacts}
          />
          <TabItem
            value="emergency"
            title="Emergency Contacts"
            selected={selectedTab === "emergency"}
            componentKey={siteComponentKeys.emergencyContacts}
          />
        </McButtonGroup>
      </Box>
      <Box>
        {selectedTab === "internal" && (
          <InternalContacts handleStatusChange={handleStatusChange} />
        )}
        {selectedTab === "external" && (
          <ExternalContacts handleStatusChange={handleStatusChange} />
        )}
        {selectedTab === "emergency" && (
          <EmergencyContacts handleStatusChange={handleStatusChange} />
        )}
      </Box>
    </SiteElementLayout>
  );
}
