import { CustomerStatus, SiteCustomer } from "../../models/SiteCustomer";
import { doGet, doPost, doPut } from "../../utils";
import { getApiUrl } from "../../utils/application-utils";

export const getSiteCustomers = (siteId: string,versionId:string): Promise<any> =>
    doGet(`${getApiUrl()}/sitekeycustomer/${siteId}/${versionId}`);
  
  export const saveSiteCustomer = (customer: SiteCustomer): Promise<any> =>
    customer.id === 0?
    doPost(`${getApiUrl()}/sitekeycustomer/create`, customer):
    doPut(`${getApiUrl()}/sitekeycustomer/update`, customer);

    export const configureSiteCustomer = (customer: CustomerStatus): Promise<any> =>
      doPut(`${getApiUrl()}/sitekeycustomer/configure`, customer);