import { doGet, doPut, doPutWithoutBody } from "../../utils";
import { getApiUrl } from "../../utils/application-utils";

export const saveFilter = (query: string): Promise<boolean> =>
  doPut(`${getApiUrl()}/dashboard/updatedashboardfilter`, query);

export const getFilteredSites = (
  pageSize: number,
  pageNumber: number,
  columns: string
): Promise<any> =>
  doGet(
    `${getApiUrl()}/dashboard/getsitedashboarddata/${pageNumber}/${pageSize}?${columns}`
  );

export const saveCustomizedColumns = (columns: string): Promise<boolean> =>
  doPutWithoutBody(
    `${getApiUrl()}/dashboard/updatedashboardtabledata`,
    columns
  );

export const getFilter = (): Promise<any> =>
  doGet(`${getApiUrl()}/dashboard/getdashboardfilter`);
