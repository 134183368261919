import { Box } from "@maersk-global/community-react";
import { useContext, useEffect, useReducer, useState } from "react";
import styled from "styled-components";
import ActionBox from "../../../components/common/actionBox";
import { loadingIndicator } from "../../../components/common/loading";
import TitleBox from "../../../components/common/titleBox";
import { toast } from "../../../components/common/toast";
import { getContinuityPlanByDependencyId } from "../../../services/Dependencies";
import {
  createOrUpdateSiteContinuityPlan,
  getSiteContinuityPlanByDependencyId,
  getSiteContinuityPlanByDependencyIdForLastVersion,
} from "../../../services/SiteContinuityPlan";
import { SiteContinuityPlan } from "../../../models/SiteContinuityPlan";
import {
  McButtonGroup,
  McButtonGroupItem,
  McIcon,
  McInput,
  McPopover,
} from "@maersk-global/mds-react-wrapper";
import {
  continuityActionTypes,
  initialState,
  reducer,
} from "./reducers/ContinuityPlan";
import AdditionalLinkText from "../../../components/common/additionalLinkText";
import { IMcListChangeDetail } from "@maersk-global/mds-components-core/mc-list/types";
import useSite from "../../../hooks/useSite";
import { Buffer } from "buffer";
import RichTextEditorSection from "./RichTextEditorSection";
import Tooltip from "../../../components/common/tooltip";
import { ModeContext } from "../SiteBCM";
import { SiteModes } from "../../../constants/SiteBCM";
import Info from "../../../components/common/info";

const buttonListConfig = {
  height: "auto",
  buttonList: [
    [
      "bold",
      "underline",
      "italic",
      "strike",
      "subscript",
      "superscript",
      "removeFormat",
    ],
    ["undo", "redo", "font", "fontSize", "formatBlock"],
    [
      "fontColor",
      "hiliteColor",
      "outdent",
      "indent",
      "align",
      "horizontalRule",
      "list",
      "table",
    ],
    ["link", "image", "fullScreen", "preview", "codeView", "print"],
  ],
};

const buttonListConfigreadOnly = {
  height: "auto",
  buttonList: [["fullScreen", "preview"]],
};

export default function SaveSiteContinuityPlan({
  dependency,
  dependencyCategoryId,
  versionId,
  archeTypeId,
  handleStatusChange,
}: Readonly<{
  dependency: any;
  dependencyCategoryId: number;
  currentSiteId: number;
  versionId: number;
  archeTypeId: number;
  handleStatusChange: () => void;
}>) {
  const htmlContent = "<p><br></p>";
  const { siteId, site } = useSite();
  const mode = useContext(ModeContext);

  const [versionState, versiondispatch] = useReducer(reducer, initialState);
  const [state, dispatch] = useReducer(reducer, initialState);

  const [fetched, setIsFetched] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [dependencyState, setDependencyState] = useState<any | null>(null);

  useEffect(() => {
    setDependencyState(dependency);
  }, [dependency]);

  const onExpand = (categoryId: number, dependencyId: number) => {
    if (!fetched) {
      versiondispatch({
        type: continuityActionTypes.SET_LOADING,
        payload: true,
      });
      getContinuityPlan(siteId, versionId, categoryId, dependencyId);
    }
  };

  const getContinuityPlan = (
    siteId: number,
    versionId: number,
    categoryId: number,
    dependencyId: number
  ) => {
    getSiteContinuityPlanByDependencyId(
      siteId,
      versionId,
      categoryId,
      dependencyId
    )
      .then((res) => {
        if (res) {
          versiondispatch({
            type: continuityActionTypes.SET_LOADING,
            payload: false,
          });
          dispatch({
            type: continuityActionTypes.SET_RECOVERY_RTO,
            payload: dependency.rtoValue,
          });
          setIsFetched(true);

          dispatch({
            type: continuityActionTypes.SET_CONTINUITY_PLAN_DESCRIPTION,
            payload: res.continuityPlanDescription || "",
          });
          dispatch({
            type: continuityActionTypes.SET_IMMEDIATE_ACTION,
            payload: res.imediateAction || "",
          });
          dispatch({
            type: continuityActionTypes.SET_OPERATIONAL_IMPACT,
            payload: res.operationalImpact || "",
          });
          dispatch({
            type: continuityActionTypes.SET_RECOVERY_DEPENDENCY,
            payload: res.recoveryDependecy || "",
          });

          dispatch({
            type: continuityActionTypes.SET_EDITED_CONTINUITY_PLAN_DESCRIPTION,
            payload: res.continuityPlanDescription || "",
          });
          dispatch({
            type: continuityActionTypes.SET_EDITED_IMMEDIATE_ACTION,
            payload: res.imediateAction || "",
          });
          dispatch({
            type: continuityActionTypes.SET_EDITED_OPERATIONAL_IMPACT,
            payload: res.operationalImpact || "",
          });
          dispatch({
            type: continuityActionTypes.SET_EDITED_RECOVERY_DEPENDENCY,
            payload: res.recoveryDependecy || "",
          });
        }
      })
      .catch((err): void => {
        toast("Error", err.message, "error");
      });
  };

  const encode = (content: string) => {
    if (content && content !== "" && content !== null) {
      return Buffer.from(content).toString("base64");
    }
    return content;
  };

  const editContinuityPlan = (categoryId: number, dependencyId: number) => {
    getContinuityPlanByDependencyId(archeTypeId, categoryId, dependencyId)
      .then((res) => {
        if (res) {
          versiondispatch({
            type: continuityActionTypes.SET_LOADING,
            payload: false,
          });
          setIsFetched(true);

          versiondispatch({
            type: continuityActionTypes.SET_CONTINUITY_PLAN_DESCRIPTION,
            payload: res.continuityPlanDescription || "",
          });
          versiondispatch({
            type: continuityActionTypes.SET_IMMEDIATE_ACTION,
            payload: res.imediateAction || "",
          });
          versiondispatch({
            type: continuityActionTypes.SET_OPERATIONAL_IMPACT,
            payload: res.operationalImpact || "",
          });
          versiondispatch({
            type: continuityActionTypes.SET_RECOVERY_DEPENDENCY,
            payload: res.recoveryDependecy || "",
          });

          versiondispatch({
            type: continuityActionTypes.SET_EDITED_CONTINUITY_PLAN_DESCRIPTION,
            payload: res.continuityPlanDescription || "",
          });
          versiondispatch({
            type: continuityActionTypes.SET_EDITED_IMMEDIATE_ACTION,
            payload: res.imediateAction || "",
          });
          versiondispatch({
            type: continuityActionTypes.SET_EDITED_OPERATIONAL_IMPACT,
            payload: res.operationalImpact || "",
          });
          versiondispatch({
            type: continuityActionTypes.SET_EDITED_RECOVERY_DEPENDENCY,
            payload: res.recoveryDependecy || "",
          });
          versiondispatch({
            type: continuityActionTypes.SET_EDITABLE,
            payload: true,
          });
          dispatch({
            type: continuityActionTypes.SET_IMMEDIATE_ACTION_KEY,
            payload: Date.now(),
          });
          dispatch({
            type: continuityActionTypes.SET_OPERATIONAL_IMPACT_KEY,
            payload: Date.now(),
          });
          dispatch({
            type: continuityActionTypes.SET_RECOVERY_DEPENDENCY_KEY,
            payload: Date.now(),
          });
          dispatch({
            type: continuityActionTypes.SET_CONTINUITY_PLAN_DESCRIPTION_KEY,
            payload: Date.now(),
          });
        }
      })
      .catch((err): void => {
        versiondispatch({
          type: continuityActionTypes.SET_EDITABLE,
          payload: true,
        });
        toast("Error", err.message, "error");
      });
  };

  const LastVersionContinuityPlan = (
    categoryId: number,
    dependencyId: number
  ) => {
    getSiteContinuityPlanByDependencyIdForLastVersion(
      siteId,
      categoryId,
      dependencyId
    )
      .then((res) => {
        if (res) {
          versiondispatch({
            type: continuityActionTypes.SET_LOADING,
            payload: false,
          });
          setIsFetched(true);

          dispatch({
            type: continuityActionTypes.SET_CONTINUITY_PLAN_DESCRIPTION_KEY,
            payload: Date.now(),
          });
          versiondispatch({
            type: continuityActionTypes.SET_CONTINUITY_PLAN_DESCRIPTION,
            payload: res.continuityPlanDescription,
          });
          dispatch({
            type: continuityActionTypes.SET_IMMEDIATE_ACTION_KEY,
            payload: Date.now(),
          });
          dispatch({
            type: continuityActionTypes.SET_OPERATIONAL_IMPACT_KEY,
            payload: Date.now(),
          });
          dispatch({
            type: continuityActionTypes.SET_RECOVERY_DEPENDENCY_KEY,
            payload: Date.now(),
          });
          versiondispatch({
            type: continuityActionTypes.SET_IMMEDIATE_ACTION,
            payload: res.imediateAction,
          });
          versiondispatch({
            type: continuityActionTypes.SET_OPERATIONAL_IMPACT,
            payload: res.operationalImpact,
          });
          versiondispatch({
            type: continuityActionTypes.SET_RECOVERY_DEPENDENCY,
            payload: res.recoveryDependecy,
          });

          versiondispatch({
            type: continuityActionTypes.SET_EDITED_CONTINUITY_PLAN_DESCRIPTION,
            payload: res.continuityPlanDescription,
          });
          versiondispatch({
            type: continuityActionTypes.SET_EDITED_IMMEDIATE_ACTION,
            payload: res.imediateAction,
          });
          versiondispatch({
            type: continuityActionTypes.SET_EDITED_OPERATIONAL_IMPACT,
            payload: res.operationalImpact,
          });
          versiondispatch({
            type: continuityActionTypes.SET_EDITED_RECOVERY_DEPENDENCY,
            payload: res.recoveryDependecy,
          });
          versiondispatch({
            type: continuityActionTypes.SET_EDITABLE,
            payload: true,
          });
        }
      })
      .catch((err): void => {
        versiondispatch({
          type: continuityActionTypes.SET_EDITABLE,
          payload: true,
        });
        toast("Error", err.message, "error");
      });
  };

  const setCancelled = () => {
    dispatch({
      type: continuityActionTypes.SET_EDITED_CONTINUITY_PLAN_DESCRIPTION,
      payload: state.continuityPlanDescription,
    });
    dispatch({
      type: continuityActionTypes.SET_EDITED_IMMEDIATE_ACTION,
      payload: state.immediateAction,
    });
    dispatch({
      type: continuityActionTypes.SET_EDITED_OPERATIONAL_IMPACT,
      payload: state.operationalImpact,
    });
    dispatch({
      type: continuityActionTypes.SET_EDITED_RECOVERY_DEPENDENCY,
      payload: state.recoveryDependency,
    });

    versiondispatch({
      type: continuityActionTypes.SET_EDITABLE,
      payload: false,
    });
  };

  const onSave = () => {
    let areAllsectionFilled = false;
    let continuityPlan: SiteContinuityPlan = {
      siteId: siteId,
      dependencyCategoryId: dependencyCategoryId,
      dependencyId: dependency.id,
      continuityPlan: {
        operationalImpact: encode(
          state.editedOperationalImpact === htmlContent
            ? ""
            : state.editedOperationalImpact
        ),
        imediateAction: encode(
          state.editedImmediateAction === htmlContent
            ? ""
            : state.editedImmediateAction
        ),
        continuityPlanDescription: encode(
          state.editedContinuityPlanDescription === htmlContent
            ? ""
            : state.editedContinuityPlanDescription
        ),
        recoveryDependecy: encode(
          state.editedRecoveryDependency === htmlContent
            ? ""
            : state.editedRecoveryDependency
        ),
      },
    };

    const sizeInMb =
      new Blob([JSON.stringify(continuityPlan)]).size / 1024 / 1024;
    if (sizeInMb > 10) {
      toast("Error", "Payload size should not be greater than 10MB", "error");
      return;
    }

    createOrUpdateSiteContinuityPlan(continuityPlan)
      .then((res) => {
        if (res) {
          toast("Success", "Continuity Plan Saved successfully.", "success");
          dispatch({
            type: continuityActionTypes.SET_OPERATIONAL_IMPACT,
            payload: state.editedOperationalImpact,
          });
          dispatch({
            type: continuityActionTypes.SET_CONTINUITY_PLAN_DESCRIPTION,
            payload: state.editedContinuityPlanDescription,
          });
          dispatch({
            type: continuityActionTypes.SET_IMMEDIATE_ACTION,
            payload: state.editedImmediateAction,
          });
          dispatch({
            type: continuityActionTypes.SET_RECOVERY_DEPENDENCY,
            payload: state.editedRecoveryDependency,
          });
          versiondispatch({
            type: continuityActionTypes.SET_EDITABLE,
            payload: false,
          });
          handleStatusChange();
          if (
            continuityPlan.continuityPlan.imediateAction != "" &&
            continuityPlan.continuityPlan.continuityPlanDescription != "" &&
            continuityPlan.continuityPlan.operationalImpact != "" &&
            continuityPlan.continuityPlan.recoveryDependecy != ""
          ) {
            //setIsSaved(true);
            dependencyState.doesContinuityPlanExist = true;
          } else {
            dependencyState.doesContinuityPlanExist = false;
          }
        }
      })
      .catch((err): void => {
        toast("error", err.message, "error");
      });
  };

  ///TODO:SSS -> Replace with common info component.
  const renderProcessPopOver = (dependency: any) => {
    return (
      <McPopover
        trigger="hover"
        actionkeys={["Enter", "Space"]}
        width="auto"
        modalmode="none"
        opendelay={50}
      >
        <McIcon slot="trigger" icon="info-circle"></McIcon>
        <div
          style={{
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "0.5em",
          }}
        >
          <p>
            {dependency.name} is mapped in the following processes and
            subprocesses:
          </p>
          {dependency.mappedProcesses?.map((process: any) => (
            <div key={process.id}>
              <strong>{process.name}</strong> [Rto : {process.rto}]
              {process.subProcesses?.map((subProcess: any) => (
                <div key={subProcess.id} style={{ marginLeft: "1em" }}>
                  {subProcess.name} [Rto : {subProcess.rto}]
                </div>
              ))}
            </div>
          ))}
          Recovery RTO : <strong>{dependency.rtoValue}</strong>
        </div>
      </McPopover>
    );
  };

  const onRecommendationsClick = (
    event: any,
    dependencyCategoryId: number,
    dependencyId: number
  ) => {
    if (event.detail.item.value === "Master Data") {
      editContinuityPlan(dependencyCategoryId, dependencyId);
      versiondispatch({
        type: continuityActionTypes.SET_ISMATERDATA,
        payload: true,
      });
    } else if (event.detail.item.value === "Previous Version") {
      LastVersionContinuityPlan(dependencyCategoryId, dependencyId);
      versiondispatch({
        type: continuityActionTypes.SET_ISMATERDATA,
        payload: false,
      });
    }
  };

  const onCopyBCPClick = (dependency: string) => {
    switch (dependency) {
      case "businessContinuityPlan":
        dispatch({
          type: continuityActionTypes.SET_EDITED_CONTINUITY_PLAN_DESCRIPTION,
          payload: versionState.continuityPlanDescription,
        });
        dispatch({
          type: continuityActionTypes.SET_CONTINUITY_PLAN_DESCRIPTION_KEY,
          payload: Date.now(),
        });
        break;
      case "operationalImpact":
        dispatch({
          type: continuityActionTypes.SET_EDITED_OPERATIONAL_IMPACT,
          payload: versionState.operationalImpact,
        });
        dispatch({
          type: continuityActionTypes.SET_OPERATIONAL_IMPACT_KEY,
          payload: Date.now(),
        });
        break;
      case "imediateAction":
        dispatch({
          type: continuityActionTypes.SET_EDITED_IMMEDIATE_ACTION,
          payload: versionState.immediateAction,
        });
        dispatch({
          type: continuityActionTypes.SET_IMMEDIATE_ACTION_KEY,
          payload: Date.now(),
        });
        break;
      case "recoveryDependency":
        dispatch({
          type: continuityActionTypes.SET_EDITED_RECOVERY_DEPENDENCY,
          payload: versionState.recoveryDependency,
        });
        dispatch({
          type: continuityActionTypes.SET_RECOVERY_DEPENDENCY_KEY,
          payload: Date.now(),
        });
        break;
      case "all":
        dispatch({
          type: continuityActionTypes.SET_EDITED_CONTINUITY_PLAN_DESCRIPTION,
          payload: versionState.continuityPlanDescription,
        });
        dispatch({
          type: continuityActionTypes.SET_EDITED_OPERATIONAL_IMPACT,
          payload: versionState.operationalImpact,
        });
        dispatch({
          type: continuityActionTypes.SET_EDITED_IMMEDIATE_ACTION,
          payload: versionState.immediateAction,
        });
        dispatch({
          type: continuityActionTypes.SET_EDITED_RECOVERY_DEPENDENCY,
          payload: versionState.recoveryDependency,
        });
        dispatch({
          type: continuityActionTypes.SET_CONTINUITY_PLAN_DESCRIPTION_KEY,
          payload: Date.now(),
        });
        dispatch({
          type: continuityActionTypes.SET_OPERATIONAL_IMPACT_KEY,
          payload: Date.now(),
        });
        dispatch({
          type: continuityActionTypes.SET_IMMEDIATE_ACTION_KEY,
          payload: Date.now(),
        });
        dispatch({
          type: continuityActionTypes.SET_RECOVERY_DEPENDENCY_KEY,
          payload: Date.now(),
        });
        break;
      default:
        break;
    }
  };

  return (
    <TitleBox
      title={dependency.name}
      renderComponent={renderProcessPopOver(dependency)}
      key={dependency.id}
      onExpand={() => onExpand(dependencyCategoryId, dependency.id)}
      id={dependency.id}
      keyName={dependencyCategoryId.toString()}
      shouldHandleExpansion={true}
      shouldLoadOnExpansion={true}
      shouldShowButtons={mode == SiteModes.Write}
      label="Edit"
      displayWarning={!dependency.doesContinuityPlanExist && !isSaved}
      onCreate={() => {
        if (fetched) {
          editContinuityPlan(dependencyCategoryId, dependency.id);
        }
      }}
    >
      {versionState.loading ? (
        loadingIndicator
      ) : (
        <ContinuityPlanContainer key={dependencyCategoryId}>
          {!versionState.editable && (
            <>
              <LabelWrapper>Operational Impact</LabelWrapper>
              <ContinuityPlanContentContainer
                display="grid"
                gridTemplateColumns="1fr"
              >
                <ContinuityPlanContentWrapper
                  dangerouslySetInnerHTML={{
                    __html: state.editedOperationalImpact,
                  }}
                />
              </ContinuityPlanContentContainer>
              <LabelWrapper>Immediate Actions</LabelWrapper>
              <ContinuityPlanContentContainer
                display="grid"
                gridTemplateColumns="1fr"
              >
                <ContinuityPlanContentWrapper
                  dangerouslySetInnerHTML={{
                    __html: state.editedImmediateAction,
                  }}
                />
              </ContinuityPlanContentContainer>
              <LabelWrapper>Business Continuity Plan</LabelWrapper>
              <ContinuityPlanContentContainer
                display="grid"
                gridTemplateColumns="1fr"
              >
                <ContinuityPlanContentWrapper
                  dangerouslySetInnerHTML={{
                    __html: state.editedContinuityPlanDescription,
                  }}
                />
              </ContinuityPlanContentContainer>
              <LabelWrapper>Recovery dependency</LabelWrapper>
              <ContinuityPlanContentContainer
                display="grid"
                gridTemplateColumns="1fr"
              >
                <ContinuityPlanContentWrapper
                  dangerouslySetInnerHTML={{
                    __html: state.editedRecoveryDependency,
                  }}
                />
              </ContinuityPlanContentContainer>
              <LabelWrapper>
                Recovery RTO
                <Info
                  popoverContent="Recovery RTO will be the least RTO of processes that are mapped to this dependency."
                  tooltipText={null}
                >
                  <span>Info</span>
                </Info>
              </LabelWrapper>

              <Box display="flex">
                <McInput
                  disabled={true}
                  fit="medium"
                  id="recoveryRTO"
                  hiddenlabel={true}
                  width="10px"
                  maxlength={50}
                  placeholder=""
                  required
                  type="text"
                  value={state.recoveryRTO}
                  variant="default"
                  key={dependency.id}
                />
              </Box>
            </>
          )}
          {versionState.editable && (
            <>
              <Box mt={6} display="flex">
                <McButtonGroup
                  listchange={(event: CustomEvent<IMcListChangeDetail>) =>
                    onRecommendationsClick(
                      event,
                      dependencyCategoryId,
                      dependency.id
                    )
                  }
                >
                  <McButtonGroupItem
                    value="Master Data"
                    selected={versionState.isMasterdata}
                    title="Continuity plan on the Archetype Level"
                  >
                    Master Data
                  </McButtonGroupItem>
                  <McButtonGroupItem
                    value="Previous Version"
                    selected={!versionState.isMasterdata}
                    disabled={site.siteVersions.length <= 1}
                    title="Continuity plan on the Previous Version"
                  >
                    Previous Version
                  </McButtonGroupItem>
                </McButtonGroup>
                <Box display="flex" mt={10} ml={10}>
                  <AdditionalLinkText
                    additionalText={"copy all content to new version"}
                    id={"copy_all_content"}
                    onAdditionalTextClick={() => onCopyBCPClick("all")}
                  />
                  <Tooltip content="Click here to Copy all content to new version">
                    <McIcon slot="trigger" icon="info-circle"></McIcon>
                  </Tooltip>
                </Box>
              </Box>
              <Box display="grid" key={dependencyCategoryId}>
                <RichTextEditorSection
                  id="operationalImpact"
                  name="operationalImpact"
                  keyProp={state.operationalImpactkey}
                  onChange={(e: any) =>
                    dispatch({
                      type: continuityActionTypes.SET_EDITED_OPERATIONAL_IMPACT,
                      payload: e,
                    })
                  }
                  setOptions={buttonListConfig}
                  setOptionsVersion={buttonListConfigreadOnly}
                  width="100%"
                  readOnly={true}
                  versionValue={versionState.editedOperationalImpact}
                  value={state.editedOperationalImpact}
                  buttonClick={() => onCopyBCPClick("operationalImpact")}
                  heading="Operational Impact"
                />
                <RichTextEditorSection
                  id="imediateAction"
                  name="imediateAction"
                  keyProp={state.immediateActionkey}
                  onChange={(e: any) =>
                    dispatch({
                      type: continuityActionTypes.SET_EDITED_IMMEDIATE_ACTION,
                      payload: e,
                    })
                  }
                  setOptions={buttonListConfig}
                  setOptionsVersion={buttonListConfigreadOnly}
                  width="100%"
                  readOnly={true}
                  versionValue={versionState.editedImmediateAction}
                  value={state.editedImmediateAction}
                  buttonClick={() => onCopyBCPClick("imediateAction")}
                  heading="Immediate Actions"
                />
                <RichTextEditorSection
                  id="businessContinuityPlan"
                  name="businessContinuityPlan"
                  keyProp={state.continuityPlanDescriptionkey}
                  onChange={(e: any) =>
                    dispatch({
                      type: continuityActionTypes.SET_EDITED_CONTINUITY_PLAN_DESCRIPTION,
                      payload: e,
                    })
                  }
                  setOptions={buttonListConfig}
                  setOptionsVersion={buttonListConfigreadOnly}
                  width="100%"
                  readOnly={true}
                  versionValue={versionState.editedContinuityPlanDescription}
                  value={state.editedContinuityPlanDescription}
                  buttonClick={() => onCopyBCPClick("businessContinuityPlan")}
                  heading="Business Continuity Plan"
                />
                <RichTextEditorSection
                  id="recoveryDependency"
                  name="recoveryDependency"
                  keyProp={state.recoveryDependencykey}
                  onChange={(e: any) =>
                    dispatch({
                      type: continuityActionTypes.SET_EDITED_RECOVERY_DEPENDENCY,
                      payload: e,
                    })
                  }
                  setOptions={buttonListConfig}
                  setOptionsVersion={buttonListConfigreadOnly}
                  width="100%"
                  readOnly={true}
                  versionValue={versionState.recoveryDependency}
                  value={state.editedRecoveryDependency}
                  buttonClick={() => onCopyBCPClick("recoveryDependency")}
                  heading="Recovery dependency"
                />

                <Box display="grid" gridTemplateColumns="1fr  1fr" gridGap={13}>
                  <Box p={3} width="200px">
                    {versionState.recoveryRTO ? (
                      <McInput
                        disabled={true}
                        fit="medium"
                        id="recoveryRTO"
                        hiddenlabel={true}
                        maxlength={50}
                        placeholder=""
                        required
                        type="text"
                        value={versionState.recoveryRTO}
                        variant="default"
                        key={dependency.id}
                      />
                    ) : null}
                  </Box>

                  <Box p={3} width="200px" ml={30}>
                    <Box display="flex" mt={1} ml={1}>
                      <LabelWrapper>
                        Recovery RTO
                        <Info
                          popoverContent="The 'Recovery RTO' value originates from the Impact screen."
                          tooltipText={null}
                        >
                          <span>Info</span>
                        </Info>
                      </LabelWrapper>
                    </Box>
                    <McInput
                      disabled={true}
                      fit="medium"
                      id="recoveryRTO"
                      hiddenlabel={true}
                      maxlength={50}
                      placeholder=""
                      required
                      type="text"
                      value={state.recoveryRTO}
                      variant="default"
                      key={dependency.id}
                    />
                  </Box>
                </Box>
              </Box>
              <ActionBox
                onCancel={setCancelled}
                onSave={onSave}
                shouldShowCancelButton={true}
              />
            </>
          )}
        </ContinuityPlanContainer>
      )}
    </TitleBox>
  );
}

const RichTextEditorComparer = styled(Box)`
  display: flex;
  gap: 16px;
  align-items: center;

  & > .rich-text-editor {
    flex: 1;
  }

  & > .mc-button {
    flex: 0 0 auto;
  }
`;
const ContinuityPlanContainer = styled(Box)`
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
`;

const ContinuityPlanContentContainer = styled(Box)`
  padding-right: 35px;
  max-width: 100%;
  overflow: auto;
`;

const LabelWrapper = styled(Box)`
  padding: 20px 0px;
  font-weight: bold;
  display: flex;
`;
const ContinuityPlanContentWrapper = styled.div`
  background-color: var(--mds_brand_appearance_neutral_weakest_background-color);
  padding: 20px;
  max-width: 100%;
  width: 100%;
  overflow: auto;
`;
