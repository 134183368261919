import { GroupSite, Site } from "../../models/Site";
import { doGet, doPost, doPut } from "../../utils";
import { getApiUrl } from "../../utils/application-utils";

export const getSites = (archetypeId: number): Promise<Site[]> =>
  doGet(`${getApiUrl()}/sites/${archetypeId}`);

export const createOrUpdateSite = (
  archetypeId: string,
  site: Site
): Promise<any> => {
  return site.id === 0
    ? doPost(`${getApiUrl()}/sites/${archetypeId}/create/`, site)
    : doPut(`${getApiUrl()}/sites/update/`, site);
};

export const createOrUpdateGroupSite = (
  archetypeId: string,
  groupId: string,
  groupSite: GroupSite
): Promise<any> => {
  return groupSite.id === 0
    ? doPost(`${getApiUrl()}/sites/group/${groupId}/site/create`, groupSite)
    : doPut(`${getApiUrl()}/sites/group/site/update`, groupSite);
};

export const getSite = (siteId: number): Promise<Site> =>
  doGet(`${getApiUrl()}/sites/${siteId}/details`);

export const searchSite = (
  pageNumber: number = 1,
  pageSize: number = 10000000
): Promise<any> =>
  doGet(`${getApiUrl()}/sites/getallsites/${pageNumber}/${pageSize}`);
