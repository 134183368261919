import { SiteContinuityPlan } from "../../models/SiteContinuityPlan";
import {  doGet, doPost } from "../../utils";
import { getApiUrl } from "../../utils/application-utils";

export const getDependenciesforSite = (siteId: string,versionId:string): Promise<any> =>
  doGet(`${getApiUrl()}/site/dependency/${siteId}/${versionId}/continuity`);

export const createOrUpdateSiteContinuityPlan = (continuityPlan: SiteContinuityPlan): Promise<any> => {
    let apiUrl = getApiUrl();
    return doPost(`${apiUrl}/sitecontinuityplan/createsitecontinuityplan`, continuityPlan)
  };

export const getSiteContinuityPlanByDependencyId = (
    siteId: number,
    versionId: number,
    categoryId: number,
    dependencyId: number
  ): Promise<any> =>
    doGet(
      `${getApiUrl()}/sitecontinuityplan/sitecontinuityplandownloadjson/${siteId}/${versionId}/${categoryId}/${dependencyId}`
    );


    export const getSiteContinuityPlanByDependencyIdForLastVersion = (
        siteId: number,
        categoryId: number,
        dependencyId: number
      ): Promise<any> =>
        doGet(
          `${getApiUrl()}/sitecontinuityplan/sitecontinuityplanlastversiondownloadjson/${siteId}/${categoryId}/${dependencyId}`
        );
