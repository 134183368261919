import React from "react";
import { Box } from "@maersk-global/community-react";
import { McNotification } from "@maersk-global/mds-react-wrapper";
import { StaticIcon } from "./icons";
import styled from "styled-components";

const StyledListItem = styled.li`
  list-style-type: none;
`;

interface NotificationProps {
  message: string;
  appearance: "warning" | "info" | "success" | "error";
  icon: string;
}

const NotificationWithIcons: React.FC<NotificationProps> = ({
  message,
  appearance,
  icon,
}) => {
  return (
    <Box display="flex" style={{ marginBottom: "10px", marginTop: "10px" }}>
      <McNotification appearance={appearance} fit="small">
        <ul>
          <StyledListItem>
            <Box display="flex" alignItems="center">
              <StaticIcon icon={icon} size="20" />
              <span style={{ marginLeft: "10px" }}>{message}</span>
            </Box>
          </StyledListItem>
        </ul>
      </McNotification>
    </Box>
  );
};

export default NotificationWithIcons;
