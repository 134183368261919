export const siteMappingActions = {
  SET_BRANDS: "SET_BRANDS",
  SET_SELECTED_BRANDS: "SET_SELECTED_BRANDS",
  SELECT_BRAND: "SELECT_BRAND",
  SELECT_SITETYPE: "SELECT_SITETYPE",
  SELECT_ARCHETYPE: "SELECT_ARCHETYPE",
  SET_SITES: "SET_SITES",
  SET_LOADING: "SET_LOADING",
  SET_SELECTED_SITES: "SET_SELECTED_SITES",
  SELECT_REGION: "SELECT_REGION",
  SET_SECONDARY_MODEL: "SET_SECONDARY_MODEL",
};

export const siteMappingReducer = (state: any, action: any) => {
  switch (action.type) {
    case siteMappingActions.SET_BRANDS:
      return { ...state, brands: action.brands };
    case siteMappingActions.SELECT_BRAND:
      return { ...state, selectedBrand: action.selectedBrand };
    case siteMappingActions.SELECT_SITETYPE:
      return { ...state, selectedSiteType: action.selectedSiteType };
    case siteMappingActions.SELECT_ARCHETYPE:
      return { ...state, selectedArchetype: action.selectedArchetype };
    case siteMappingActions.SET_LOADING:
      return { ...state, loading: action.loading };
    case siteMappingActions.SET_SITES:
      return {
        ...state,
        sites: action.sites,
      };
    case siteMappingActions.SET_SELECTED_SITES:
      return {
        ...state,
        selectedSites: action.selectedSites,
      };
    case siteMappingActions.SELECT_REGION:
      return { ...state, selectedRegion: action.selectedRegion };
    case siteMappingActions.SET_SECONDARY_MODEL:
      return { ...state, secondaryModel: action.secondaryModel };
  }
};

export const siteMappingState = {
  brands: [],
  sites: [],
  selectedSites: [],
  selectedBrand: 0,
  selectedSiteType: 0,
  selectedArchetype: 0,
  loading: false,
  selectedRegion: "",
  secondaryModel: false,
};
