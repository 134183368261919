import { useEffect, useReducer } from "react";
import { getBrands } from "../../../services/Brands";
import { toast } from "../../../components/common/toast";
import { ArcheType, Brand, SiteType } from "../../../models/Brand";
import { FilterBox } from "../../../components/common/common.styles";
import { getSites } from "../../../services/Sites";
import { Site } from "../../../models/Site";
import Heading from "../../../components/common/heading";
import NoDataAvailable from "../../../components/common/noDataAvailable";
import {
  McCheckbox,
  McOption,
  McSelect,
  McTable,
} from "@maersk-global/mds-react-wrapper";
import CommonModal from "../../../components/common/commonModal";
import { loadingIndicator } from "../../../components/common/loading";
import Tooltip from "../../../components/common/tooltip";
import { DeleteIcon } from "../../../components/common/icons";
import {
  siteMappingActions,
  siteMappingReducer,
  siteMappingState,
} from "../reducers/siteMapping";
import Box from "../../../components/common/box";
import { SiteMappingInfo } from "../../../constants/Info";
import Info from "../../../components/common/info";

export default function SiteMapping(props: Readonly<any>) {
  const [siteMapping, dispatch] = useReducer(
    siteMappingReducer,
    siteMappingState
  );

  useEffect(() => {
    getAllBrands();
  }, []);

  useEffect(() => {
    let selectedSites = JSON.parse(JSON.stringify(props.sites));
    dispatch({
      type: siteMappingActions.SET_SELECTED_SITES,
      selectedSites: selectedSites,
    });
  }, [props.isOpen]);

  useEffect(() => {
    if (siteMapping.selectedArchetype?.id) {
      dispatch({ type: siteMappingActions.SELECT_REGION, selectedRegion: "" });
      dispatch({ type: siteMappingActions.SET_LOADING, loading: true });
      getAllSites(siteMapping.selectedArchetype?.id);
    }
  }, [siteMapping.selectedArchetype?.id]);

  const onCancel = () => {
    if (
      props.sites.length !== siteMapping.selectedSites.length ||
      !props.sites.every(
        (site: Site, index: number) =>
          site.id === siteMapping.selectedSites[index].id
      )
    ) {
      dispatch({
        type: siteMappingActions.SET_SECONDARY_MODEL,
        secondaryModel: true,
      });
    } else {
      props.onClose();
    }
  };

  const onConfirmUnsavedChanges = () => {
    let selectedSites = props.sites;
    dispatch({
      type: siteMappingActions.SET_SELECTED_SITES,
      selectedSites: selectedSites,
    });
    dispatch({
      type: siteMappingActions.SET_SECONDARY_MODEL,
      secondaryModel: false,
    });
    props.onClose();
  };

  const onCancelUnsavedChanges = () => {
    dispatch({
      type: siteMappingActions.SET_SECONDARY_MODEL,
      secondaryModel: false,
    });
  };

  const getAllBrands = () => {
    getBrands()
      .then((res) => {
        dispatch({ type: siteMappingActions.SET_BRANDS, brands: res.brands });
      })
      .catch((err): void => {
        toast("error", err.message, "error");
      });
  };

  const getAllSites = (archetypeId: number) => {
    getSites(archetypeId)
      .then((sites) => {
        if (sites) {
          dispatch({ type: siteMappingActions.SET_LOADING, loading: false });
          dispatch({ type: siteMappingActions.SET_SITES, sites: sites });
        }
      })
      .catch((error) => {
        dispatch({ type: siteMappingActions.SET_LOADING, loading: false });
        toast("error", error.message, "error");
      });
  };

  const handleBrandChange = (event: any) => {
    const brandId = event.value;
    let selectedBrand = siteMapping.brands.find(
      (brand: any) => brand.id === parseInt(brandId)
    );
    dispatch({
      type: siteMappingActions.SELECT_BRAND,
      selectedBrand: selectedBrand,
    });
  };

  const handleSiteTypeChange = (selectedOption: {
    value: number;
    label: string;
  }) => {
    let selectedsiteType = siteMapping.selectedBrand.siteTypes.find(
      (siteType: SiteType) => siteType.id === selectedOption.value
    );
    dispatch({
      type: siteMappingActions.SELECT_SITETYPE,
      selectedSiteType: selectedsiteType,
    });
  };

  const handleArcheTypeChange = (selectedOption: {
    value: number;
    label: string;
  }) => {
    let selectedArcheType = siteMapping.selectedSiteType.archeTypes.find(
      (archeType: ArcheType) => archeType.id === selectedOption.value
    );
    dispatch({
      type: siteMappingActions.SELECT_ARCHETYPE,
      selectedArchetype: selectedArcheType,
    });
  };

  const handleRegionChange = (selectedOption: {
    value: string;
    label: string;
  }) => {
    let selectedRegion = selectedOption.value;
    dispatch({
      type: siteMappingActions.SELECT_REGION,
      selectedRegion: selectedRegion,
    });
  };

  const brandOptions = siteMapping.brands?.map((brand: Brand) => ({
    value: brand.id,
    label: brand.businessBrandName,
  }));

  const siteTypeOptions = siteMapping.selectedBrand?.siteTypes?.map(
    (siteType: SiteType) => ({
      value: siteType.id,
      label: siteType.siteTypeName,
    })
  );

  const archeTypeOptions = siteMapping.selectedSiteType?.archeTypes?.map(
    (archeType: ArcheType) => ({
      value: archeType.id,
      label: archeType.archeTypeName,
    })
  );

  const regionOptions = Object.values(
    siteMapping.sites?.reduce((acc: any, site: Site) => {
      if (!acc[site.regionName]) {
        acc[site.regionName] = {
          value: site.regionName,
          label: site.regionName,
        };
      }
      return acc;
    }, {})
  );

  function onDeleteEntity(siteId: number) {
    let selectedSites = siteMapping.selectedSites;
    if (selectedSites.findIndex((site: Site) => site.id === siteId) > -1) {
      selectedSites = selectedSites.filter((site: Site) => site.id !== siteId);
      dispatch({
        type: siteMappingActions.SET_SELECTED_SITES,
        selectedSites: selectedSites,
      });
    }
  }

  const isCheckBoxChecked = (siteId: number): boolean => {
    return (
      siteMapping.selectedSites?.findIndex((site: Site) => site.id === siteId) >
      -1
    );
  };

  const onSiteAssignClicked = (siteId: number) => {
    let selectedSite = siteMapping.sites.find(
      (site: Site) => site.id === siteId
    );
    let selectedSites = [...siteMapping.selectedSites];

    if (selectedSites.findIndex((site: Site) => site.id === siteId) > -1) {
      selectedSites = selectedSites.filter((site: Site) => site.id !== siteId);
    } else {
      selectedSites.push(selectedSite);
    }
    dispatch({
      type: siteMappingActions.SET_SELECTED_SITES,
      selectedSites: selectedSites,
    });
  };

  const onSave = () => {
    let siteIds = siteMapping.selectedSites?.map((site: Site) => site.id);
    let id = props.entity.id;

    props.onSave(siteIds, id);
  };

  return (
    <>
      <CommonModal
        primaryActionLabel="Confirm"
        primaryAction={() => onSave()}
        secondaryActionLabel="Cancel"
        onSeceondaryAction={() => onCancel()}
        isOpen={props.isOpen}
        onModalClose={() => onCancel()}
        heading={`Assign Sites`}
        dimension="large"
      >
        <Box display="grid" gridTemplateColumns="30fr 1fr" p={2}>
          <Box>
            <FilterBox
              display="grid"
              gridTemplateColumns="1.5fr 1.5fr 1.5fr 1.5fr"
            >
              <Box>
                <McSelect
                  label="Brand"
                  optionswidth="trigger"
                  optionselected={(e: CustomEvent) => {
                    handleBrandChange(e.detail);
                  }}
                  fit="medium"
                  value={siteMapping.selectedBrand?.id ?? null}
                  name="selectBrand"
                ><Info popoverContent={SiteMappingInfo.SiteMappingInfo_Brand} tooltipText="Brands" />
                  {brandOptions.map((brand: any) => (
                    <McOption key={brand.value} value={brand.value}>
                      {brand.label}
                    </McOption>
                  ))}
                </McSelect>
              </Box>
              <Box>
                <McSelect
                  label="SiteType"
                  optionswidth="trigger"
                  optionselected={(e: CustomEvent) => {
                    handleSiteTypeChange(e.detail);
                  }}
                  value={siteMapping.selectedSiteType?.id ?? null}
                  name="selectSiteType"
                ><Info popoverContent={SiteMappingInfo.SiteMappingInfo_SiteType} tooltipText="SiteType" />
                  {siteTypeOptions?.map((siteType: any) => (
                    <McOption key={siteType.value} value={siteType.value}>
                      {siteType.label}
                    </McOption>
                  ))}
                </McSelect>
              </Box>
              <Box>
                <McSelect
                  label="Archetype"
                  optionswidth="trigger"
                  optionselected={(e: CustomEvent) => {
                    handleArcheTypeChange(e.detail);
                  }}
                  value={siteMapping.selectedArchetype?.id ?? null}
                  name="archetype"
                ><Info popoverContent={SiteMappingInfo.SiteMappingInfo_Archetype} tooltipText="Archetype" />
                  {archeTypeOptions?.map((archetype: any) => (
                    <McOption key={archetype.value} value={archetype.value}>
                      {archetype.label}
                    </McOption>
                  ))}
                </McSelect>
              </Box>
              <Box>
                <McSelect
                  label="Region"
                  optionswidth="trigger"
                  optionselected={(e: CustomEvent) => {
                    handleRegionChange(e.detail);
                  }}
                  value={siteMapping.selectedRegion ?? null}
                  name="region"
                ><Info popoverContent={SiteMappingInfo.SiteMappingInfo_Region} tooltipText="Region" />
                  {regionOptions?.map((region: any) => (
                    <McOption key={region.value} value={region.value}>
                      {region.label}
                    </McOption>
                  ))}
                </McSelect>
              </Box>
            </FilterBox>
          </Box>
        </Box>
        {siteMapping.loading ? loadingIndicator : null}
        {siteMapping.sites?.length === 0 ? (
          <NoDataAvailable text="No sites found for selected filter." />
        ) : (
          <McTable
            data={siteMapping.sites.filter(
              (site: Site) =>
                !siteMapping.selectedRegion ||
                site.regionName === siteMapping.selectedRegion
            )}
            columns={[
              { id: "id", label: "", sortDisabled: true },
              { id: "icon", label: "", sortDisabled: true },
              { id: "code", label: "Id", sortDisabled: false },
              { id: "siteName", label: "Name", sortDisabled: false },
              { id: "regionName", label: "Region", sortDisabled: false },
              { id: "areaName", label: "Area", sortDisabled: false },
              { id: "country", label: "Country", sortDisabled: false },
              { id: "entity", label: "Local Entity", sortDisabled: false },
            ]}
          >
            {siteMapping.sites
              .filter(
                (site: Site) =>
                  !siteMapping.selectedRegion ||
                  site.regionName === siteMapping.selectedRegion
              )
              .map((row: any) => (
                <>
                  <div key={`${row.id}_id`} slot={`${row.id}_id`}>
                    {
                      <McCheckbox
                        checked={isCheckBoxChecked(row.id)}
                        label=""
                        change={() => onSiteAssignClicked(row.id)}
                        id=""
                        name=""
                      />
                    }
                  </div>
                  <div key={`${row.code}_code`} slot={`${row.code}_code`}>
                    {row.code}
                  </div>
                  <div key={`${row.name}_name`} slot={`${row.name}_name`}>
                    {row.name}
                  </div>
                  <div
                    key={`${row.region}_region`}
                    slot={`${row.region}_region`}
                  >
                    {row.region}
                  </div>
                  <div key={`${row.area}_area`} slot={`${row.area}_area`}>
                    {row.area}
                  </div>
                  <div
                    key={`${row.country}_country`}
                    slot={`${row.country}_country`}
                  >
                    {row.country}
                  </div>
                  <div
                    key={`${row.entity}_entity`}
                    slot={`${row.entity}_entity`}
                  >
                    {row.entity}
                  </div>
                </>
              ))}
          </McTable>
        )}
        <br />
        <Heading heading={props.secondHeading} subheading={``}></Heading>
        {siteMapping.selectedSites?.length === 0 ? (
          <NoDataAvailable text="No sites mapped to user." />
        ) : (
          <McTable
            data={siteMapping.selectedSites}
            columns={[
              { id: "code", label: "Id", sortDisabled: false },
              { id: "siteName", label: "Name", sortDisabled: false },
              { id: "regionName", label: "Region", sortDisabled: false },
              { id: "areaName", label: "Area", sortDisabled: false },
              { id: "country", label: "Country", sortDisabled: false },
              { id: "entity", label: "Local Entity", sortDisabled: false },
              { id: "id", label: "", sortDisabled: true },
            ]}
          >
            {siteMapping.selectedSites.map((row: any) => (
              <>
                <div key={`${row.code}_code`} slot={`${row.code}_code`}>
                  {row.code}
                </div>
                <div key={`${row.siteName}_name`} slot={`${row.siteName}_name`}>
                  {row.siteName}
                </div>
                <div key={`${row.region}_region`} slot={`${row.region}_region`}>
                  {row.region}
                </div>
                <div key={`${row.area}_area`} slot={`${row.area}_area`}>
                  {row.area}
                </div>
                <div
                  key={`${row.country}_country`}
                  slot={`${row.country}_country`}
                >
                  {row.country}
                </div>
                <div key={`${row.entity}_entity`} slot={`${row.entity}_entity`}>
                  {row.entity}
                </div>

                <div key={`${row.id}_id`} slot={`${row.id}_id`}>
                  <Box display="flex" justifyContent="flex-end">
                    <Box>
                      <Tooltip content="Remove Sites">
                        <DeleteIcon
                          slot="trigger"
                          onClick={() => onDeleteEntity(row.id)}
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                </div>
              </>
            ))}
          </McTable>
        )}
      </CommonModal>
      <CommonModal
        primaryActionLabel="Confirm"
        primaryAction={() => onConfirmUnsavedChanges()}
        secondaryActionLabel="Cancel"
        onSeceondaryAction={() => onCancelUnsavedChanges()}
        isOpen={siteMapping.secondaryModel}
        onModalClose={() => onCancelUnsavedChanges()}
        heading={"Unsaved Changes, Do you want to Procced?"}
        dimension="small"
        className={"delete-modal"}
      >
        <div>
          <p>
            Are you certain you want to proceed without saving the User to Site
            mapping data?
          </p>
        </div>
      </CommonModal>
    </>
  );
}
