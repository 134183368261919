import Box from "./box";
import { Button } from "./button";
import { FlexGrowBox } from "./common.styles";

/**
 * Renders an action box with optional cancel and save buttons.
 *
 * @param {boolean} props.shouldShowCancelButton Determines if the cancel button should be shown.
 * @param {Function} props.onCancel Callback function to be called when the cancel button is clicked.
 * @param {Function} props.onSave Callback function to be called when the save button is clicked.
 *
 * @returns {JSX.Element} The rendered action box component.
 */
export default function ActionBox({
  shouldShowCancelButton,
  onCancel,
  onSave,
}: Readonly<{
  shouldShowCancelButton: boolean;
  onCancel?: () => void;
  onSave: () => void;
}>): JSX.Element {
  return (
    <Box display="flex" gridGap={10} p={10}>
      <FlexGrowBox />
      {shouldShowCancelButton && (
        <Button
          appearance="neutral"
          fit="large"
          id="primary"
          justifyitems="center"
          label="Cancel"
          name="cancel"
          type="button"
          variant="filled"
          click={onCancel}
        />
      )}
      <Button
        appearance="primary"
        fit="large"
        id="primary"
        justifyitems="center"
        label="Save"
        name="save"
        type="button"
        variant="filled"
        click={onSave}
      />
    </Box>
  );
}
