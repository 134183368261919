import { RichTextEditor } from "@maersk-global/community-react-rich-text-editor";
import Box from "../../../components/common/box";
import { McButton } from "@maersk-global/mds-react-wrapper";
import { styled } from "styled-components";
interface RichTextEditorSectionProps {
  id: string;
  name: string;
  keyProp: any;
  onChange: (e: any) => void;
  setOptionsVersion: any;
  setOptions: any;
  width: string;
  readOnly?: boolean;
  value: any;
  versionValue: any;
  buttonClick: () => void;
  heading: string;
}

const RichTextEditorSection: React.FC<RichTextEditorSectionProps> = ({
  id,
  name,
  keyProp,
  onChange,
  setOptions,
  setOptionsVersion,
  width,
  readOnly = false,
  value,
  versionValue,
  buttonClick,
  heading
}) => {
  return (
    <Box display="grid" gridTemplateColumns="1fr 0.1fr 1fr" gridGap={3}>
      <Box p={3}>
        <div style={{ width: "600px", overflowX: "auto" }}>
        <LabelWrapper>{heading}</LabelWrapper>
          <RichTextEditor
            id={id}
            name={name}
            key={keyProp}
            onChange={onChange}
            setOptions={setOptionsVersion}
            width={"100%"}
            readOnly={readOnly}
            value={versionValue}
          />
        </div>
      </Box>
      <Box
        p={1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <McButton
          id="copyContent"
          label=""
          name="copyContent"
          click={buttonClick}
          title="Copy Content from left to right"
          variant="filled"
          fit="small"
          appearance="neutral"
          icon="arrow-right"
        />
      </Box>
      <Box p={3}>
        <div style={{ width: "600px", overflowX: "auto" }}>
        <LabelWrapper>{heading}</LabelWrapper>
          <RichTextEditor
            id={id}
            name={name}
            key={keyProp}
            onChange={onChange}
            setOptions={setOptions}
            width={"100%"}
            value={value}
          />
        </div>
      </Box>
    </Box>
  );
};

export default RichTextEditorSection;


const LabelWrapper = styled(Box)`
  padding: 20px 0px;
  font-weight: bold;
  display: flex;
`;