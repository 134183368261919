export const usersActions = {
  SET_MODAL_CLOSE: "SET_MODAL_CLOSE",
  SET_USERS: "SET_USERS",
  SET_MODAL_OPEN: "SET_MODAL_OPEN",
  RESET_EDITABLE_USER: "RESET_EDITABLE_USER",
  SET_EDITABLE_USER: "SET_EDITABLE_USER",
  SET_USER_SITE_MAPPING: "SET_USER_SITE_MAPPING",
  SET_USER_SITE_MAPPING_MODAL_CLOSE: "SET_USER_SITE_MAPPING_MODAL_CLOSE",
};

export const defaultUsersState = {
  loading: true,
  isAddEditUserModalOpen: false,
  editableUser: null,
  users: [],
  siteMappingUser: null,
  isSiteMappingModalOpen: false,
};

export const usersReducer = (state: any, action: any) => {
  switch (action.type) {
    case usersActions.SET_MODAL_CLOSE:
      return {
        ...state,
        isAddEditUserModalOpen: false,
      };
    case usersActions.SET_MODAL_OPEN:
      return {
        ...state,
        isAddEditUserModalOpen: true,
      };
    case usersActions.SET_USERS:
      return {
        ...state,
        users: action.value,
        loading: false,
      };
    case usersActions.SET_EDITABLE_USER:
      return {
        ...state,
        editableUser: action.user,
      };
    case usersActions.RESET_EDITABLE_USER:
      return {
        ...state,
        editableUser: null,
      };
    case usersActions.SET_USER_SITE_MAPPING:
      return {
        ...state,
        isSiteMappingModalOpen: true,
        siteMappingUser: action.user,
      };
    case usersActions.SET_USER_SITE_MAPPING_MODAL_CLOSE:
      return {
        ...state,
        siteMappingUser: null,
        isSiteMappingModalOpen: false,
      };
    default:
      return state;
  }
};
