import { McTextAndIcon } from "@maersk-global/mds-react-wrapper";
import styled from "styled-components";
import { useEffect, useState } from "react";
import ActivityLog from "./ActivityLog";
import { Drawer } from "@maersk-global/community-react-drawer";

export default function RightSideNav({
  siteId,
  versionId,
}: Readonly<{ siteId: number; versionId: number }>) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    if (isDrawerOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Cleanup on component unmount
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isDrawerOpen, versionId]);

  return (
    <RightSideNavWrapper>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <SideNavContentWrapper>
          <McTextAndIcon
            className="site-activity-log"
            label={"Activity log"}
            fit="small"
            icon="clipboard-check"
            onClick={() => setIsDrawerOpen(true)}
          />
          <Drawer
            direction="right"
            fit="large"
            onClose={() => setIsDrawerOpen(false)}
            open={isDrawerOpen}
          >
            <div
              style={{
                width: "450px",
                marginTop: "80px",
                paddingRight: "10px",
              }}
            >
              <ActivityLog
                versionId={versionId}
                onClose={() => {
                  setIsDrawerOpen(false);
                }}
              />
            </div>
          </Drawer>
        </SideNavContentWrapper>
      </div>
    </RightSideNavWrapper>
  );
}

export const SideNavContentWrapper = styled.div`
  padding-top: 10px;
  padding-right: 8px;
  padding-left: 8px;

  .site-activity-log::part(text-and-icon) {
    flex-direction: column;
    align-items: center;
  }
  .site-activity-log::part(text-and-icon-label) {
    font-size: 8px;
  }
`;

export const RightSideNavWrapper = styled.div`
  position: fixed;
  right: 0px;
  bottom: 0px;
  width: 60px;
  top: 70px;
  height: 100%;
  background-color: var(
    --mds_brand_appearance_neutral_default_background-color
  );
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  justify-content: center;
  z-index: 10;
`;
