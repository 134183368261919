import { McPagination } from "@maersk-global/mds-react-wrapper";
import styled from "styled-components";

export default function Pagination({
  pageSize,
  currentpage,
  itemsCount,
  onPagechange,
}: Readonly<{
  pageSize: number;
  currentpage: number;
  itemsCount: number;
  onPagechange: (page: number) => void;
}>) {
  const totalpages = Math.ceil(itemsCount / pageSize);
  return (
    <PaginationWrapper>
      <McPagination
        totalpages={totalpages}
        currentpage={currentpage}
        visiblepages={totalpages}
        pagechange={(e) => onPagechange(e.detail)}
      />
    </PaginationWrapper>
  );
}

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;
