import MultiSelect from "@maersk-global/community-react-multi-select";
import { useEffect, useState } from "react";
import { Wrapper } from "./ProcessDependencies";
import TitleBox from "../../components/common/titleBox";
import Box from "../../components/common/box";
import { McMultiSelect, McOption } from "@maersk-global/mds-react-wrapper";
import { IMcMultiSelectOptionsSelectedDetail } from "@maersk-global/mds-components-core/mc-multi-select/types";
import SiteDependencies from "../Site/ProcessDependencies/SiteDependencies";
import { UserTypes } from "../../constants/Users";

export default function ProcessDependency({
  process,
  dependencies,
  mappedProcessDependencies,
  mappedProcessDependenciesCopy,
  onSingleDependencySelected,
  onSetProcessDependenciesUpdated,
  processName,
  userType,
  onDependencyAdded,
}: Readonly<{
  process: any;
  dependencies: any;
  mappedProcessDependencies: any;
  mappedProcessDependenciesCopy: any;
  onSingleDependencySelected: Function;
  onSetProcessDependenciesUpdated: Function;
  processName: string;
  userType: any;
  onDependencyAdded: Function;
}>) {
  const [processDependencies, setProcessDependencies] = useState<any>([]);

  useEffect(() => {
    let processMappings;

    if (mappedProcessDependencies) {
      processMappings = mappedProcessDependencies.find(
        (item: any) => item.processId === process.id
      );
    }
    if (processMappings) {
      processMappings.selectedDependencies.map((item: any) => {
        let dependencyCategory = dependencies.find(
          (dependency: any) =>
            dependency.dependencyCategoryId === item.dependencyCategoryId
        );
        if (dependencyCategory) {
          let selectedDependencies =
            dependencyCategory.dependencyDropdown.filter((dependency: any) =>
              item.selectectedDependencyIds.includes(dependency.id)
            );
          onDependencySelected(
            selectedDependencies,
            item.dependencyCategoryId,
            process
          );
        }
      });
    }
  }, [process, dependencies, mappedProcessDependencies]);

  const onDependencySelected = (
    selectedDependencies: any,
    dependencyCategoryId: number,
    process: any
  ) => {
    const findIndex = processDependencies.findIndex(
      (item: any) => item.dependencyCategoryId === dependencyCategoryId
    );
    if (findIndex !== -1) {
      processDependencies[findIndex] = {
        dependencyCategoryId: dependencyCategoryId,
        selectedDependencies: selectedDependencies,
      };
    } else {
      processDependencies.push({
        dependencyCategoryId: dependencyCategoryId,
        selectedDependencies: selectedDependencies,
      });
    }

    setProcessDependencies(processDependencies);
    onSingleDependencySelected(
      dependencyCategoryId,
      selectedDependencies,
      process,
      processName
    );
    onSetProcessDependenciesUpdated({
      processId: process.id,
      processDependencies: processDependencies,
    });
  };

  const findSelectedDependencies = (dependencyCategoryId: number) => {
    const item = processDependencies.find(
      (item: any) => item.dependencyCategoryId === dependencyCategoryId
    );
    if (item) {
      return item.selectedDependencies;
    }
    return [];
  };

  const findSiteSelectedDependencies = (dependencyCategoryId: number) => {
    const item = processDependencies.find(
      (item: any) => item.dependencyCategoryId === dependencyCategoryId
    );
    if (item) {
      let selectedDependencies = item.selectedDependencies.map((item: any) => {
        return item.value;
      });

      return selectedDependencies.join(",");
    }
    return "";
  };

  const onSiteDependencyEdited = (
    dependencyCategoryId: any,
    dependencyDropDowns: any
  ) => {
    let updatedDependenciesIndex = dependencies.findIndex((item: any) => {
      return item.dependencyCategoryId === dependencyCategoryId;
    });

    if (updatedDependenciesIndex !== -1) {
      dependencies[updatedDependenciesIndex].dependencyDropdown =
        dependencyDropDowns;

      onDependencyAdded(dependencies);
    }
  };

  const getSiteDependencyDropdowns = (processId: any) => {
    return dependencies.map((dependency: any) => {
      let { dependencyDropdown, dependencyCategoryId, dependencyCategoryName } =
        dependency;
      return (
        <Wrapper
          key={dependencyCategoryId}
          display="grid"
          gridTemplateColumns="1fr 2fr 0.2fr"
          style={{ alignItems: "center" }}
        >
          <Box>{dependencyCategoryName}</Box>
          <Box>
            <McMultiSelect
              fit={"small"}
              id={dependencyCategoryId}
              name="site-dependency-category"
              labelposition={"left"}
              label=""
              optionselected={(
                e: CustomEvent<IMcMultiSelectOptionsSelectedDetail>
              ) => {
                let selectedOptions = e.detail.map((item) => {
                  return {
                    id: Number(item.value),
                    value: Number(item.value),
                    label: item.label,
                  };
                });
                onDependencySelected(
                  selectedOptions,
                  dependencyCategoryId,
                  process
                );
              }}
              value={findSiteSelectedDependencies(dependencyCategoryId)}
            >
              {dependencyDropdown.map((item: any) => {
                return (
                  <McOption key={item.id} value={item.value.toString()}>
                    {item.label}
                  </McOption>
                );
              })}
            </McMultiSelect>
          </Box>
          <Box style={{ alignSelf: "center", justifySelf: "end" }}>
            <SiteDependencies
              dependencyCategoryName={dependencyCategoryName}
              dependencyDropdown={dependencyDropdown}
              key={`${new Date().getTime()}`}
              dependencyCategoryId={dependencyCategoryId}
              onDependencyEdited={onSiteDependencyEdited}
            />
          </Box>
        </Wrapper>
      );
    });
  };

  const getdependencyDropdowns = () => {
    return dependencies.map((dependency: any) => {
      return (
        <Wrapper
          key={dependency.dependencyCategoryId}
          display="grid"
          gridTemplateColumns="0.4fr 1fr"
        >
          <Box>{dependency.dependencyCategoryName}</Box>
          <Box>
            <MultiSelect
              className="react-select"
              closeMenuOnSelect
              fit="small"
              id="process-dependency-category"
              name="process-dependency-category"
              options={dependency.dependencyDropdown}
              onChange={(newValue: any, action?: any) => {
                onDependencySelected(
                  newValue,
                  dependency.dependencyCategoryId,
                  process
                );
              }}
              menuPortalTarget={document.body}
              orientation="horizontal"
              suggestType="static"
              variant="checkbox"
              value={findSelectedDependencies(dependency.dependencyCategoryId)}
            />
          </Box>
        </Wrapper>
      );
    });
  };

  const CheckIfProcessMappedToDependency = (processId: any): boolean => {
    const dependencyMappings = mappedProcessDependenciesCopy.find(
      (process: any) => process.processId === processId
    );
    let isDependencyMapped = false;
    isDependencyMapped = dependencyMappings?.selectedDependencies.every(
      (dependency: any) => dependency.selectectedDependencyIds.length === 0
    );
    return isDependencyMapped;
  };

  const getDropdowns = () => {
    if (userType === UserTypes.site) {
      return getSiteDependencyDropdowns(process.id);
    } else {
      return getdependencyDropdowns();
    }
  };

  return (
    <>
      {processName !== "" ? (
        <TitleBox
          key={process.id}
          title={process.name}
          shouldHandleExpansion={true}
          displayWarning={CheckIfProcessMappedToDependency(process.id)}
        >
          {getDropdowns()}
        </TitleBox>
      ) : (
        getDropdowns()
      )}
    </>
  );
}
