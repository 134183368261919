import { DependencyCategoryMaster } from "../../models/Dependency";
import { ImpactType } from "../../models/Impact";
import { doGet, doGetWithoutAutheader, doPost } from "../../utils";
import { getApiUrl } from "../../utils/application-utils";
import { DependencyContinuityPlan } from '../../models/ContinuityPlan'; 

export const getDependenciesByArcheTypeId = (id: string): Promise<ImpactType> =>
  doGet(`${getApiUrl()}/dependency/${id}`);

export const createOrUpdateDependencies = (
  id: string,
  dependecies: DependencyCategoryMaster
): Promise<any> => {
  let apiUrl = getApiUrl();
  return doPost(`${apiUrl}/dependency/${id}/create`, dependecies);
};

export const getContinuityPlanByDependencyId = (
  archeTypeId: number,
  categoryId: number,
  dependencyId: number
): Promise<any> =>
  doGet(
    `${getApiUrl()}/mastercontinuityplan/mastercontinuityplandownloadjson/${archeTypeId}/${categoryId}/${dependencyId}`
  );

export const getAzureblobContent = (downloadUri: string): Promise<any> =>
  doGetWithoutAutheader(`${downloadUri}`);

export const createOrUpdateContinuityPlan = (continuityPlan: DependencyContinuityPlan): Promise<any> => {
    let apiUrl = getApiUrl();
    return doPost(`${apiUrl}/mastercontinuityplan/createmastercontinuityplan`, continuityPlan)
  };
