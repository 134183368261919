import { Mention, MentionsInput } from "react-mentions";
import { TaggableUsersContext } from "./contexts/taggableUsersContext";
import { useContext } from "react";
import { mentionStyle } from "./styles/mentions";

export default function TagInput({
  value,
  onTextChange,
}: {
  value: string;
  onTextChange: (e: any) => void;
}) {
  const { taggableUsers } = useContext(TaggableUsersContext);
  return (
    <MentionsInput
      value={value}
      onChange={(e: any) => onTextChange(e.target.value)}
      placeholder="Enter comments here...To tag a user press @<Users with permissions to this site>"
      style={mentionStyle}
    >
      <Mention
        markup="[[__id__:__display__]]"
        data={taggableUsers}
        trigger="@"
        appendSpaceOnAdd
      />
    </MentionsInput>
  );
}
