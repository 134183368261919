import OperationSchedule from "./OperationSchedule";
import StaffingSecurity from "./StaffingSecurity";
import SeasonalVariation from "./SeasonalVariation";

export default function OperationalParameters({
  handleStatusChange,
  operationalScheduleSectionId,
  staffingSecuritySectionId,
  seasonalVariationSectionId,
}: Readonly<{
  handleStatusChange: () => void;
  operationalScheduleSectionId: number;
  staffingSecuritySectionId: number;
  seasonalVariationSectionId: number;
}>) {
  const onSubmit = () => {
    handleStatusChange();
  };
  return (
    <>
      <OperationSchedule
        onSubmit={onSubmit}
        sectionId={operationalScheduleSectionId}
      />
      <StaffingSecurity
        onSubmit={onSubmit}
        sectionId={staffingSecuritySectionId}
      />
      <SeasonalVariation
        onSubmit={onSubmit}
        sectionId={seasonalVariationSectionId}
      />
    </>
  );
}
