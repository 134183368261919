import { Box } from "@maersk-global/community-react";
import CommonModal from "../../../../components/common/commonModal";
import {
  McCheckbox,
  McCheckboxGroup,
  McLabel,
} from "@maersk-global/mds-react-wrapper";
import styled from "styled-components";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useEffect, useState } from "react";
import { StaticIcon } from "../../../../components/common/icons";

export default function CustomizableColumnsModal({
  isOpen,
  availableColumns,
  sortedColumns,
  onChangesApplied,
  onDefaultColumnsRestored,
  onModalClosed,
}: Readonly<{
  isOpen: boolean;
  availableColumns: any;
  sortedColumns: any;
  onChangesApplied: (sortableColumns: any, selectableColumns: any) => void;
  onDefaultColumnsRestored: () => void;
  onModalClosed: () => void;
}>) {
  const [selectableColumns, setSelectableColumns] = useState<any>([]);
  const [sortableColumns, setSortableColumns] = useState<any>([]);

  useEffect(() => {
    if (isOpen) {
      setSelectableColumns(availableColumns);
      setSortableColumns(sortedColumns);
    } else {
      setSelectableColumns([]);
      setSortableColumns([]);
    }
  }, [isOpen]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 6,
      },
    })
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      setSortableColumns((items: any) => {
        const oldIndex = items.findIndex((item: any) => item.id === active.id);
        const newIndex = items.findIndex((item: any) => item.id === over.id);

        if (items[newIndex]?.isDefault) {
          return items;
        }

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const DraggableItem = ({ id, item }: { id: any; item: any }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id });

    return (
      <CustomizableColumnsModalWrapper>
        <div
          ref={setNodeRef}
          className={`listItem ${item.isDefault ? "disabled" : ""}`}
          style={{
            transform: CSS.Transform.toString(transform),
            transition,
          }}
          {...attributes}
          {...listeners}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <McLabel fit="small">{item.label}</McLabel>
            </div>
            <div style={{ flexGrow: 1 }}></div>
            <div>
              <StaticIcon icon="dot-grid" />
            </div>
          </div>
        </div>
      </CustomizableColumnsModalWrapper>
    );
  };

  const onItemChecked = (isChecked: boolean, key: string) => {
    setSelectableColumns((items: any) => {
      const index = items.findIndex((item: any) => item.key === key);
      items[index].isChecked = isChecked;

      return [...items].sort((a: any, b: any) => {
        if (a.isChecked === b.isChecked) {
          return a.id - b.id;
        }
        return a.isChecked ? -1 : 1;
      });
    });

    setSortableColumns((items: any) => {
      const index = items.findIndex((item: any) => item.key === key);

      if (index > -1) {
        items[index].isChecked = isChecked;
      } else {
        items.push({
          ...selectableColumns.find((item: any) => item.key === key),
          isChecked,
        });
      }

      return [...items].sort((a: any, b: any) => a.id - b.id);
    });
  };

  return (
    <CommonModal
      heading="Customize Columns"
      isOpen={isOpen}
      fit="medium"
      onModalClose={onModalClosed}
      primaryAction={() => {
        onChangesApplied(sortableColumns, selectableColumns);
      }}
      primaryActionLabel="Apply Changes"
      secondaryActionLabel="Cancel"
      onSeceondaryAction={onModalClosed}
      shouldShowSecondaryAction={true}
      shouldShowTertiaryAction={true}
      tertiaryActionLabel="Restore Default columns"
      tertiaryAction={onDefaultColumnsRestored}
    >
      <CustomizableColumnsModalWrapper>
        <Box display="grid" gridTemplateColumns="1fr 1fr">
          <Box>
            <ColumnsWrapper>
              <McLabel fit="small">Available Columns</McLabel>
              <McCheckboxGroup legend="" name="selectableColumns" fit="small">
                {selectableColumns.map((column: any) => (
                  <McCheckbox
                    key={column.key}
                    label={column.label}
                    value={column.key}
                    checked={column.isChecked}
                    className={`listItem ${column.isDefault ? "disabled" : ""}`}
                    fit="small"
                    change={(e) => onItemChecked(e.detail, column.key)}
                  />
                ))}
              </McCheckboxGroup>
            </ColumnsWrapper>
          </Box>
          <Box>
            <ColumnsWrapper>
              <McLabel fit="small">Selected Columns (Drag to reorder)</McLabel>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={sortableColumns.map((column: any) => ({
                    id: column.id,
                  }))}
                  strategy={verticalListSortingStrategy}
                >
                  {sortableColumns.map(
                    (item: any) =>
                      item.isChecked && (
                        <DraggableItem key={item.id} id={item.id} item={item} />
                      )
                  )}
                </SortableContext>
              </DndContext>
            </ColumnsWrapper>
          </Box>
        </Box>
      </CustomizableColumnsModalWrapper>
    </CommonModal>
  );
}

export const CustomizableColumnsModalWrapper = styled.div`
  .listItem {
    display: flex;
    flex-direction: column;
    padding: 8px;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
    height: 36px;
    margin-bottom: 1px;
    margin-top: 0px !important;

    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      background: #ededed;
    }

    .label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 220px;
    }
  }
`;

const ColumnsWrapper = styled(Box)`
  padding: 0px 20px 0px 0px;
`;
