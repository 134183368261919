import { useEffect, useState } from "react";
import { loadingIndicator } from "../../../components/common/loading";
import Notifications from "../../../components/common/notifications";
import { toast } from "../../../components/common/toast";
import { DependencyCategory, Dependency } from "../../../models/Dependency";
import Accordian from "../../../components/common/accordian";
import useSite from "../../../hooks/useSite";
import { getDependenciesforSite } from "../../../services/SiteContinuityPlan";
import SaveSiteContinuityPlan from "./SaveContinuityPlan";
import SiteElementLayout from "../../../components/common/siteElementLayout";
import { ContinuityPlanInfo } from "../../../constants/SiteInfo";
import { McNotification } from "@maersk-global/mds-react-wrapper";
import { StyledListItem } from "../../../components/common/StyledComponents";
import { StaticIcon } from "../../../components/common/icons";
import Box from "../../../components/common/box";
import { siteComponentKeys } from "../../../constants/Site";
import NotificationWithIcons from "../../../components/common/notificationsWithIcon";

export default function SiteContinuityPlan({
  handleStatusChange,
  componentStatus,
  sectionId,
}: Readonly<{
  handleStatusChange: () => void;
  sectionId: number;
  componentStatus: any;
}>) {
  const [loading, setLoading] = useState(true);
  const [payload, setPayload] = useState([]);
  const [canAddContinuityPlan, setCanAddContinuityPlan] = useState(false);
  const { siteId, site } = useSite();
  const [notificationsMessage, setNotificationsMessage] = useState(
    "You can't add ContinuityPlan as there are no Dependencies configured by the Admin."
  );

  useEffect(() => {
    if (siteId > 0) {
      getDependencies(siteId, site?.versionId);
    }
  }, [siteId]);

  const getDependencies = (siteId: number, versionId: number) => {
    getDependenciesforSite(siteId.toString(), versionId.toString())
      .then((res) => {
        if (res) {
          setPayload(res ?? []);
          setLoading(false);
          setCanAddContinuityPlan(res?.length > 0);
        }
      })
      .catch((err): void => {
        setNotificationsMessage(err.message);
        setLoading(false);
      });
  };

  const doesAllDependencyhaveContinuityPlan = (
    dependencies: any[]
  ): boolean => {
    return dependencies.every(
      (dependency) => dependency.doesContinuityPlanExist === true
    );
  };

  const mapContinuityPlan = (depCategory: DependencyCategory) => {
    return (
      <Accordian
        headerText={depCategory.name}
        key={depCategory.id}
        id={depCategory.id}
        displayWarning={
          !doesAllDependencyhaveContinuityPlan(depCategory.dependencies)
        }
      >
        {depCategory.dependencies.map((dependency: Dependency) => {
          return mapDependency(dependency, depCategory.id);
        })}
      </Accordian>
    );
  };

  const mapDependency = (dependency: Dependency, categoryId: number) => {
    return (
      <SaveSiteContinuityPlan
        handleStatusChange={handleStatusChange}
        dependency={dependency}
        dependencyCategoryId={categoryId}
        currentSiteId={siteId}
        archeTypeId={site.archeTypeId}
        versionId={site?.versionId}
      />
    );
  };

  const displayWarning = () => {
    return (
      <>
        <NotificationWithIcons
          message="Missing continuity plan for some dependencies. Please ensure all dependencies have continuity plan."
          appearance="warning"
          icon="exclamation-triangle"
        />
      </>
    );
  };

  const notifications = (
    <Notifications description={[notificationsMessage]} variant={"error"} />
  );

  return loading ? (
    loadingIndicator
  ) : !canAddContinuityPlan ? (
    notifications
  ) : (
    <SiteElementLayout
      heading="Continuity Plan"
      sectionId={sectionId}
      popoverContent={ContinuityPlanInfo.Section_Heading}
    >
      {!componentStatus[siteComponentKeys.continuityPlan].status &&
        displayWarning()}
      {payload.map((depCatagetory: DependencyCategory) => {
        return mapContinuityPlan(depCatagetory);
      })}
    </SiteElementLayout>
  );
}
