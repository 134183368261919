import { doGet, doPost, doPut } from "../../utils";
import { getApiUrl } from "../../utils/application-utils";

export const getExternalContact = (
  siteId: number,
  version: number
): Promise<any> =>
  doGet(`${getApiUrl()}/externalcontacts/${siteId}/${version}`);

export const saveExternalContact = (internalContact: any): Promise<any> => {
  let apiUrl = getApiUrl();
  return internalContact.id === 0
    ? doPost(`${apiUrl}/externalcontacts/create`, internalContact)
    : doPut(`${apiUrl}/externalcontacts/update`, internalContact);
};
