import { doGet, doPost, doPut } from "../../utils";
import { getApiUrl } from "../../utils/application-utils";

export const getSiteDependencies = (
  siteId: number,
  versionId: number
): Promise<any> =>
  doGet(`${getApiUrl()}/site/dependency/${siteId}/${versionId}`);

export const saveMappedSiteDependencies = (mappings: any): Promise<any> =>
  doPost(`${getApiUrl()}/siteprocess/dependency/configure`, mappings);

export const createSiteDependency = (dependency: any): Promise<any> =>
  doPost(`${getApiUrl()}/site/dependency/create`, dependency);

export const updateSiteDependency = (updateDependency: any): Promise<any> =>
  doPut(`${getApiUrl()}/site/dependency/update`, updateDependency);
