import { Roles } from "../utils";
import { siteBCPStatus } from "./Site";

export const SiteModes = {
  Read: "read", // Read says whether you as a member of the site can only view individual sections. You cannot submit individual sections.
  Write: "write", // Write says whether you as a member of the site can submit individual sections.
};

export const SiteActions = {
  Start: "start",
  Submit: "submit",
  Approve: "pendingvalidation",
  Rework: "reworkinprogress",
  Reject: "rejected",
  Validated: "validated",
};

const baseConfig = [
  { Status: siteBCPStatus.NotStarted, Mode: SiteModes.Read, Actions: [] },
  { Status: siteBCPStatus.InProgress, Mode: SiteModes.Read, Actions: [] },
  { Status: siteBCPStatus.PendingApproval, Mode: SiteModes.Read, Actions: [] },
  {
    Status: siteBCPStatus.PendingValidation,
    Mode: SiteModes.Read,
    Actions: [],
  },
  { Status: siteBCPStatus.Validated, Mode: SiteModes.Read, Actions: [] },
  { Status: siteBCPStatus.ReworkInProgress, Mode: SiteModes.Read, Actions: [] },
  { Status: siteBCPStatus.Rejected, Mode: SiteModes.Read, Actions: [] },
];

const siteCoordinatorConfig = [
  { Status: siteBCPStatus.NotStarted, Mode: SiteModes.Write, Actions: [] },
  {
    Status: siteBCPStatus.InProgress,
    Mode: SiteModes.Write,
    Actions: [SiteActions.Submit],
  },
  { Status: siteBCPStatus.PendingApproval, Mode: SiteModes.Read, Actions: [] },
  {
    Status: siteBCPStatus.PendingValidation,
    Mode: SiteModes.Read,
    Actions: [],
  },
  {
    Status: siteBCPStatus.Validated,
    Mode: SiteModes.Read,
    Actions: [SiteActions.Start],
  },
  {
    Status: siteBCPStatus.ReworkInProgress,
    Mode: SiteModes.Write,
    Actions: [SiteActions.Submit],
  },
  {
    Status: siteBCPStatus.Rejected,
    Mode: SiteModes.Read,
    Actions: [SiteActions.Start],
  },
];

const siteOwnerConfig = [
  {
    Status: siteBCPStatus.NotStarted,
    Mode: SiteModes.Write,
    Actions: [SiteActions.Start],
  },
  {
    Status: siteBCPStatus.InProgress,
    Mode: SiteModes.Write,
    Actions: [SiteActions.Submit],
  },
  {
    Status: siteBCPStatus.PendingApproval,
    Mode: SiteModes.Write,
    Actions: [SiteActions.Rework, SiteActions.Approve],
  },
  {
    Status: siteBCPStatus.PendingValidation,
    Mode: SiteModes.Read,
    Actions: [],
  },
  {
    Status: siteBCPStatus.Validated,
    Mode: SiteModes.Read,
    Actions: [SiteActions.Start],
  },
  {
    Status: siteBCPStatus.ReworkInProgress,
    Mode: SiteModes.Write,
    Actions: [SiteActions.Submit],
  },
  {
    Status: siteBCPStatus.Rejected,
    Mode: SiteModes.Read,
    Actions: [SiteActions.Start],
  },
];

const brAdminConfig = [
  { Status: siteBCPStatus.NotStarted, Mode: SiteModes.Read, Actions: [] },
  { Status: siteBCPStatus.InProgress, Mode: SiteModes.Read, Actions: [] },
  { Status: siteBCPStatus.PendingApproval, Mode: SiteModes.Read, Actions: [] },
  {
    Status: siteBCPStatus.PendingValidation,
    Mode: SiteModes.Read,
    Actions: [SiteActions.Validated, SiteActions.Reject, SiteActions.Rework],
  },
  { Status: siteBCPStatus.Validated, Mode: SiteModes.Read, Actions: [] },
  { Status: siteBCPStatus.ReworkInProgress, Mode: SiteModes.Read, Actions: [] },
  { Status: siteBCPStatus.Rejected, Mode: SiteModes.Read, Actions: [] },
];

export const SiteRoleStatusMapping = {
  [Roles.SITECOORDINATOR]: siteCoordinatorConfig,
  [Roles.SITEOWNER]: siteOwnerConfig,
  [Roles.BRADMIN]: brAdminConfig,
  [Roles.SITEUSER]: baseConfig,
  [Roles.OFFICEUSER]: baseConfig,
  [Roles.SNRADMIN]: baseConfig,
};
