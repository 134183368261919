import { createContext, useEffect, useMemo, useState } from "react";
import {
  ConcreteRoles,
  Roles,
  buildConcreteRolesFromTokenRoles,
  buildRolesFromTokenRoles,
  getUserInfo,
} from "../../utils";
import { toast } from "../common/toast";
import SiteLayout from "./siteLayout";
import GscAdminLayout from "./gscAdminLayout";
import SiteAdminLayout from "./siteAdminLayout";
import { loadingIndicator } from "../common/loading";

export interface UserContextProps {
  email: string;
  roles: Array<string>;
}
export const UserContext = createContext<UserContextProps>({
  email: "",
  roles: [],
});

export default function Layout() {
  const [userName, setUserName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [roles, setRoles] = useState<Array<string>>([]);
  const [isValidUser, setIsValidUser] = useState<boolean>(true);
  const [concreteRole, setConcreteRole] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let userInfo = getUserInfo();

    if (userInfo && userInfo.userName && userInfo.roles) {
      setUserName(userInfo.userName);
      setEmail(userInfo.email);
      let tokenRoles = buildRolesFromTokenRoles(userInfo.roles);
      setRoles(tokenRoles);

      let concreteRole = buildConcreteRolesFromTokenRoles(tokenRoles);
      setConcreteRole(concreteRole);

      if (tokenRoles.length === 0) setIsValidUser(false);
    } else {
      setIsValidUser(false);
    }
  }, []);

  useEffect(() => {
    if (roles.length > 0) setLoading(false);
  }, [roles]);

  useEffect(() => {
    if (!isValidUser) {
      toast(
        "Unauthorized",
        "You do not have enough permission to access this page. Please contact your administrator.",
        "error"
      );
    }
  }, [isValidUser]);

  const userContextValue = useMemo(
    () => ({ email: email, roles: roles }),
    [email, roles]
  );

  const renderLayout = () => {
    if (
      concreteRole === ConcreteRoles.SITECOORDINATOR ||
      concreteRole === ConcreteRoles.SITEOWNER ||
      concreteRole === ConcreteRoles.SITEUSER
    ) {
      return <SiteLayout userName={userName} role={concreteRole} />;
    }
    if (roles.includes(Roles.BRADMIN) || roles.includes(Roles.OFFICEUSER)) {
      return <SiteAdminLayout userName={userName} role={concreteRole} />;
    }
    if (roles.includes(Roles.GSCADMIN) || roles.includes(Roles.PROCESSOWNER)) {
      return <GscAdminLayout userName={userName} role={concreteRole} />;
    }
    if (roles.includes(Roles.SNRADMIN)) {
      return (
        <>
          <GscAdminLayout userName={userName} role={concreteRole} />;
        </>
      );
    }

    return null;
  };

  if (loading) return loadingIndicator;

  return (
    <>
      {isValidUser && (
        <UserContext.Provider value={userContextValue}>
          {renderLayout()}
        </UserContext.Provider>
      )}
    </>
  );
}
