export interface InternalContactsModel {
  id: number;
  userType: string;
  displayName: string;
  jobTitle: string;
  email: string;
  mobilePhone: string;
  brRole: string;
  isPrimary: boolean;
  isSecondary: boolean;
  isIncidentResponseTeam: boolean;
  incidentResponseTeam: string;
  isAzureContact: boolean; // True if the contact is added at the time of BCP creation
}

export interface InternalContactsKeys {
  name: string;
  jobTitle: string;
  email: string;
  phone: string;
  brRole: string;
  roleType: string;
  incidentResponseTeam: string;
}

export interface SaveInternalContactsModel {
  versionId: number;
  siteId: number;
  id: number;
  userType: string;
  displayName: string;
  jobTitle: string;
  email: string;
  mobilePhone: string;
  brRole: string;
  isPrimary: boolean;
  isSecondary: boolean;
  isIncidentResponseTeam: boolean;
  isAzureContact: boolean;
}

export const userKind = {
  primary: "primary",
  secondary: "secondary",
};
