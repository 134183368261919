import { Box, MultiSelect, Select } from "@maersk-global/community-react";
import { McRadio, McRadioGroup } from "@maersk-global/mds-react-wrapper";
import { useEffect, useReducer, useState } from "react";
import { loadingIndicator } from "../../../components/common/loading";
import { getBrands } from "../../../services/Brands";
import { toast, toastError } from "../../../components/common/toast";
import {
  defaultSiteFilter,
  siteFilterActions,
  siteFilterReducer,
} from "./reducers/siteFilter";
import { siteBcpStatusSelectList } from "../../../constants/Site";
import styled from "styled-components";
import { searchSite } from "../../../services/Sites";
import {
  ArcheType,
  Area,
  Brand,
  Region,
  SiteType,
} from "../../../models/Brand";

const initialFilteredFields: { [key: string]: any } = {
  brandIds: [],
  siteTypeIds: [],
  countryIds: [],
  regionIds: [],
  areaIds: [],
  bcpStatusIds: [],
  archetypeIds: [],
  siteIds: [],
  isSiteAssignedtoMeOnly: "false",
};

export const transformQueryString = (queryString: string) => {
  const params = new URLSearchParams(queryString);
  const result: { [key: string]: any } = {};

  params.forEach((value, key) => {
    if (result[key]) {
      if (Array.isArray(result[key])) {
        result[key].push(value);
      } else {
        result[key] = [result[key], [value]];
      }
    } else {
      result[key] = [value];
    }
  });

  // Convert numeric strings to numbers and boolean strings to booleans
  Object.keys(result).forEach((key) => {
    if (Array.isArray(result[key])) {
      result[key] = result[key].map((val: string) => {
        if (!isNaN(Number(val))) {
          return Number(val);
        }
        if (val === "true" || val === "false") {
          return val === "true";
        }
        return val;
      });
    } else if (!isNaN(Number(result[key]))) {
      result[key] = Number(result[key]);
    } else if (result[key] === "true" || result[key] === "false") {
      result[key] = result[key] === "true";
    }
  });

  return result;
};

export default function SiteFilter({
  onChange,
  currentFilter,
}: Readonly<{
  currentFilter: string;
  onChange: (columns: string) => void;
}>) {
  const [loading, setLoading] = useState<boolean>(true);
  const [filteredFields, setFilteredFields] = useState<any>(
    initialFilteredFields
  );
  const [filter, dispatch] = useReducer(siteFilterReducer, defaultSiteFilter);
  const [isBrandsLoaded, setIsBrandsLoaded] = useState<boolean>(false);

  useEffect(() => {
    getBrands()
      .then((res) => {
        res.brands.sort((a: Brand, b: Brand) =>
          a.businessBrandName.localeCompare(b.businessBrandName)
        );
        dispatch({
          type: siteFilterActions.SET_BRANDS_DROPDOWN,
          brandsDropdown: res.brands,
        });
        dispatch({
          type: siteFilterActions.SET_COUNTRIES_DROPDOWN,
          countriesDropdown: res.masterCountries,
        });
      })
      .then(() => {
        searchSite()
          .then((res) => {
            dispatch({
              type: siteFilterActions.SET_SITES_DROPDOWN,
              sitesDropdown: res,
            });
            setIsBrandsLoaded(true);
          })
          .catch((err) => {
            toastError(err.message);
          });
      })
      .catch((err): void => {
        toast("error", err.message, "error");
      });
  }, []);

  useEffect(() => {
    if (isBrandsLoaded) {
      const filteredFields = transformQueryString(currentFilter);

      const mergedFilters = { ...initialFilteredFields, ...filteredFields };

      setFilteredFields(mergedFilters);

      dispatch({
        type: siteFilterActions.MAP_STATE_FROM_FILTERS,
        mergedFilters: mergedFilters,
      });
      setLoading(false);
    }
  }, [isBrandsLoaded]);

  const promiseOptions = (inputValue: string) => {
    return new Promise<any>((resolve) => {
      setTimeout(() => {
        resolve(filterCountry(inputValue));
      }, 1000);
    });
  };

  const brandPromiseOptions = (inputValue: string) => {
    return new Promise<any>((resolve) => {
      setTimeout(() => {
        resolve(filterBrand(inputValue));
      }, 1000);
    });
  };

  const sitePromiseOptions = (inputValue: string) => {
    return new Promise<any>((resolve) => {
      setTimeout(() => {
        resolve(filterSite(inputValue));
      }, 1000);
    });
  };

  const filterCountry = (inputValue: string) => {
    return filter.countriesDropdown.filter((i: any) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const filterSite = (inputValue: string) => {
    return filter.sitesDropdown.filter((i: any) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const filterBrand = (inputValue: string) => {
    return filter.brandsDropdown.filter((i: any) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const handleChange = (name: string, value: any) => {
    let filterValue: any[] = [];
    switch (name) {
      case "siteTypeIds":
        dispatch({
          type: siteFilterActions.ON_SITETYPE_DROPDOWN_CHANGE,
          siteTypeId: value,
        });
        filterValue = [value.id];
        break;
      case "regionIds":
        dispatch({
          type: siteFilterActions.ON_REGIONS_DROPDOWN_CHANGE,
          regionId: value,
        });
        filterValue = [value.id];
        break;
      case "areaIds":
        dispatch({
          type: siteFilterActions.ON_AREAS_DROPDOWN_CHANGE,
          areaId: value,
        });
        filterValue = [value.id];
        break;
      case "archetypeIds":
        dispatch({
          type: siteFilterActions.ON_ARCHETYPE_DROPDOWN_CHANGE,
          archeType: value,
        });
        filterValue = value.map((val: any) => val.id);
        break;
      case "brandIds":
        dispatch({
          type: siteFilterActions.ON_BRANDS_DROPDOWN_CHANGE,
          brand: value,
        });
        filterValue = [value.id];
        break;
      case "countryIds":
        dispatch({
          type: siteFilterActions.ON_COUNTRY_DROPDOWN_CHANGE,
          countryIds: value,
        });
        filterValue = value.map((val: any) => val.id);
        break;
      case "bcpStatusIds":
        dispatch({
          type: siteFilterActions.ON_BCPSTATUS_DROPDOWN_CHANGE,
          bcpStatus: value,
        });
        filterValue = value.map((val: any) => val.id);
        break;
      case "siteIds":
        dispatch({
          type: siteFilterActions.ON_SITES_DROPDOWN_CHANGE,
          siteIds: value,
        });
        filterValue = value.map((val: any) => val.id);
        break;
      case "isSiteAssignedtoMeOnly":
        dispatch({
          type: siteFilterActions.ON_SITE_ASSIGNMENT_TYPE_CHANGE,
          siteAssignmentType: value,
        });
        filterValue = [value === "true"];
        break;
      default:
        break;
    }

    populateFilterComponents(name, filterValue);
  };

  const onClearDropDown = (name: string) => {
    switch (name) {
      case "brandIds":
        clearFilters([
          "siteTypeIds",
          "archetypeIds",
          "regionIds",
          "areaIds",
          "brandIds",
        ]);
        break;
      case "siteTypeIds":
        clearFilters(["siteTypeIds", "archetypeIds"]);
        break;
      case "regionIds":
        clearFilters(["areaIds", "regionIds"]);
        break;
      default:
        break;
    }
  };

  const clearFilters = (names: string[]) => {
    names.forEach((name) => {
      populateFilterComponents(name, []);
    });
  };

  const populateFilterComponents = (name: string, value: any) => {
    filteredFields[name] = value;
    setFilteredFields({ ...filteredFields });

    const stringifiedFields = Object.keys(filteredFields)
      .flatMap((key) => {
        const fieldValue = filteredFields[key];
        if (Array.isArray(fieldValue)) {
          return fieldValue.map((val) => `${key}=${val}`);
        }
        if (fieldValue !== undefined && fieldValue !== null)
          return `${key}=${fieldValue}`;

        return [];
      })
      .join("&");

    onChange(stringifiedFields);
  };

  if (loading) return loadingIndicator;

  return (
    <>
      <SingleRowWrapper display="grid" gridTemplateColumns="1fr 1fr 1fr">
        <Box display="flex" style={{ flexDirection: "column" }}>
          <Select
            fit="small"
            id="brand"
            isClearable
            label="Brand"
            name="brand"
            onChange={(e: any) => {
              if (e) handleChange("brandIds", e);
              else {
                dispatch({
                  type: siteFilterActions.ON_BRANDS_DROPDOWN_CLEAR,
                });
                onClearDropDown("brandIds");
              }
            }}
            options={filter.brandsDropdown}
            orientation="vertical"
            placeholder=""
            value={filter.brand}
            defaultOptions={filter.brandsDropdown}
            variant="default"
            width={100}
            isAsync
            loadOptions={brandPromiseOptions}
          />
        </Box>
        <Box display="flex" style={{ flexDirection: "column" }}>
          <Select
            fit="small"
            id="region"
            isClearable
            label="Region"
            name="regionIds"
            placeholder=""
            tooltipDescription="Select a brand first and then select a region associated with the brand."
            onChange={(e: any) => {
              if (e) handleChange("regionIds", e);
              else {
                dispatch({
                  type: siteFilterActions.ON_REGIONS_DROPDOWN_CLEAR,
                });
                onClearDropDown("regionIds");
              }
            }}
            options={filter.regionsDropdown.map((region: Region) => ({
              label: region.regionName,
              value: region.regionId,
              id: region.regionId,
            }))}
            orientation="vertical"
            value={filter.region}
            variant="default"
          />
        </Box>
        <Box display="flex" style={{ flexDirection: "column" }}>
        <MultiSelect
            fit="small"
            isClearable
            label="BCP Status"
            name="bcpStatusIds"
            onChange={(e) => handleChange("bcpStatusIds", e)}
            options={siteBcpStatusSelectList.map((item) => ({
              label: item.label,
              value: item.value,
              id: item.value,
            }))}
            orientation="vertical"
            renderValue="label"
            variant="default"
            id="bcpStatus"
            value={filter.bcpStatus}
            placeholder=""
            hasSelectAll={false}
          />
        </Box>
      </SingleRowWrapper>
      <SingleRowWrapper display="grid" gridTemplateColumns="1fr 1fr 1fr">
        <Box display="flex" style={{ flexDirection: "column" }}>
          <Select
            fit="small"
            id="siteType"
            isClearable
            label="Site Type"
            name="siteTypeIds"
            placeholder=""
            tooltipDescription="Select a brand first and then select a site type associated with the brand."
            onChange={(e: any) => {
              if (e) handleChange("siteTypeIds", e);
              else {
                dispatch({
                  type: siteFilterActions.ON_SITETYPE_DROPDOWN_CLEAR,
                });
                onClearDropDown("siteTypeIds");
              }
            }}
            options={filter.siteTypesDropdown.map((siteType: SiteType) => ({
              label: siteType.siteTypeName,
              value: siteType.id,
              id: siteType.id,
            }))}
            orientation="vertical"
            value={filter.siteType}
            variant="default"
          />
        </Box>
        <Box display="flex" style={{ flexDirection: "column" }}>
          <Select
            fit="small"
            id="area"
            isClearable
            label="Area"
            name="areaIds"
            placeholder=""
            tooltipDescription="Select a brand first then region and then select an area associated with the region."
            onChange={(e: any) => handleChange("areaIds", e)}
            options={filter.areasDropdown.map((area: Area) => ({
              label: area.areaName,
              value: area.areaId,
              id: area.areaId,
            }))}
            orientation="vertical"
            value={filter.areas}
            variant="default"
          />
        </Box>
        <Box display="flex" style={{ flexDirection: "column" }}>
          <MultiSelect
            fit="small"
            isClearable
            label="Site"
            name="siteIds"
            onChange={(e) => handleChange("siteIds", e)}
            options={filter.sitesDropdown}
            orientation="vertical"
            renderValue="label"
            variant="default"
            id="siteIds"
            value={filter.sites}
            placeholder=""
            hasSelectAll={false}
            loadOptions={sitePromiseOptions}
            defaultOptions={filter.sitesDropdown}
            isAsync
          />
        </Box>
      </SingleRowWrapper>
      <SingleRowWrapper display="grid" gridTemplateColumns="1fr 1fr 1fr">
        <Box display="flex" style={{ flexDirection: "column" }}>
          <MultiSelect
            fit="small"
            isClearable
            label="Archetype"
            name="archetypeIds"
            tooltipDescription="Select a brand first and then select a sitetype and then the archetype for the sitetype."
            onChange={(e) => handleChange("archetypeIds", e)}
            options={filter.archeTypesDropdown.map((archetype: ArcheType) => ({
              id: archetype.id,
              label: archetype.archeTypeName,
              value: archetype.id,
            }))}
            orientation="vertical"
            renderValue="label"
            variant="default"
            id="archetype"
            value={filter.archeType}
            placeholder=""
            hasSelectAll={false}
          />
        </Box>
        <Box display="flex" style={{ flexDirection: "column" }}>
          <MultiSelect
            fit="small"
            id="country"
            isClearable
            label="Country"
            name="countryIds"
            onChange={(e) => handleChange("countryIds", e)}
            options={filter.countriesDropdown}
            value={filter.countries}
            orientation="vertical"
            placeholder=""
            renderValue="label"
            loadOptions={promiseOptions}
            defaultOptions={filter.countriesDropdown}
            isAsync
          />
        </Box>
        <Box style={{ marginTop: "20px" }}>
          <McRadioGroup
            legend=" "
            name="site-assignment-type"
            fit="small"
            orientation="horizontal"
            onClick={(e: any) =>
              handleChange("isSiteAssignedtoMeOnly", e.target.value)
            }
          >
            <McRadio
              name="site-assignment-type"
              value="false"
              label="All Sites"
              checked={filter.siteAssignmentType === "false"}
            />
            <McRadio
              name="site-assignment-type"
              value="true"
              label="Assigned to me"
              checked={filter.siteAssignmentType === "true"}
            />
          </McRadioGroup>
        </Box>
      </SingleRowWrapper>
    </>
  );
}

const SingleRowWrapper = styled(Box)`
  display: grid;
  gap: 12px;
  alignitems: flex-start;
  margin: 10px 0;
`;
