import React, { useContext, useEffect, useReducer, useState } from "react";
import useSite from "../../../hooks/useSite";
import { getSingleSiteConfiguration } from "../../../services/SiteConfiguration";
import { ConfigurationMapping } from "../../../models/AlternativeLocation";
import Notifications from "../../../components/common/notifications";
import Welcome from "../../Welcome";
import {
  InternalContactsModel,
  InternalContactsKeys,
  userKind,
} from "../../../models/InternalContacts";
import AddEditInternalContact from "./modals/addEditInternalContact";
import { getInternalContact } from "../../../services/Site/InternalContacts";
import { loadingIndicator } from "../../../components/common/loading";
import { toastError } from "../../../components/common/toast";
import { Box } from "@maersk-global/community-react";
import { McButton, McTable } from "@maersk-global/mds-react-wrapper";
import Heading from "../../../components/common/heading";
import { Button } from "../../../components/common/button";
import {
  defaultDeleteEntityState,
  deleteActions,
  deleteReducer,
  onDeleteEntity,
} from "../../../reducers/delete";
import DeleteModal from "../../../components/common/deleteModal";
import { Cards } from "../../../components/common/cards";
import { ModeContext } from "../SiteBCM";
import SiteElementsRenderer from "../../../components/common/siteElementsRenderer";
import { SiteModes } from "../../../constants/SiteBCM";
import HorizontalLine from "../../../components/common/horizontalLine";
import Action from "../../../components/common/action";
import { chunkArray } from "../../../utils/utils";

export default function InternalContacts({
  handleStatusChange,
}: Readonly<{ handleStatusChange: () => void }>) {
  const { siteId, site } = useSite();
  const mode = useContext(ModeContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [configurations, setConfigurations] = useState<
    ConfigurationMapping | undefined
  >(undefined);
  const [isApplicable, setIsApplicable] = useState<boolean>(true);
  const [InternalContacts, setInternalContacts] = useState<any[]>([]);
  const [InternalContactstableData, setInternalContactsTableData] = useState<
    any[]
  >([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editableInternalContact, setEditableInternalContact] = useState<
    any | null
  >(null);
  const [showtable, setShowTable] = useState<boolean>(false);
  const [deleteEntityState, dispatchDelete] = useReducer(
    deleteReducer,
    defaultDeleteEntityState
  );

  const onAddNewInternalContactClick = () => {
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setEditableInternalContact(null);
    setIsModalOpen(false);
  };

  const handleDelete = (id: number, path: string) => {
    onDeleteEntity(
      dispatchDelete,
      id,
      "InternalContact",
      path,
      "internalcontacts",
      ""
    );
  };

  const internalConatcsKey: InternalContactsKeys = {
    name: "name",
    jobTitle: "job-title",
    email: "email",
    phone: "phone",
    brRole: "br-role",
    roleType: "br-role-type",
    incidentResponseTeam: "incident-responses-team",
  };

  const SetShowTable = () => {
    setShowTable(!showtable);
  };

  const getInternalContacts = () => {
    getInternalContact(site.id, site.versionId)
      .then((response) => {
        if (response.length > 0) {
          response.forEach((element: InternalContactsModel) => {
            element.isIncidentResponseTeam
              ? (element.incidentResponseTeam = "Yes")
              : (element.incidentResponseTeam = "No");
          });
          setInternalContactsTableData(response);
          let chunkedArray = chunkArray(response, 2);
          setInternalContacts(chunkedArray);
        } else {
          setInternalContacts([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        toastError(error.message);
      });
  };

  const onSave = () => {
    getInternalContacts();
    handleStatusChange();
  };
  const getBRRoleType = (internalContact: InternalContactsModel) => {
    if (internalContact.isPrimary === true) {
      return "primary";
    } else if (internalContact.isSecondary === true) {
      return "secondary";
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (siteId > 0) {
      getSingleSiteConfiguration(site.id, site.versionId, "internal-contacts")
        .then((response) => {
          if (response.length === 0) {
            setIsApplicable(false);
            setLoading(false);
          }

          let mappedResponse: ConfigurationMapping = response.reduce(
            (acc, item) => {
              return {
                ...acc,
                [item.key]: item.configurationValue,
              };
            },
            {}
          );
          setConfigurations(mappedResponse);
        })
        .catch((error) => {
          toastError(error.message);
        })
        .then(() => {
          if (isApplicable) {
            getInternalContacts();
          }
        });
    }
  }, [siteId]);



    const addRowIdToContacts = (InternalContactstableData: any[]) => {
      return InternalContactstableData.map((row, index) => ({
        ...row,
     rowid: index,
      }));
    };  

  const constructActionItems = (row: any) => {
    let items = [];

    mode === SiteModes.Write &&
      items.push({
        label: "Edit",
        onClick: () => {
          setIsModalOpen(true);
          setEditableInternalContact(row);
        },
      });
    mode === SiteModes.Write &&
      !row.isAzureContact &&
      items.push({
        label: "Delete",
        onClick: () => handleDelete(row.id, row.userType),
      });

    return items;
  };

  const displayTable = () => {
    return (
      <>
        <br />
        <McTable
          disableroundedcorners
          data={addRowIdToContacts(InternalContactstableData)}
          expand
          datakey="rowid"
          columns={[
            { id: "userType", label: "Type Of User" },
            { id: "displayName", label: "Name" },
            { id: "jobTitle", label: "Title" },
            { id: "email", label: "Email" },
            { id: "mobilePhone", label: "Phone" },
            {
              id: "incidentResponseTeam",
              label: "Incident Response Team"
            },
            { id: "rowid", label: "", sortDisabled: true },
          ]}
          fit="small"
        >
          {addRowIdToContacts(InternalContactstableData).map((row: any) => (
            <>
              <div key={`${row.rowid}_rowid`} slot={`${row.rowid}_rowid`}>
                <div style={{ float: "right" }}>
                  {mode === SiteModes.Write ? (
                    <Action items={constructActionItems(row)} />
                  ) : null}
                </div>
              </div>
            </>
          ))}
        </McTable>
      </>
    );
  };

  const displayasCard = () => {
    return (
      <>
        {InternalContacts.map((chunk: any, chunkIndex: number) => (
          <Box
            key={chunkIndex}
            display="grid"
            gridTemplateColumns="1fr 1fr"
            gap="10px"
          >
            {chunk.map((internalContact: InternalContactsModel) => (
              <Box
                key={internalContact.id}
                style={{
                  padding: "10px",
                  border: "1px solid var(--mds_brand_appearance_neutral_weak_border-color)",
                  boxSizing: "border-box",
                  height: "auto",
                  overflow: "auto",
                  margin: "8px",
                }}
              >
                <Cards
                  items={[
                    { label: "", value: internalContact.displayName },
                    { label: "", value: internalContact.jobTitle },
                    { label: "", value: internalContact.email },
                    { label: "", value: internalContact.mobilePhone },
                    null,
                    { label: "", value: `BR Role : ${internalContact.brRole}` },
                    {
                      label: "BR Role Type ",
                      value: `${getBRRoleType(internalContact)}`,
                    },
                    {
                      label: "Incident response team ",
                      value: `${
                        internalContact.isIncidentResponseTeam ? "Yes" : "No"
                      }`,
                    },
                    null,
                  ]}
                  key={internalContact.id}
                  id={internalContact.id}
                  title={internalContact.userType}
                  allowEdit={mode === SiteModes.Write}
                  allowDelete={
                    mode === SiteModes.Write && !internalContact.isAzureContact
                  }
                  onDelete={handleDelete}
                  onEdit={() => {
                    setIsModalOpen(true);
                    setEditableInternalContact(internalContact);
                  }}
                />
              </Box>
            ))}
          </Box>
        ))}
      </>
    );
  };

  if (loading) return loadingIndicator;

  // IF NOT APPLICABLE SHOW WARNING
  if (!isApplicable) {
    return (
      <Notifications
        description={[
          "This section is not applicable for your site.",
        ]}
        variant="warning"
      />
    );
  }

  return (
    <>
      <DeleteModal
        isOpen={deleteEntityState.isModalOpen}
        onSave={onSave}
        deleteEntity={deleteEntityState.deleteEntity}
        onClose={() => {
          dispatchDelete({
            type: deleteActions.SET_DELETE_MODAL_CLOSE,
          });
          handleStatusChange();
        }}
      />

      <AddEditInternalContact
        isOpen={isModalOpen}
        editableInternalContact={editableInternalContact}
        configurations={configurations}
        keys={internalConatcsKey}
        onClose={onModalClose}
        onSave={onSave}
      />

      {InternalContacts.length == 0 ? (
        <Welcome
          heading={"Internal Contacts"}
          subHeading={
            "There’s no internal contacts for the site. Start adding one."
          }
          buttonLabel={"Add New Internal Contact"}
          icon="plus"
          onButtonClick={onAddNewInternalContactClick}
          isDisabled={mode === SiteModes.Read}
        />
      ) : (
        <>
          <SiteElementsRenderer mode={mode}>
            <>
              <Heading heading={""} subheading={""}>
                <Button
                  click={onAddNewInternalContactClick}
                  label="Add New Internal Contact"
                  fit="medium"
                />
              </Heading>
              <HorizontalLine />
            </>
          </SiteElementsRenderer>
          <Heading heading={""} subheading={""}>
            <McButton
              label={showtable ? "Show as Cards" : "Show as Table"}
              appearance="neutral"
              variant="outlined"
              fit="small"
              click={SetShowTable}
            />
          </Heading>

          {!showtable ? displayasCard() : displayTable()}
        </>
      )}
    </>
  );
}
