export const processDependenciesActions = {
  SET_PROCESSES: "SET_PROCESSES",
  SET_DEPENDENCIES: "SET_DEPENDENCIES",
  SET_LOADING: "SET_LOADING",
  SET_DEPENDENCY_CATEGORY_TAGS: "SET_DEPENDENCY_CATEGORY_TAGS",
  SET_DEPENDENCY_CATEGORY_TAGS_INITIAL: "SET_DEPENDENCY_CATEGORY_TAGS_INITIAL",
  SET_DEPENDENCY_UPDATED: "SET_DEPENDENCY_UPDATED",
  REMOVE_DEPENDENCY_TAG: "REMOVE_DEPENDENCY_TAG",
};

export const defaultProcessDependencies = {
  processes: [],
  isSubprocess: false,
  loading: true,
  dependencies: [],
  dependencyCategoryTags: [],
  processDependencies: [],
  mappedProcessesDependencies: [],
  mappedProcessDependenciesCopy: [],
};

export const processDependenciesReducer = (state: any, action: any) => {
  switch (action.type) {
    case processDependenciesActions.SET_PROCESSES:
      return {
        ...state,
        isSubprocess: action.value.doesSubProcessExist,
        processes: action.value.processImpactMappings,
      };
    case processDependenciesActions.SET_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case processDependenciesActions.SET_DEPENDENCIES:
      let dependencies = mapDependencies(action.value);
      let dependencyCategoryTags = dependencyProcessesMapping(dependencies);
      return {
        ...state,
        dependencies: dependencies,
        dependencyCategoryTags: dependencyCategoryTags,
      };
    case processDependenciesActions.SET_DEPENDENCY_CATEGORY_TAGS_INITIAL: {
      let mapping = ensureDependencies(
        action.value.mappings,
        state.dependencies
      );
      return {
        ...state,
        mappedProcessesDependencies: mapping,
        mappedProcessDependenciesCopy: mapping,
      };
    }
    case processDependenciesActions.SET_DEPENDENCY_UPDATED:
      let processDependencies = setProcessDependencies(
        state.processDependencies,
        action.processDependencies,
        action.processId
      );
      let processDependenciesCopy = state.mappedProcessDependenciesCopy;
      let mappedProcessDependenciesCopy = setMappedProcessDependencies(
        action.processId,
        action.processDependencies,
        processDependenciesCopy
      );
      return {
        ...state,
        processDependencies: processDependencies,
        mappedProcessDependenciesCopy: mappedProcessDependenciesCopy,
      };
    case processDependenciesActions.SET_DEPENDENCY_CATEGORY_TAGS:
      return {
        ...state,
        dependencyCategoryTags: setTags(
          state.dependencyCategoryTags,
          action.dependencyCategoryId,
          action.selectedDependencies,
          action.process,
          action.processName
        ),
      };
    case processDependenciesActions.REMOVE_DEPENDENCY_TAG:
      let updatedProcessDependencies = state.processDependencies.map(
        (mappedProcess: any) => {
          const updatedDependencies = mappedProcess.dependencies.map(
            (dependency: any) => {
              if (
                dependency.dependencyCategoryId === action.dependencyCategoryId
              ) {
                let filteredDependencies =
                  dependency.selectedDependencies.filter(
                    (item: any) => item.id !== action.dependencyId
                  );
                return {
                  ...dependency,
                  selectedDependencies: filteredDependencies,
                };
              }
              return dependency;
            }
          );

          return {
            ...mappedProcess,
            dependencies: updatedDependencies,
          };
        }
      );
      let updatedMappedProcessDependencies =
        state.mappedProcessDependenciesCopy.map((mappedProcess: any) => {
          const updatedDependencies = mappedProcess.selectedDependencies.map(
            (dependency: any) => {
              if (
                dependency.dependencyCategoryId === action.dependencyCategoryId
              ) {
                let filteredSelectedDependencyIds =
                  dependency.selectectedDependencyIds.filter(
                    (item: any) => item !== action.dependencyId
                  );
                return {
                  ...dependency,
                  selectectedDependencyIds: filteredSelectedDependencyIds,
                };
              }
              return dependency;
            }
          );
          return {
            ...mappedProcess,
            selectedDependencies: updatedDependencies,
          };
        });
      return {
        ...state,
        processDependencies: [...updatedProcessDependencies],
        mappedProcessesDependencies: [...updatedMappedProcessDependencies],
        mappedProcessesDependenciesCopy: [...updatedMappedProcessDependencies],
      };
    default:
      return state;
  }
};

const mapDependency = (dependency: any) => ({
  id: dependency.id,
  label: dependency.name,
  value: dependency.id,
  isSiteLevelDependency: dependency.isSiteLevelDependency ?? false,
});

const mapDependencies = (dependencies: any) => {
  if (!dependencies.dependencyCategories) return [];
  return dependencies.dependencyCategories.map((dependencyCategory: any) => {
    const mappedDependencies =
      dependencyCategory.dependencies.map(mapDependency);
    return {
      dependencyCategoryId: dependencyCategory.id,
      dependencyCategoryName: dependencyCategory.name,
      dependencyDropdown: mappedDependencies,
    };
  });
};

const dependencyProcessesMapping = (mapDependencies: any) => {
  let mapping = mapDependencies.map((dependencyCategory: any) => {
    return {
      dependencyCategoryId: dependencyCategory.dependencyCategoryId,
      dependencyCategoryName: dependencyCategory.dependencyCategoryName,
      tags: dependencyCategory.dependencyDropdown.map((dependency: any) => {
        return {
          id: dependency.id,
          name: dependency.label,
          processes: [],
        };
      }),
    };
  });
  return mapping;
};

const setTags = (
  dependencyCategoryTags: any,
  dependencyCategoryId: any,
  selectedDependencies: any,
  process: any,
  processName: string
) => {
  let existingCategoryWithTags = dependencyCategoryTags.find(
    (tag: any) => tag.dependencyCategoryId === dependencyCategoryId
  );
  let existingCategoryWithTagsIndex = dependencyCategoryTags.findIndex(
    (tag: any) => tag.dependencyCategoryId === dependencyCategoryId
  );

  let tags = existingCategoryWithTags.tags;

  const nonSelectedDependencies = tags.filter((tag: any) => {
    return !selectedDependencies.find((item: any) => item.id === tag.id);
  });

  tags.forEach((tag: any) => {
    let selectedDependency = selectedDependencies.find((item: any) => {
      return item.id === tag.id;
    });
    if (selectedDependency) {
      let isTagContainingProcess = tag.processes.find(
        (item: any) => item.id === process.id
      );
      if (!isTagContainingProcess) {
        let { id, name } = process;

        if (processName) {
          name = `${processName}/${name}`;
        }

        tag.processes[tag.processes.length] = { id: id, name: name };
      }
    }
  });

  tags.forEach((tag: any) => {
    let nonSelectedDependencyItem = nonSelectedDependencies.find(
      (item: any) => {
        return item.id === tag.id;
      }
    );
    if (nonSelectedDependencyItem) {
      let isTagContainingProcess = tag.processes.find(
        (item: any) => item.id === process.id
      );
      if (isTagContainingProcess) {
        tag.processes = tag.processes.filter(
          (item: any) => item.id !== process.id
        );
      }
    }
  });

  existingCategoryWithTags.tags = tags;

  dependencyCategoryTags[existingCategoryWithTagsIndex] =
    existingCategoryWithTags;

  return [...dependencyCategoryTags];
};

const setProcessDependencies = (
  processDependenciesState: any,
  processDependencies: any,
  processId: any
) => {
  let index = processDependenciesState.findIndex(
    (item: any) => item.processId === processId
  );
  if (index !== -1) {
    processDependenciesState[index] = {
      processId: processId,
      dependencies: processDependencies,
    };
  } else {
    processDependenciesState.push({
      processId: processId,
      dependencies: processDependencies,
    });
  }

  return [...processDependenciesState];
};

const ensureDependencies = (processes: any[], defaultDependencies: any[]) => {
  return processes.map((process: any) => {
    const existingCategories = process.selectedDependencies.map(
      (dep: any) => dep.dependencyCategoryId
    );

    const missingCategories = defaultDependencies.filter(
      (defaultDep: any) =>
        !existingCategories.includes(defaultDep.dependencyCategoryId)
    );

    const updatedDependencies = [
      ...process.selectedDependencies,
      ...missingCategories.map((missingDep: any) => ({
        dependencyCategoryId: missingDep.dependencyCategoryId,
        selectectedDependencyIds: [],
      })),
    ];

    return {
      ...process,
      selectedDependencies: updatedDependencies,
    };
  });
};

const setMappedProcessDependencies = (
  processId: any,
  processDependencies: any,
  mappedProcessDependencies: any
) => {
  const processIndex = mappedProcessDependencies.findIndex(
    (item: any) => item.processId === processId
  );

  if (processIndex !== -1) {
    const process = mappedProcessDependencies[processIndex];

    processDependencies.forEach((newDep: any) => {
      const existingDepIndex = process.selectedDependencies.findIndex(
        (dep: any) => dep.dependencyCategoryId === newDep.dependencyCategoryId
      );

      const selectedDependencyIds = newDep.selectedDependencies.map(
        (dep: any) => dep.id
      );

      if (existingDepIndex !== -1) {
        const newIds = selectedDependencyIds;

        process.selectedDependencies[
          existingDepIndex
        ].selectectedDependencyIds = newIds;
      } else {
        // Add new dependency category
        process.selectedDependencies.push({
          dependencyCategoryId: newDep.dependencyCategoryId,
          selectectedDependencyIds: selectedDependencyIds,
        });
      }
    });

    // Update the mappedProcessDependencies array
    mappedProcessDependencies[processIndex] = process;
  }

  return [...mappedProcessDependencies];
};
