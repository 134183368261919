import Box from "../../../components/common/box";
import styled from "styled-components";
import Charts from "./Charts";

export default function Widgets({
  details,
}: Readonly<{
  details: any;
}>) {
  const getColor = (bcmValidityDays?: number) => {
    if (bcmValidityDays == null) return "";
    if (bcmValidityDays <= 0)
      return "var(--mds_brand_appearance_error_default_text-color)";
    if (bcmValidityDays > 0 && bcmValidityDays <= 60)
      return "var(--mds_brand_appearance_warning_default_text-color)";
    return "var(--mds_brand_appearance_success_default_text-color)";
  };

  const Validity = ({ bcmValidityDays }: { bcmValidityDays: any }) => {
    const color = getColor(bcmValidityDays);
    return (
      <>
        <ValidityDays color={color}>{bcmValidityDays ?? "_"}</ValidityDays>
        <DaysLabel color={color}>
          {bcmValidityDays && (bcmValidityDays >= 2 || bcmValidityDays <= -2)
            ? "days"
            : "day"}
        </DaysLabel>
      </>
    );
  };

  return (
    details && (
      <WidgetsWrapper>
        <SingleWidgetWrapper>
          <div className="headingText">Validity</div>
          <CenteredElement>
            <Validity bcmValidityDays={details.bcmValidityDays} />
          </CenteredElement>
        </SingleWidgetWrapper>
        <SingleWidgetWrapper>
          <div className="headingText">RTO vs Processes</div>
          <Charts data={details.processGraph}/>
        </SingleWidgetWrapper>
        <SingleWidgetWrapper>
          <div className="headingText">RTO vs Dependencies</div>
          <Charts data={details.dependenciesGraph}  />
        </SingleWidgetWrapper>
      </WidgetsWrapper>
    )
  );
}

const WidgetsWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  padding-top: 20px;

  margin-bottom: 10px;
  height: 150px;
`;

const CenteredElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Adjust as needed */
`;

const SingleWidgetWrapper = styled(Box)`
  border: 1px solid var(--mds_brand_appearance_neutral_weak_border-color);
  padding: 10px;
  padding-left: 20px;
  padding-bottom: 20px;
  height: 129px;
  box-shadow: 0px 4px 4px 0px #00000040;

  .headingText {
    color: var(--mds_brand_appearance_neutral_default_text-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`;

const ValidityDays = styled.div<{ color: string }>`
  font-size: 40px;
  line-height: 40px;
  font-weight: 700;
  color: ${(props) => props.color};
`;

const DaysLabel = styled.div<{ color: string }>`
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  color: ${(props) => props.color};
`;
