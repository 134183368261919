export default function SiteMandatoryLabel({
  slot,
  label,
  isMandatory,
}: Readonly<{
  slot: string;
  label: string;
  isMandatory: boolean;
}>): JSX.Element {
  return (
    <span slot={slot}>
      {label}{" "}
      {isMandatory && (
        <span
          style={{
            color: "var(--mds_brand_appearance_error_default_text-color)",
          }}
        >
          *
        </span>
      )}
    </span>
  );
}
