import { Customer } from "../../models/Customer";
import { doGet, doPost } from "../../utils";
import { getApiUrl } from "../../utils/application-utils";

export const getGlobalKeyCustomers = (): Promise<any> =>
  doGet(`${getApiUrl()}/globalkeycustomer`);

export const saveGlobalKeyCustomer = (customer: Customer): Promise<any> =>
  doPost(`${getApiUrl()}/globalkeycustomer`, customer);

export const saveKeyCustomerSitesMapping = (customerSites : any): Promise<any> =>
  doPost(`${getApiUrl()}/globalkeycustomer/sites`, customerSites);
