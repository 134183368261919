import { useContext, useEffect, useReducer, useState } from "react";
import CommonModal, {
  AddNewContentText,
  ExpandableContentWrapper,
} from "../../../components/common/commonModal";
import {
  defaultError,
  errorActions,
  errorReducer,
} from "../../../reducers/error";
import { McInput, McOption, McSelect } from "@maersk-global/mds-react-wrapper";
import { DeleteIcon, StaticIcon } from "../../../components/common/icons";
import { colors } from "../../../styles/colors";
import Tooltip from "../../../components/common/tooltip";
import { createOrUpdateService } from "../../../services/Contacts";
import { toast } from "../../../components/common/toast";
import Info from "../../../components/common/info";
import { SystemConfigurationSetup } from "../../../constants/Info";
import { BrandArchetypeContext } from "../../../contexts/BrandArchetypeContext";

const configurationDropdown = [
  {
    label: "Mandatory",
    value: 1,
  },
  {
    label: "Optional",
    value: 2,
  },
];

const errorMessages = {
  NAME_IS_REQUIRED: "Name is required.",
  CONFIGURATION_IS_REQUIRED: "Configuration is required.",
};

export default function AddEditServiceModal(props: any) {
  const [errors, dispatchErrors] = useReducer(errorReducer, defaultError);
  const [services, setServices] = useState<any[]>([]);

  const { archetypeId } = useContext(BrandArchetypeContext) as {
    brandId: string;
    archetypeId: string;
  };

  useEffect(() => {
    if (props.editableService.length > 0) {
      let services: any[] = [];
      props.editableService.forEach((item: any) => {
        let configuration = configurationDropdown.find((dropdown: any) => {
          return dropdown.label === item.configurationValue;
        });
        services.push({
          id: item.id,
          name: item.name,
          configurationValue: configuration?.value ?? 0,
        });
      });
      setServices(services);
    } else {
      setServices([
        {
          id: 0,
          name: "",
          configurationValue: 0,
        },
      ]);
    }
  }, [props.isOpen]);

  const onModalClose = () => {
    setServices([]);
    dispatchErrors({ type: errorActions.REMOVE_ERROR });

    props.onClose();
  };

  const onInputChange = (e: any, index: number) => {
    let newContacts = [...services];
    newContacts[index].name = e.target.value;

    setServices(newContacts);
  };

  const onDropdownChange = (e: any, index: number) => {
    let newContacts = [...services];
    newContacts[index].configurationValue = e.detail.value;

    setServices(newContacts);
  };

  const saveExternalContact = () => {
    let service: any = {
      services: services,
      serviceType: props.serviceType,
    };

    if (!validate()) return;

    createOrUpdateService(service, archetypeId)
      .then((response) => {
        toast(
          "Success",
          `${props.serviceTypeName} saved successfully`,
          "success"
        );
        props.onSave();
        onModalClose();
      })
      .catch((error) => {
        console.log(error);
        dispatchErrors({
          type: errorActions.ADD_ERROR,
          errorMessage: [error.message],
        });
      });
  };

  const validate = () => {
    let errors: string[] = [];

    services.map((service) => {
      if (
        service.name === "" &&
        !errors.includes(errorMessages.NAME_IS_REQUIRED)
      ) {
        errors.push(errorMessages.NAME_IS_REQUIRED);
      }
      if (
        service.configurationValue === 0 &&
        !errors.includes(errorMessages.CONFIGURATION_IS_REQUIRED)
      ) {
        errors.push(errorMessages.CONFIGURATION_IS_REQUIRED);
      }
    });

    if (errors.length > 0) {
      dispatchErrors({
        type: errorActions.ADD_ERROR,
        errorMessage: errors,
      });
      return false;
    }

    return true;
  };

  const addNewContact = () => {
    let newServices = [...services];
    newServices.push({
      id: 0,
      name: "",
      configurationValue: 0,
    });

    setServices([...newServices]);
  };

  const removeExternalContact = (index: number) => {
    let newContacts = [...services];
    newContacts.splice(index, 1);

    setServices(newContacts);
  };

  return (
    <CommonModal
      primaryActionLabel="Confirm"
      primaryAction={() => saveExternalContact()}
      secondaryActionLabel="Cancel"
      onSeceondaryAction={() => onModalClose()}
      isOpen={props.isOpen}
      onModalClose={() => onModalClose()}
      heading={`Add/Edit ${props.serviceTypeName}`}
      errors={errors}
      dimension="medium"
      width={700}
    >
      {services.map((service, index) => {
        return (
          <ExpandableContentWrapper key={service.id}>
            {service.id === 0 && index !== 0 && (
              <div
                style={{
                  display: "flex",
                  gridTemplateColumns: "1fr",
                  justifyContent: "flex-end",
                }}
              >
                <Tooltip content="Remove Contact" position="left-top">
                  <DeleteIcon
                    onClick={() => removeExternalContact(index)}
                    slot="trigger"
                  />
                </Tooltip>
              </div>
            )}
            <McInput
              key={service.id}
              label="Service"
              value={service.name}
              input={(e: any) => onInputChange(e, index)}
            ><Info popoverContent={SystemConfigurationSetup.AddEditServiceModal_Service} tooltipText="Service" /></McInput>
            <McSelect
              label="Configuration"
              value={service.configurationValue}
              optionselected={(e: CustomEvent) => onDropdownChange(e, index)}
            ><Info popoverContent={SystemConfigurationSetup.AddEditServiceModal_Configuration} tooltipText="Configuration" />
              {configurationDropdown.map((item: any) => {
                return (
                  <McOption key={item.value} value={item.value}>
                    {item.label}
                  </McOption>
                );
              })}
            </McSelect>
          </ExpandableContentWrapper>
        );
      })}
      <AddNewContentText onClick={() => addNewContact()}>
        <StaticIcon
          icon="plus"
          color={`${colors["mds_core_button_primary_filled_background-color"]}`}
        />
        <span>Add new</span>
      </AddNewContentText>
    </CommonModal>
  );
}
