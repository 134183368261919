import { useEffect, useReducer, useState } from "react";
import CommonModal from "../../../components/common/commonModal";
import {
  McCheckbox,
  McCheckboxGroup,
  McInput,
  McOption,
  McRadio,
  McRadioGroup,
  McSelect,
  McTypeahead,
} from "@maersk-global/mds-react-wrapper";
import {
  defaultError,
  errorActions,
  errorReducer,
} from "../../../reducers/error";
import styled from "styled-components";
import {
  defaultUserState,
  userActions,
  userKind,
  userReducer,
  userTypes,
  userTypesOptions,
} from "../reducers/user";
import {
  createOrUpdateUser,
  getRoles,
  searchUsers,
} from "../../../services/UserManagement";
import { toast } from "../../../components/common/toast";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../../utils/validation-utils";
import Info from "../../../components/common/info";
import { UserInfo } from "../../../constants/Info";

export default function AddEditUserModal(
  props: Readonly<{
    isOpen: boolean;
    editableUser: any;
    onClose: () => void;
    onSave: () => void;
  }>
) {
  const [errors, dispatchErrors] = useReducer(errorReducer, defaultError);
  const [state, dispatch] = useReducer(userReducer, defaultUserState);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatchErrors({ type: errorActions.REMOVE_ERROR });
    getRoles()
      .then((data) => {
        dispatch({ type: userActions.SET_ROLES, value: data });
      })
      .catch((error) => {
        toast("Error", "Error while fetching roles", "error");
      });
    if (props.editableUser) {
      setSearchTerm(props.editableUser.displayName);
      dispatch({
        type: userActions.SET_EDITABLE_USER,
        value: props.editableUser,
      });
    }
  }, [props.isOpen]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (searchTerm) {
        searchUsers(searchTerm)
          .then((data) => {
            dispatch({ type: userActions.MAP_SEARCH_RESULTS, value: data });
          })
          .catch((error) => {
            toast("Error", "Error while searching users", "error");
          });
      }
    }, 100);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);
  const onSearchUser = (e: CustomEvent) => {
    let term = e.detail;

    if (!term || term.length < 3) return;

    setSearchTerm(term);
  };

  const onOptionsSelected = (e: any) => {
    dispatch({ type: userActions.ON_USER_SELECTED, value: e.value });
    setSearchTerm(e.label);
  };

  const onModalClose = () => {
    setSearchTerm("");
    dispatch({ type: userActions.RESET_STATE });
    props.onClose();
  };

  const saveUser = () => {
    let selectedRoleIds = state.selectedRole
      ? [
          state.roles.find(
            (item: any) => item.groupId === state.selectedRole?.value
          ),
        ]
      : [];
    let user = {
      id: state.id,
      objectID: state.objectId,
      displayName: state.name?.trim(),
      jobTitle: state.jobTitle?.trim(),
      email: state.email?.trim(),
      mobilePhone: state.phone?.trim(),
      businessPhones:
        state.businessPhones !== ""
          ? state.businessPhones?.trim().split(",")
          : [],
      isActive: state.isActive,
      isPrimary: state.selectedUserKind.includes(userKind.primary),
      isSecondary: state.selectedUserKind.includes(userKind.secondary),
      isShownInSites:
        state.selectedUserType === userTypes.office
          ? state.isShownToAssignedSites
          : false,
      isIncidentResponseTeam:
        state.selectedUserType === userTypes.office
          ? state.isIncidentResponseTeam
          : false,
      roleIDs: selectedRoleIds
        ?.filter((role: any) => role?.id)
        .map((role: any) => role.id),
    };
    if (!isValid(user)) return;

    createOrUpdateUser(user)
      .then((res) => {
        toast(
          "Success",
          `User
      ${user.id === 0 ? "added" : "updated"} successfully`,
          "success"
        );
        props.onSave();
        onModalClose();
      })
      .catch((error) => {
        dispatchErrors({
          type: errorActions.ADD_ERROR,
          errorMessage: [error.message],
        });
      });
  };

  const isValid = (user: any) => {
    const errorMessages: string[] = [];
    let { displayName, email, mobilePhone, roleIDs, isPrimary, isSecondary } =
      user;

    let selectedUser = state.selectedUser;

    if (displayName === "") errorMessages.push("Name is required");
    if (selectedUser && selectedUser.displayName !== searchTerm)
      errorMessages.push("Please select a valid user.");
    if (email === "") errorMessages.push("Email is required.");
    if (email !== "" && !validateEmail(email))
      errorMessages.push("Email is not valid.");
    if (mobilePhone && !validatePhoneNumber(mobilePhone))
      errorMessages.push("Phone number is not valid.");
    if (roleIDs.length === 0 && user.isActive)
      errorMessages.push("Role is required.");
    if (isPrimary && isSecondary)
      errorMessages.push(
        "Either user can be of primary or secondary. Both cannot be selected together."
      );
    if (errorMessages.length > 0) {
      dispatchErrors({
        type: errorActions.ADD_ERROR,
        errorMessage: errorMessages,
      });
      return false;
    }
    return true;
  };

  return (
    <CommonModal
      primaryActionLabel="Confirm"
      primaryAction={() => saveUser()}
      secondaryActionLabel="Cancel"
      onSeceondaryAction={() => onModalClose()}
      isOpen={props.isOpen}
      onModalClose={() => onModalClose()}
      heading={`Add/Edit user`}
      dimension="medium"
      width={674}
      errors={errors}
    >
      <McRadioGroup
        name="userType"
        legend="Type of User"
        orientation="horizontal"
      >
        {userTypesOptions.map((option: any) => (
          <McRadio
            key={option.label}
            name="typeOfUser"
            value={option.value}
            label={option.label}
            checked={state.selectedUserType === option.value}
            onClick={() => {
              dispatch({
                type: userActions.USER_TYPE_CHANGE,
                value: option.value,
              });
            }}
          />
        ))}
      </McRadioGroup>
      <McTypeahead
        name="selectUser"
        label="Name"
        clearbutton={false}
        placeholder="Search user by name or email (Min 3 characters) from azure group."
        minlength={3}
        search={onSearchUser}
        optionselected={(event: CustomEvent) => onOptionsSelected(event.detail)}
        data={state.searchUserDropdownData}
        value={searchTerm}
        disabled={state.id !== 0}
      >
        <Info
          popoverContent={UserInfo.AddEditUserModal_Name}
          tooltipText="Name"
        />
      </McTypeahead>
      <McInput
        name="jobTitle"
        label="Job Title"
        input={(e: any) => {
          dispatch({ type: userActions.ON_JOB_TITLE_CHANGE, value: e.target.value });
        }}
        value={state.jobTitle}

      ></McInput>
      <McInput name="email" label="Email" value={state.email} disabled />
      <McInput
        name="phone"
        label="Phone"
        value={state.phone}
        input={(e: any) => {
          dispatch({
            type: userActions.ON_PHONE_CHANGE,
            value: e.target.value,
          });
        }}
      >
        <Info
          popoverContent={UserInfo.AddEditUserModal_Phone}
          tooltipText="Phone"
        />
      </McInput>
      <McInput
        name="businessPhone"
        label="Business Phone"
        value={state.businessPhones}
        input={(e: any) => {
          dispatch({
            type: userActions.ON_BUSINESS_PHONE_CHANGE,
            value: e.target.value,
          });
        }}
        disabled
      />
      <CheckboxWrapper>
        <McCheckbox
          name="isActive"
          label="Is Active"
          change={(e: CustomEvent) => {
            dispatch({
              type: userActions.SET_IS_ACTIVE,
              value: e.detail,
            });
          }}
          checked={state.isActive}
          disabled={state.id === 0}
        >
          <Info
            popoverContent={UserInfo.AddEditUserModal_IsActive}
            tooltipText="Is Active"
          />
        </McCheckbox>
      </CheckboxWrapper>
      <McSelect
        label="Role (The user's new role will become active after 30 minutes.)"
        optionswidth="trigger"
        optionselected={(e: CustomEvent) => {
          dispatch({
            type: userActions.ON_ROLE_SELECTED,
            value: e.detail,
          });
        }}
        value={state.selectedRole !== null ? state.selectedRole?.value : null}
        name="role"
      >
        <Info
          popoverContent={UserInfo.AddEditUserModal_Role}
          tooltipText="Role"
        />
        {state.rolesDropdown.map((role: any) => (
          <McOption key={role.value} value={role.value}>
            {role.label}
          </McOption>
        ))}
      </McSelect>
      <CheckboxWrapper>
        <McCheckboxGroup
          name="kindOfUser"
          legend=""
          change={(e: CustomEvent) => {
            dispatch({
              type: userActions.USER_KIND_CHANGE,
              value: e.detail,
            });
          }}
          orientation="horizontal"
        >
          <McCheckbox
            name="kindOfUser"
            value={userKind.primary}
            label="Primary"
            checked={state.selectedUserKind.includes(userKind.primary)}
          ></McCheckbox>
          <McCheckbox
            name="kindOfUser"
            value={userKind.secondary}
            label="Secondary"
            checked={state.selectedUserKind.includes(userKind.secondary)}
          ></McCheckbox>
        </McCheckboxGroup>
      </CheckboxWrapper>
      {state.selectedUserType === userTypes.office && (
        <CheckboxWrapper>
          <McCheckbox
            name="incidentResponseTeam"
            label="Incident Response Team"
            change={(e: CustomEvent) => {
              dispatch({
                type: userActions.INCIDENT_RESPONSE_TEAM_SELECTED,
                value: e.detail,
              });
            }}
            checked={state.isIncidentResponseTeam}
          >
            <Info
              popoverContent={UserInfo.AddEditUserModal_IncidentResponseTeam}
              tooltipText="Incident Response Team"
            />
          </McCheckbox>
        </CheckboxWrapper>
      )}
      {state.selectedUserType === userTypes.office && (
        <CheckboxWrapper>
          <McCheckbox
            name="showOnAssignedSites"
            label="Shown to assigned sites"
            change={(e: CustomEvent) => {
              dispatch({
                type: userActions.IS_SHOWN_TO_ASSIGNED_SITES_SELECTED,
                value: e.detail,
              });
            }}
            checked={state.isShownToAssignedSites}
          />
        </CheckboxWrapper>
      )}
    </CommonModal>
  );
}

const CheckboxWrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;
