import { SiteModes } from "../../constants/SiteBCM";

const SiteElementsRenderer = ({ mode, children }: any) => {
  if (mode === SiteModes.Read) {
    return null;
  }

  return <>{children}</>;
};

export default SiteElementsRenderer;
