import { doGet, doPost, doPut } from "../../utils";
import { getApiUrl } from "../../utils/application-utils";

export const getAlternativeLocation = (
  siteId: number,
  version: number
): Promise<any> =>
  doGet(`${getApiUrl()}/alternativelocation/${siteId}/${version}`);

export const saveAlternativeLocation = (
  alternativeLocation: any
): Promise<any> => {
  let apiUrl = getApiUrl();
  return alternativeLocation.id === 0
    ? doPost(`${apiUrl}/alternativelocation/create`, alternativeLocation)
    : doPut(`${apiUrl}/alternativelocation/update`, alternativeLocation);
};

export const getAlternativeLocationById = (id: number): Promise<any> =>
  doGet(`${getApiUrl()}/alternativelocation/getbyid/${id}`);

export const getAlternativeLocationSites = (
  archetypeId: number,
  siteId: number
): Promise<any> =>
  doGet(`${getApiUrl()}/alternativelocation/sites/${archetypeId}/${siteId}`);
