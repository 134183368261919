import { doGet, doPost, doPut } from "../../utils";
import { getApiUrl } from "../../utils/application-utils";

export const getEmergencyContact = (
  siteId: number,
  version: number
): Promise<any> =>
  doGet(`${getApiUrl()}/emergencycontacts/${siteId}/${version}`);

export const saveEmergencyContact = (internalContact: any): Promise<any> => {
  let apiUrl = getApiUrl();
  return internalContact.id === 0
    ? doPost(`${apiUrl}/emergencycontacts/create`, internalContact)
    : doPut(`${apiUrl}/emergencycontacts/update`, internalContact);
};
