import React from "react";

export const CardKeyValueDisplay = ({
  label,
  value,
}: {
  label?: string;
  value: string | React.ReactNode;
}) => {
  return (
    <div style={{ padding: "3px" }} data-label={label || undefined}>
      {label ? `${label}: ${value}` : value}
    </div>
  );
};
