export const option = {
  Yes: 1,
  No: 2,
};

export const selectionOptions = [
  {
    label: "Yes",
    value: 1,
  },
  {
    label: "No",
    value: 2,
  },
];

export const yesOrNoDropdownWithBooleanOptions = [
  {
    label: "Yes",
    value: "true",
  },
  {
    label: "No",
    value: "false",
  },
];

export const timeIntervalOptions = [
  {
    label: "Hours",
    value: "1",
  },
  {
    label: "Days",
    value: "2",
  },
  {
    label: "Weeks",
    value: "3",
  },
];

export const timeIntervalOption = {
  Hours: "1",
  Days: "2",
  Weeks: "3",
};
