import { ItemType, TabItemProps, Tabs } from "@maersk-global/community-react";
import { useEffect, useState } from "react";
import CommonModal from "../../../../components/common/commonModal";
import CurrentRecomendations from "../CurrentRecomendation";
import LastRecommendations from "../LastRecommendation";

export const tabItemCodes = {
  archetype: "archetype",
  siteversion: "site-version",
};

export default function ProcessRecommendationsModal(props: any) {
  let items: ItemType[] = [
    ...(props.isConfiguredArchetypeLevel
      ? [
          {
            id: tabItemCodes.archetype,
            active: true,
            title: "Master Data",
          },
        ]
      : []),
    {
      id: tabItemCodes.siteversion,
      active: false,
      title: "Previous Version",
    },
  ];

  useEffect(() => {
    if (props.isOpen) {
      setTabItems(items);
      setActiveTabCode(items[0].id);
    }
  }, [props.isOpen, props.isConfiguredArchetypeLevel]);

  const [tabItems, setTabItems] = useState<ItemType[]>(items);
  const [activeTabCode, setActiveTabCode] = useState<string>(items[0].id);

  const setActiveTab = (e: TabItemProps) => {
    setTabItems(e.updatedTabData);
    setActiveTabCode(e.selectedItemId);
  };

  const onModalClose = () => {
    props.onClose();
  };

  return (
    <CommonModal
      primaryActionLabel="Close"
      primaryAction={onModalClose}
      isOpen={props.isOpen}
      onModalClose={onModalClose}
      heading={"Recommendations"}
      dimension="large"
      shouldShowSecondaryAction={false}
    >
      <Tabs items={tabItems} onClick={setActiveTab} fit="small" />
      {activeTabCode === tabItemCodes.archetype && props.isOpen && (
        <CurrentRecomendations
          isOpen={props.isOpen}
          archetypeId={props.archetypeId}
          selectedProcessName={props.selectedProcessName}
          isProcess={props.isProcess}
          impacts={props.impacts}
          tableColumns={props.tableColumns}
        />
      )}
      {activeTabCode === tabItemCodes.siteversion && props.isOpen && (
        <LastRecommendations
          isOpen={props.isOpen}
          siteId={props.siteId}
          archetypeId={props.archeTypeId}
          selectedProcessName={props.selectedProcessName}
          isProcess={props.isProcess}
          impacts={props.impacts}
          tableColumns={props.tableColumns}
        />
      )}
    </CommonModal>
  );
}
