import { useEffect, useReducer, useState } from "react";
import { loadingIndicator } from "../../../components/common/loading";
import { getLastVersionProcesses } from "../../../services/Processes";
import { ProcessImpactMappings } from "../../../models/Processes";
import { toast } from "../../../components/common/toast";
import EditableProcessTable from "../../Process/editableProcessTable";
import {
  defaultProcesses,
  processesActions,
  processImpactsReducer,
} from "../../Process/reducers/processes";
import Notifications from "../../../components/common/notifications";

export default function LastRecommendation(props: any) {
  const [loading, setLoading] = useState<boolean>(true);
  const [processes, setProcesses] = useState<ProcessImpactMappings[]>();
  const [state, dispatch] = useReducer(processImpactsReducer, defaultProcesses);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.isOpen)
      getLastVersionProcesses(props.siteId)
        .then((res) => {
          if (!props.isProcess) {
            let selectedProcess = res.processImpactMappings.find(
              (item: ProcessImpactMappings) =>
                item.name === props.selectedProcessName
            );
            if (selectedProcess) {
              setProcesses(selectedProcess.subProcessImpactMappings);
            }
          } else {
            setProcesses(res.processImpactMappings);
          }
          dispatch({
            type: processesActions.SET_PROCESSES,
            value: res,
          });
          setLoading(false);
        })
        .catch((error) => {
          if (error.statusCode === 400) {
            setError(error.statusCode);
            setLoading(false);
          } else {
            toast("error", error.message, "error");
          }
        });
  }, [props.isOpen, props.selectedProcessName]);

  return (
    <>
      {loading ? (
        loadingIndicator
      ) : error === 400 ? (
        <Notifications
          description={["No Previous Version Found"]}
          variant={"warning"}
        />
      ) : !processes || processes.length === 0 ? (
        <Notifications
          description={["This Process not Found in  Previous Version"]}
          variant={"warning"}
        />
      ) : (
        <EditableProcessTable
          processes={processes}
          impacts={state.impacts}
          tableColumns={state.tableColumns}
          isProcessEditEnabled={false}
          archetypeId={props.archeTypeId}
          isProcess={false}
          isReadOnly={true}
        />
      )}
    </>
  );
}
