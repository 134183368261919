import { Suspense } from "react";
import { loadingIndicator } from "../components/common/loading";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Site/CustomizableDashboard/Dashboard";
import SiteGuardCustomised from "../pages/Site/SiteGuardCustomised";
import Settings from "../pages/Settings/Settings";

export default function SiteAdminRoutes() {
  return (
    <Suspense fallback={loadingIndicator}>
      <Routes>
        <Route path="/" element={<Dashboard />}></Route>
        <Route path="/site-overview" element={<Dashboard />}></Route>
        <Route
          path="/business-continuity-management/:id"
          element={<SiteGuardCustomised />}
        />
        <Route path="/settings" element={<Settings />} />
      </Routes>
    </Suspense>
  );
}
