export enum continuityActionTypes {
  SET_EDITABLE = "SET_EDITABLE",
  SET_LOADING = "SET_LOADING",
  SET_OPERATIONAL_IMPACT = "SET_OPERATIONAL_IMPACT",
  SET_IMMEDIATE_ACTION = "SET_IMMEDIATE_ACTION",
  SET_CONTINUITY_PLAN_DESCRIPTION = "SET_CONTINUITY_PLAN_DESCRIPTION",
  SET_RECOVERY_DEPENDENCY = "SET_RECOVERY_DEPENDENCY",
  SET_EDITED_OPERATIONAL_IMPACT = "SET_EDITED_OPERATIONAL_IMPACT",
  SET_EDITED_IMMEDIATE_ACTION = "SET_EDITED_IMMEDIATE_ACTION",
  SET_EDITED_CONTINUITY_PLAN_DESCRIPTION = "SET_EDITED_CONTINUITY_PLAN_DESCRIPTION",
  SET_EDITED_RECOVERY_DEPENDENCY = "SET_EDITED_RECOVERY_DEPENDENCY",
  SET_FETCHED = "SET_FETCHED",
  SET_ISMATERDATA = "SET_ISMATERDATA",
  SET_RECOVERY_DEPENDENCY_KEY = "SET_RECOVERY_DEPENDENCY_KEY",
  SET_CONTINUITY_PLAN_DESCRIPTION_KEY = "SET_CONTINUITY_PLAN_DESCRIPTION_KEY",
  SET_IMMEDIATE_ACTION_KEY = "SET_IMMEDIATE_ACTION_KEY",
  SET_OPERATIONAL_IMPACT_KEY = "SET_OPERATIONAL_IMPACT_KEY",
  SET_RECOVERY_RTO = "SET_RECOVERY_RTO",
}

export const initialState = {
  editable: false,
  loading: false,
  operationalImpact: "",
  operationalImpactkey: "",
  immediateAction: "",
  immediateActionkey: "",
  continuityPlanDescription: "",
  continuityPlanDescriptionkey: "",
  recoveryDependency: "",
  recoveryDependencykey: "",
  editedOperationalImpact: "",
  editedImmediateAction: "",
  editedContinuityPlanDescription: "",
  editedRecoveryDependency: "",
  recoveryRTO: "",
  fetched: false,
  isMasterdata: true,
};

export const reducer = (state: any, action: any) => {
  switch (action.type) {
    case continuityActionTypes.SET_EDITABLE:
      return { ...state, editable: action.payload };
    case continuityActionTypes.SET_LOADING:
      return { ...state, loading: action.payload };
    case continuityActionTypes.SET_OPERATIONAL_IMPACT:
      return { ...state, operationalImpact: action.payload };
    case continuityActionTypes.SET_IMMEDIATE_ACTION:
      return { ...state, immediateAction: action.payload };
    case continuityActionTypes.SET_CONTINUITY_PLAN_DESCRIPTION:
      return { ...state, continuityPlanDescription: action.payload };
    case continuityActionTypes.SET_RECOVERY_DEPENDENCY:
      return { ...state, recoveryDependency: action.payload };
    case continuityActionTypes.SET_EDITED_OPERATIONAL_IMPACT:
      return { ...state, editedOperationalImpact: action.payload };
    case continuityActionTypes.SET_EDITED_IMMEDIATE_ACTION:
      return { ...state, editedImmediateAction: action.payload };
    case continuityActionTypes.SET_EDITED_CONTINUITY_PLAN_DESCRIPTION:
      return { ...state, editedContinuityPlanDescription: action.payload };
    case continuityActionTypes.SET_EDITED_RECOVERY_DEPENDENCY:
      return { ...state, editedRecoveryDependency: action.payload };
    case continuityActionTypes.SET_FETCHED:
      return { ...state, fetched: action.payload };
    case continuityActionTypes.SET_ISMATERDATA:
      return { ...state, isMasterdata: action.payload };
    case continuityActionTypes.SET_RECOVERY_DEPENDENCY_KEY:
      return { ...state, recoveryDependencykey: action.payload };
    case continuityActionTypes.SET_CONTINUITY_PLAN_DESCRIPTION_KEY:
      return { ...state, continuityPlanDescriptionkey: action.payload };
    case continuityActionTypes.SET_IMMEDIATE_ACTION_KEY:
      return { ...state, immediateActionkey: action.payload };
    case continuityActionTypes.SET_OPERATIONAL_IMPACT_KEY:
      return { ...state, operationalImpactkey: action.payload };
    case continuityActionTypes.SET_RECOVERY_RTO:
      return { ...state, recoveryRTO: action.payload };
    default:
      return state;
  }
};
