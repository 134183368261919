import { doGet, doPost, doPut } from "../../utils";
import { getApiUrl } from "../../utils/application-utils";

export const getSiteProcesses = (
  siteId: number,
  versionId: number
): Promise<any> =>
  doGet(
    `${getApiUrl()}/siteprocess/GetProcess?siteId=${siteId}&versionId=${versionId}`
  );

export const createOrUpdateSiteProcess = (process: any): Promise<any> => {
  return process.processes[0].id === 0
    ? doPost(`${getApiUrl()}/siteprocess/create`, process)
    : doPut(`${getApiUrl()}/siteprocess/update`, process);
};

export const configureSiteProcessImpact = (
  processImpact: any
): Promise<any> => {
  return doPost(`${getApiUrl()}/siteprocess/impact/configure`, processImpact);
};

export const submitSiteProcesses = (siteProcess: any): Promise<any> => {
  return doPost(`${getApiUrl()}/siteprocess/Submit`, siteProcess);
};

export const configureProcessSelection = (
  selectedProcess: any
): Promise<any> => {
  return doPut(
    `${getApiUrl()}/siteprocess/configureprocessselection`,
    selectedProcess
  );
};
