import { useEffect, useReducer, useState } from "react";
import {
  defaultProcessDependencies,
  processDependenciesReducer,
} from "./reducers/processDepencencies";
import { processDependenciesActions } from "../ProcessDependencies/reducers/processDepencencies";
import ProcessDependency from "./ProcessDependency";
import Accordian from "../../components/common/accordian";
import { FlexGrowBox, StyledTag } from "../../components/common/common.styles";
import styled from "styled-components";
import NoDataAvailable from "../../components/common/noDataAvailable";
import {
  McList,
  McListItem,
  McMenu,
  McNotification,
} from "@maersk-global/mds-react-wrapper/components-core";
import Box from "../../components/common/box";
import ActionBox from "../../components/common/actionBox";
import { UserTypes } from "../../constants/Users";
import SiteElementsRenderer from "../../components/common/siteElementsRenderer";
import { StaticIcon } from "../../components/common/icons";

export default function ProcessDependencies(props: any) {
  const [processDependenciesState, dispatch] = useReducer(
    processDependenciesReducer,
    defaultProcessDependencies
  );

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (props.processes) {
      dispatch({
        type: processDependenciesActions.SET_PROCESSES,
        value: props.processes,
      });
    }

    if (props.dependencies) {
      dispatch({
        type: processDependenciesActions.SET_DEPENDENCIES,
        value: props.dependencies,
      });
    }

    if (props.dependencyMapping) {
      dispatch({
        type: processDependenciesActions.SET_DEPENDENCY_CATEGORY_TAGS_INITIAL,
        value: props.dependencyMapping,
      });
    }
    dispatch({
      type: processDependenciesActions.SET_LOADING,
      value: false,
    });
  }, [props.processes, props.dependencies, props.dependencyMappings]);

  const selectedDependencies =
    processDependenciesState.dependencyCategoryTags.map(
      (dependencyCategory: any) => {
        let { dependencyCategoryId, dependencyCategoryName, tags } =
          dependencyCategory;
        return (
          <Box
            display="grid"
            gridTemplateColumns="1fr"
            key={dependencyCategoryId}
          >
            <SelectedDependenciesRowWrapper
              display="grid"
              gridTemplateColumns="0.5fr 1fr"
            >
              <SelectedDependenciesRow>
                <b>{dependencyCategoryName}</b>
              </SelectedDependenciesRow>
              <Box>
                <SelectedDependenciesRow>
                  <SelectedDependenciesTagsContainer>
                    {tags.map(
                      (tag: any) =>
                        tag.processes.length > 0 && (
                          <McMenu
                            position="bottom-center"
                            fit="medium"
                            key={tag.id}
                            arrow={true}
                            maxheight="500px"
                            maxwidth="500px"
                            trigger="hover focus"
                          >
                            <TagWrappper key={tag.id} slot="trigger">
                              <StyledTag
                                bgColor="var(--mds_brand_appearance_neutral_weakest_background-color)"
                                style={{
                                  textWrap: "wrap",
                                  cursor: "pointer",
                                }}
                              >
                                {`${tag.name} (${tag.processes.length})`}{" "}
                                {props.userType === UserTypes.site && (
                                  <span
                                    style={{ paddingLeft: "12px" }}
                                    onClick={() => {
                                      dispatch({
                                        type: processDependenciesActions.REMOVE_DEPENDENCY_TAG,
                                        dependencyCategoryId:
                                          dependencyCategoryId,
                                        dependencyId: tag.id,
                                      });
                                    }}
                                  >
                                    X
                                  </span>
                                )}
                              </StyledTag>
                            </TagWrappper>
                            <McList>
                              {tag.processes.map((process: any) => {
                                return (
                                  <McListItem
                                    key={process.id}
                                    label={process.name}
                                  />
                                );
                              })}
                            </McList>
                          </McMenu>
                        )
                    )}
                  </SelectedDependenciesTagsContainer>
                </SelectedDependenciesRow>
              </Box>
            </SelectedDependenciesRowWrapper>
          </Box>
        );
      }
    );

  const onSingleDependencySelected = (
    dependencyCategoryId: number,
    selectedDependencies: any,
    process: any,
    processName: string
  ) => {
    dispatch({
      type: processDependenciesActions.SET_DEPENDENCY_CATEGORY_TAGS,
      dependencyCategoryId: dependencyCategoryId,
      selectedDependencies: selectedDependencies,
      process: process,
      processName: processName,
    });
  };

  const onSetProcessDependenciesUpdated = (processDependencies: any) => {
    dispatch({
      type: processDependenciesActions.SET_DEPENDENCY_UPDATED,
      processId: processDependencies.processId,
      processDependencies: processDependencies.processDependencies,
    });
  };

  const onSaveClick = () => {
    let mappedProcessDependencies = {
      mappings: processDependenciesState.processDependencies.map(
        (processDependency: any) => {
          return {
            processId: processDependency.processId,
            selectedDependencies: processDependency.dependencies.map(
              (dependency: any) => {
                return {
                  dependencyCategoryId: dependency.dependencyCategoryId,
                  selectectedDependencyIds: dependency.selectedDependencies.map(
                    (selectedDependency: any) => {
                      return selectedDependency.id;
                    }
                  ),
                };
              }
            ),
          };
        }
      ),
      doesSubProcessExist: processDependenciesState.isSubprocess,
    };
    props.onSave(mappedProcessDependencies);
  };

  const onDependencyAdded = (dependencyDropdowns: any) => {
    let mappedDependencies = dependencyDropdowns.map((dependency: any) => {
      return {
        id: dependency.dependencyCategoryId,
        name: dependency.dependencyCategoryName,
        dependencies: dependency.dependencyDropdown.map((item: any) => {
          return {
            id: item.id,
            name: item.label,
            isSiteLevelDependency: item.isSiteLevelDependency,
          };
        }),
      };
    });

    dispatch({
      type: processDependenciesActions.SET_DEPENDENCIES,
      value: { dependencyCategories: mappedDependencies },
    });
  };

  const CheckIfProcessNotMappedToDependency = (processId: any): boolean => {
    let ismapped = false;
    if (processDependenciesState.isSubprocess) {
      var process = processDependenciesState.processes.find(
        (p: any) => p.id === processId
      );
      for (let subProcess of process?.subProcessImpactMappings) {
        const dependencyMappings =
          processDependenciesState.mappedProcessDependenciesCopy.find(
            (process: any) => process.processId === subProcess.id
          );
        var isdepMapped = dependencyMappings?.selectedDependencies.every(
          (dependency: any) => dependency.selectectedDependencyIds.length === 0
        );
        if (isdepMapped) {
          return true;
        }
      }
    } else {
      const dependencyMappings =
        processDependenciesState.mappedProcessDependenciesCopy.find(
          (process: any) => process.processId === processId
        );
      ismapped = dependencyMappings?.selectedDependencies.every(
        (dependency: any) => dependency.selectectedDependencyIds.length === 0
      );
    }
    return ismapped;
  };

  return (
    <Box display="grid" gridTemplateColumns="0.6fr 1fr">
      <ProcessWrapper windowHeight={windowHeight}>
        {processDependenciesState.processes.length === 0 ? (
          <NoDataAvailable text="No processes added. Add new processes and dependencies for mapping." />
        ) : (
          <div className="processes-wrapper">
            {processDependenciesState.processes.map((process: any) => (
              <Accordian
                key={process.id}
                headerText={process.name}
                displayWarning={CheckIfProcessNotMappedToDependency(process.id)}
              >
                {processDependenciesState.isSubprocess ? (
                  process.subProcessImpactMappings.map((subProcess: any) => {
                    return (
                      <ProcessDependency
                        process={subProcess}
                        key={subProcess.id}
                        dependencies={processDependenciesState.dependencies}
                        onSingleDependencySelected={onSingleDependencySelected}
                        onSetProcessDependenciesUpdated={
                          onSetProcessDependenciesUpdated
                        }
                        mappedProcessDependencies={
                          processDependenciesState.mappedProcessesDependencies
                        }
                        mappedProcessDependenciesCopy={
                          processDependenciesState.mappedProcessDependenciesCopy
                        }
                        processName={process.name}
                        userType={props.userType}
                        onDependencyAdded={onDependencyAdded}
                      />
                    );
                  })
                ) : (
                  <ProcessDependency
                    process={process}
                    key={process.id}
                    dependencies={processDependenciesState.dependencies}
                    onSingleDependencySelected={onSingleDependencySelected}
                    onSetProcessDependenciesUpdated={
                      onSetProcessDependenciesUpdated
                    }
                    mappedProcessDependencies={
                      processDependenciesState.mappedProcessesDependencies
                    }
                    mappedProcessDependenciesCopy={
                      processDependenciesState.mappedProcessDependenciesCopy
                    }
                    processName={""}
                    userType={props.userType}
                    onDependencyAdded={onDependencyAdded}
                  />
                )}
              </Accordian>
            ))}
          </div>
        )}
      </ProcessWrapper>
      <Box>
        <SelectedDependenciesWrapper windowHeight={windowHeight}>
          <Box display="grid" gridTemplateColumns="1fr">
            <div className="title-wrapper">
              <span className="title"> Identified dependencies </span>- Here you
              see the items selected per processes.
            </div>
          </Box>
          <div className="selected-dependencies">{selectedDependencies}</div>
          <Box display="flex">
            <FlexGrowBox />
            <Box display="flex" gridGap={10} p={10}>
              {props.userType === UserTypes.site && (
                <SiteElementsRenderer mode={props.mode}>
                  <ActionBox
                    shouldShowCancelButton={true}
                    onSave={onSaveClick}
                    onCancel={props.onCancel}
                  />
                </SiteElementsRenderer>
              )}
              {props.userType === UserTypes.office && (
                <ActionBox
                  shouldShowCancelButton={true}
                  onSave={onSaveClick}
                  onCancel={props.onCancel}
                />
              )}
            </Box>
          </Box>
        </SelectedDependenciesWrapper>
      </Box>
    </Box>
  );
}

export const ProcessWrapper = styled(Box)<{ windowHeight: number }>`
  padding: 5px 10px 10px 10px;

  .processes-wrapper {
    overflow: auto;
    padding: 5px;
    max-height: ${(props) => props.windowHeight - 303}px;
  }
`;

export const Wrapper = styled(Box)`
  padding: 10px;
`;

const TagWrappper = styled.div`
  padding: 3px;

  word-wrap: wrap;
`;

const SelectedDependenciesTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SelectedDependenciesRow = styled(Box)`
  padding: 15px;
  min-height: 30px;
`;

const SelectedDependenciesRowWrapper = styled(Box)`
  border-bottom: 1px solid #dbdbdb;
`;

const SelectedDependenciesWrapper = styled(Box)<{ windowHeight: number }>`
  margin-top: 10px;
  border: 2px solid var(--mds_brand_appearance_secondary_default_border-color);

  .title-wrapper {
    padding: 20px;
    min-height: 30px;
    border-bottom: 1px solid
      var(--mds_brand_appearance_secondary_default_border-color);

    .title {
      font-weight: bold;
    }
  }

  .selected-dependencies {
    overflow: auto;
    max-height: ${(props) => props.windowHeight - 450}px;
    padding: 10px;
  }
`;
