import {
  Suspense,
  lazy,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import Heading from "../../components/common/heading";
import { toast } from "../../components/common/toast";
import { getBrandById } from "../../services/Brands";
import { Brand } from "../../models/Brand";
import { loadingIndicator } from "../../components/common/loading";
import { StaticIcon } from "../../components/common/icons";
import { TabItemTypes, tabItemCodes } from "../../constants/TabItems";
import {
  ItemType,
  TabItemProps,
  Tabs,
} from "@maersk-global/community-react-tabs";
import styled from "styled-components";
import Box from "../../components/common/box";
import Info from "../../components/common/info";
import { SystemConfiguration } from "../../constants/Info";
import { BrandArchetypeContext } from "../../contexts/BrandArchetypeContext";

const Impacts = lazy(() => import("../Impact/Impacts"));
const Sites = lazy(() => import("../Sites/Sites"));
const Dependencies = lazy(() => import("../Dependencies/Dependencies"));
const ProcessesWrapper = lazy(() => import("../Process/ProcessesWrapper"));
const ProcessDependencies = lazy(
  () => import("../ProcessDependencies/ProcessDependenciesWrapper")
);
const Contact = lazy(() => import("../Contacts/Contact"));
const ContinuityPlan = lazy(() => import("../ContinuityPlan/ContinuityPlan"));
const SiteConfigurations = lazy(
  () => import("../SiteConfiguration/SiteConfigurations")
);

export default function TabsConfiguration({
  type,
  items,
}: {
  readonly type: string;
  readonly items: ItemType[];
}) {
  const [tabItems, setTabItems] = useState<ItemType[]>(items);
  const [activeTabCode, setActiveTabCode] = useState<string>(items[0].id);
  const [brandName, setBrandName] = useState<string>("");
  const [archetypeName, setArchetypeName] = useState<string>("");
  const [siteTypeName, setSiteTypeName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const { brandId, archetypeId } = useParams();

  const setActiveTab = (e: TabItemProps) => {
    setTabItems(e.updatedTabData);
    setActiveTabCode(e.selectedItemId);
  };

  const handleBrandById = (brand: Brand) => {
    setBrandName(brand.businessBrandName);

    let archetype;
    let siteType;

    for (let sitetype of brand.siteTypes) {
      archetype = sitetype.archeTypes.find(
        (a) => a.id.toString() === archetypeId
      );
      if (archetype) {
        siteType = sitetype;
        break;
      }
    }
    if (archetype) {
      setArchetypeName(archetype.archeTypeName);
    }

    if (siteType) {
      setSiteTypeName(siteType.siteTypeName);
    }
    setLoading(false);
  };

  const handleBrandByIdError = (error: Error) => {
    toast(error.message, "error", "error");
  };

  useEffect(() => {
    if (brandId && archetypeId) {
      getBrandById(brandId).then(handleBrandById).catch(handleBrandByIdError);
    }
  }, [brandId, archetypeId]);

  const contextValue = useMemo(() => {
    return {
      brandId: brandId,
      archetypeId: archetypeId,
    };
  }, [brandId, archetypeId]);

  let configurationLabel = "";
  if (type === TabItemTypes.SYSTEM) {
    configurationLabel = "System Configuration";
  } else if (type === TabItemTypes.PROCESS) {
    configurationLabel = "Process Configuration";
  }

  return (
    <>
      {loading ? (
        loadingIndicator
      ) : (
        <>
          <Heading
            heading={configurationLabel}
            subheading=
              {<Info
                popoverContent={SystemConfiguration.Page_Subheading}
                tooltipText={
              <Box display="flex">
                {brandName} <StaticIcon icon="chevron-right" size="24" />
                {siteTypeName} <StaticIcon icon="chevron-right" size="24" />
                {archetypeName}
              </Box>} />
            }
          ></Heading>
          <BrandArchetypeContext.Provider value={contextValue}>
            <Box display="grid" gridTemplateColumns="1fr">
              <Tabs items={tabItems} onClick={setActiveTab} type="default" />
              <ContentWrapper>
                {type === TabItemTypes.SYSTEM && (
                  <>
                    {activeTabCode === tabItemCodes.site && (
                      <Suspense fallback={loadingIndicator}>
                        <Sites />
                      </Suspense>
                    )}
                    {activeTabCode === tabItemCodes.siteConfiguration && (
                      <Suspense fallback={loadingIndicator}>
                        <SiteConfigurations />
                      </Suspense>
                    )}
                    {activeTabCode === tabItemCodes.impact && (
                      <Suspense fallback={loadingIndicator}>
                        <Impacts />
                      </Suspense>
                    )}
                    {activeTabCode === tabItemCodes.dependencies && (
                      <Suspense fallback={loadingIndicator}>
                        <Dependencies />
                      </Suspense>
                    )}
                    {activeTabCode === tabItemCodes.contacts && (
                      <Suspense fallback={loadingIndicator}>
                        <Contact />
                      </Suspense>
                    )}
                  </>
                )}
                {type === TabItemTypes.PROCESS && (
                  <>
                    {activeTabCode === tabItemCodes.processes && (
                      <Suspense fallback={loadingIndicator}>
                        <ProcessesWrapper />
                      </Suspense>
                    )}
                    {activeTabCode === tabItemCodes.processDependencies && (
                      <Suspense fallback={loadingIndicator}>
                        <ProcessDependencies />
                      </Suspense>
                    )}
                    {activeTabCode === tabItemCodes.continuityPlan && (
                      <Suspense fallback={loadingIndicator}>
                        <ContinuityPlan />
                      </Suspense>
                    )}
                  </>
                )}
              </ContentWrapper>
            </Box>
          </BrandArchetypeContext.Provider>
        </>
      )}
    </>
  );
}

export const ContentWrapper = styled.div`
  padding-top: 10px;
`;
