export enum CustomerAction {
  SET_CUSTOMER = "SET_CUSTOMER",
  SET_CUSTOMERS_TABLE_DATA = "SET_CUSTOMERS_TABLE_DATA",
  SET_IS_MODAL_OPEN = "SET_IS_MODAL_OPEN",
  SET_EDITABLE_CUSTOMER = "SET_EDITABLE_CUSTOMER",
  SET_SHOW_TABLE = "SET_SHOW_TABLE",
  SET_KEY_CUSTOMERS_MODAL_OPEN = "SET_KEY_CUSTOMERS_MODAL_OPEN",
  SET_CONFIGURATIONS = "SET_CONFIGURATIONS",
  SET_ISAPPLICABLE = "SET_ISAPPLICABLE",
}

export const initialState = {
  customers: [],
  keyCustomersModalOpen: false,
  customersTableData: [],
  isModalOpen: false,
  editableCustomer: {},
  showtable: false,
  configurations: [],
  isApplicable: false,
};

export const reducer = (state: any, action: any) => {
  switch (action.type) {
    case CustomerAction.SET_CUSTOMER:
      return { ...state, customers: action.payload };
    case CustomerAction.SET_CUSTOMERS_TABLE_DATA:
      return { ...state, customersTableData: action.payload };
    case CustomerAction.SET_IS_MODAL_OPEN:
      return { ...state, isModalOpen: action.payload };
    case CustomerAction.SET_EDITABLE_CUSTOMER:
      return { ...state, editableCustomer: action.payload };
    case CustomerAction.SET_SHOW_TABLE:
      return { ...state, showtable: action.payload };
    case CustomerAction.SET_KEY_CUSTOMERS_MODAL_OPEN:
      return { ...state, keyCustomersModalOpen: action.payload };
    case CustomerAction.SET_CONFIGURATIONS:
      return { ...state, configurations: action.payload };
    case CustomerAction.SET_ISAPPLICABLE:
      return { ...state, isApplicable: action.payload };
    default:
      return state;
  }
};
