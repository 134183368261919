import {
  Attribute,
  SaveSiteConfiguration,
  SiteConfiguration,
} from "../../models/SiteConfiguration";
import { doGet, doPost } from "../../utils";
import { getApiUrl } from "../../utils/application-utils";

export const getSiteConfiguration = (
  archetypeId: string
): Promise<SiteConfiguration> =>
  doGet(`${getApiUrl()}/siteconfiguration/${archetypeId}`);

export const saveSiteConfigurations = (
  archetypeId: string,
  configurations: SaveSiteConfiguration
): Promise<any> =>
  doPost(`${getApiUrl()}/siteconfiguration/${archetypeId}`, configurations);

export const getSingleSiteConfiguration = (
  siteId: number,
  versionId: number,
  key: string
): Promise<Attribute[]> =>
  doGet(`${getApiUrl()}/siteconfiguration/${siteId}/${versionId}/${key}`);
