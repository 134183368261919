import { configuration } from "../../constants/Site";

export default function ConfigurableComponentsRenderer({
  configKey,
  configurations,
  children,
}: {
  configurations: any;
  configKey: string;
  children: any;
}) {
  if (!configurations) return null;

  return configurations[configKey] === configuration.Mandatory ||
    configurations[configKey] === configuration.Optional
    ? children
    : null;
}
