import { useEffect, useReducer } from "react";
import CommonModal, {
  AddNewContentText,
  ExpandableContentDeleteButtonWrapper,
  ExpandableContentWrapper,
} from "../../../components/common/commonModal";
import {
  defaultError,
  errorActions,
  errorReducer,
} from "../../../reducers/error";
import {
  defaultProcessImpactState,
  processImpactActions,
  processImpactReducer,
  processTypes,
} from "../reducers/process";
import Box from "../../../components/common/box";
import { Group } from "@maersk-global/community-react/src/index";
import styled from "styled-components";
import { createOrUpdateProcess } from "../../../services/Processes";
import { toast } from "../../../components/common/toast";
import { SaveProcessNames } from "../../../models/Processes";
import { DeleteIcon, StaticIcon } from "../../../components/common/icons";
import Tooltip from "../../../components/common/tooltip";
import { McInput } from "@maersk-global/mds-react-wrapper";
import { Roles } from "../../../utils";
import { createOrUpdateSiteProcess } from "../../../services/Site/Impacts";
import Info from "../../../components/common/info";
import { ProcessConfigurationSetup } from "../../../constants/Info";

const errorMessagesValidation = {
  PROCESS_NAME_REQUIRED: "Process name is required",
  SUB_PROCESS_NAME_REQUIRED: "Subprocess name is required",
};

export default function AddEditProcessModal({
  entityId,
  isTypeSwitchingAllowed,
  isMappedProcessLevel,
  role,
  ...props
}: Readonly<{
  entityId: string;
  isTypeSwitchingAllowed: boolean;
  isMappedProcessLevel: boolean;
  role: string;
  [key: string]: any;
}>) {
  const [errors, dispatchErrors] = useReducer(errorReducer, defaultError);
  const [processImpactState, dispatch] = useReducer(
    processImpactReducer,
    defaultProcessImpactState
  );

  useEffect(() => {
    dispatch({
      type: processImpactActions.SET_TYPE,
      value: isMappedProcessLevel
        ? processTypes.process
        : processTypes.subprocess,
    });
    if (props.editableProcess) {
      dispatch({
        type: processImpactActions.SET_EDITABLE_PROCESS,
        value: props.editableProcess,
      });
    }
  }, [props.isOpen]);

  const onModalClose = () => {
    dispatchErrors({ type: errorActions.REMOVE_ERROR });
    dispatch({ type: processImpactActions.RESET_MODAL_STATE });
    props.onClose();
  };

  const saveProcessImpact = () => {
    if (!validate()) return;
    role === Roles.GSCADMIN
      ? createOrUpdateProcessForArchetype()
      : createOrUpdateProcessForSite();
  };

  const createOrUpdateProcessForArchetype = () => {
    let saveProcessObj: SaveProcessNames = {
      processes: processImpactState.isProcess
        ? processImpactState.process
        : processImpactState.subProcess,
      doesSubProcessExist: !processImpactState.isProcess,
    };

    createOrUpdateProcess(entityId, saveProcessObj)
      .then((res) => {
        if (res) {
          toast(
            "Success",
            `Process
      ${processImpactState.id === 0 ? "added" : "updated"} successfully`,
            "success"
          );
          props.onSave();
          onModalClose();
        }
      })
      .catch((error) => {
        dispatchErrors({
          type: errorActions.ADD_ERROR,
          errorMessage: [error.message],
        });
      });
  };

  const createOrUpdateProcessForSite = () => {
    //add site id and version id to the process object
    let saveProcessObj = {
      processes: processImpactState.isProcess
        ? processImpactState.process
        : processImpactState.subProcess,
      doesSubProcessExist: !processImpactState.isProcess,
      siteId: entityId,
    };

    createOrUpdateSiteProcess(saveProcessObj)
      .then((res) => {
        if (res) {
          toast(
            "Success",
            `Process
      ${processImpactState.id === 0 ? "added" : "updated"} successfully`,
            "success"
          );
          props.onSave();
          onModalClose();
        }
      })
      .catch((error) => {
        dispatchErrors({
          type: errorActions.ADD_ERROR,
          errorMessage: [error.message],
        });
      });
  };

  const validate = () => {
    const errorMessages: string[] = [];

    if (processImpactState.isProcess) {
      processImpactState.process.forEach((process: any, index: number) => {
        if (
          process.name.trim() === "" &&
          !errorMessages.includes(errorMessagesValidation.PROCESS_NAME_REQUIRED)
        ) {
          errorMessages.push(errorMessagesValidation.PROCESS_NAME_REQUIRED);
        }
      });
    } else {
      processImpactState.subProcess.forEach(
        (subProcess: any, processIndex: number) => {
          if (
            subProcess.name.trim() === "" &&
            !errorMessages.includes(
              errorMessagesValidation.PROCESS_NAME_REQUIRED
            )
          ) {
            errorMessages.push(errorMessagesValidation.PROCESS_NAME_REQUIRED);
          }
          subProcess.subProcesses.forEach((subProcess: any, index: number) => {
            if (
              subProcess.name.trim() === "" &&
              !errorMessages.includes(
                errorMessagesValidation.SUB_PROCESS_NAME_REQUIRED
              )
            ) {
              errorMessages.push(
                errorMessagesValidation.SUB_PROCESS_NAME_REQUIRED
              );
            }
          });
        }
      );
    }
    if (errorMessages.length > 0) {
      dispatchErrors({
        type: errorActions.ADD_ERROR,
        errorMessage: errorMessages,
      });
      return false;
    }

    return true;
  };

  const removeProcess = (index: number) => {
    dispatch({
      type: processImpactActions.REMOVE_PROCESS,
      index: index,
    });
  };

  const removeSubprocess = (processIndex: number, subprocessIndex: number) => {
    dispatch({
      type: processImpactActions.REMOVE_SUB_PROCESS_SUB_PROCESS,
      processIndex: processIndex,
      subprocessIndex: subprocessIndex,
    });
  };

  return (
    <CommonModal
      primaryActionLabel="Confirm"
      primaryAction={() => saveProcessImpact()}
      secondaryActionLabel="Cancel"
      onSeceondaryAction={() => onModalClose()}
      isOpen={props.isOpen}
      onModalClose={() => onModalClose()}
      heading={`Add process`}
      errors={errors}
      dimension="medium"
      width={700}
    >
      <GroupWrapper>
        <Group
          fit="medium"
          onChange={(e: any) => {
            dispatchErrors({ type: errorActions.REMOVE_ERROR });
            dispatch({
              type: processImpactActions.SET_TYPE,
              value: e.target.value,
            });
          }}
          options={processImpactState.typeOptions}
          orientation="horizontal"
          value={processImpactState.type}
          id={""}
          name={""}
          disabled={!isTypeSwitchingAllowed}
        />
      </GroupWrapper>
      {processImpactState.isProcess ? (
        <>
          {processImpactState.process.map((process: any, index: number) => {
            return (
              <Box
                display="grid"
                gridTemplateColumns="20fr 1fr"
                gridGap={3}
                key={process.id}
              >
                <Box p={3}>
                  <McInput
                    fit="medium"
                    id="processName"
                    label={"Process name"}
                    required
                    type="text"
                    value={process.name}
                    variant="default"
                    key={process.id}
                    maxlength={250}
                    input={(e: any) => {
                      dispatch({
                        type: processImpactActions.PROCESS_NAME_CHANGE,
                        index: index,
                        value: e.target.value,
                      });
                    }}
                    disabled={process.isConfiguredArchetypeLevel ?? false}
                  >
                    <Info
                      popoverContent={
                        ProcessConfigurationSetup.AddEditProcessModal_ProcessName
                      }
                      tooltipText="Process Name"
                    />
                  </McInput>
                </Box>
                <ExpandableContentDeleteButtonWrapper>
                  {process.id === 0 && index !== 0 && (
                    <Tooltip content="Remove process">
                      <DeleteIcon
                        onClick={() => removeProcess(index)}
                        slot="trigger"
                      />
                    </Tooltip>
                  )}
                </ExpandableContentDeleteButtonWrapper>
              </Box>
            );
          })}
          <AddNewContentText
            onClick={() => {
              dispatch({
                type: processImpactActions.ADD_PROCESS,
              });
            }}
          >
            <StaticIcon
              icon="plus"
              color={"var(--mds_brand_appearance_secondary_default_text-color)"}
            />{" "}
            <span>Add new</span>
          </AddNewContentText>
        </>
      ) : (
        <>
          {processImpactState.subProcess.map(
            (process: any, processIndex: number) => (
              <ExpandableContentWrapper key={process.id}>
                {process.id === 0 && processIndex > 0 && (
                  <Box
                    display="flex"
                    gridTemplateColumns="1fr"
                    gridGap={3}
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Tooltip content="Remove process">
                      <DeleteIcon
                        onClick={() => removeProcess(processIndex)}
                        slot="trigger"
                      />
                    </Tooltip>
                  </Box>
                )}
                <Box display="grid" gridTemplateColumns="20fr 1fr" gridGap={3}>
                  <McInput
                    fit="medium"
                    id="processName"
                    label={"Process name"}
                    required
                    type="text"
                    key={process.id}
                    value={process.name}
                    variant="default"
                    maxlength={250}
                    disabled={process.isConfiguredArchetypeLevel ?? false}
                    input={(e: any) => {
                      dispatch({
                        type: processImpactActions.SET_SUBPROCESS_PROCESS_NAME_CHANGE,
                        value: e.target.value,
                        index: processIndex,
                      });
                    }}
                  >
                    <Info
                      popoverContent={
                        ProcessConfigurationSetup.AddEditProcessModal_ProcessName
                      }
                      tooltipText="Process Name"
                    />
                  </McInput>
                </Box>
                {process.subProcesses.map(
                  (subProcess: any, subProcessIndex: number) => (
                    <Box
                      display="grid"
                      gridTemplateColumns="20fr 1fr"
                      gridGap={3}
                      key={subProcess.id}
                    >
                      <Box p={3}>
                        <McInput
                          fit="medium"
                          id={subProcess.id.toString()}
                          label="Subprocess"
                          input={(e: any) => {
                            dispatch({
                              type: processImpactActions.SET_SUBPROCESS_SUBPROCESS_NAME_CHANGE,
                              value: e.target.value,
                              index: subProcessIndex,
                              processIndex: processIndex,
                            });
                          }}
                          placeholder=""
                          required
                          type="text"
                          value={subProcess.name}
                          variant="default"
                          maxlength={250}
                          key={process.id + "-" + subProcess.id}
                          disabled={
                            subProcess.isConfiguredArchetypeLevel ?? false
                          }
                        >
                          <Info
                            popoverContent={
                              ProcessConfigurationSetup.AddEditProcessModal_Subprocess
                            }
                            tooltipText="Subprocess"
                          />
                        </McInput>
                      </Box>
                      <ExpandableContentDeleteButtonWrapper>
                        {subProcess.id === 0 && subProcessIndex !== 0 && (
                          <Tooltip content="Remove subprocess">
                            <DeleteIcon
                              onClick={() =>
                                removeSubprocess(processIndex, subProcessIndex)
                              }
                              slot="trigger"
                            />
                          </Tooltip>
                        )}
                      </ExpandableContentDeleteButtonWrapper>
                    </Box>
                  )
                )}
                <AddNewContentText
                  onClick={() =>
                    dispatch({
                      type: processImpactActions.ADD_SUB_PROCESS_SUB_PROCESS,
                      index: processIndex,
                    })
                  }
                >
                  <StaticIcon
                    icon="plus"
                    color={"var(--mds_brand_appearance_secondary_default_text-color)"}
                  />
                  <span>Add new</span>
                </AddNewContentText>
              </ExpandableContentWrapper>
            )
          )}
          <AddNewContentText
            onClick={() =>
              dispatch({
                type: processImpactActions.ADD_SUB_PROCESS,
              })
            }
          >
            <StaticIcon
              icon="plus"
              color={"var(--mds_brand_appearance_secondary_default_text-color)"}
            />
            <span>Add new process</span>
          </AddNewContentText>
        </>
      )}
    </CommonModal>
  );
}

export const GroupWrapper = styled.div`
  padding: 0 0 10px 0;
`;
