import Brands from "./Brands";

export default function MasterData() {
  return (
    <Brands
      shouldShowButtons={false}
      shoudShowRegion={false}
      NavigationLink="process-configuration"
      heading="Master data"
      subheading="Set up processes and impact for the archetypes"
    />
  );
}
