import { useContext, useEffect, useReducer, useState } from "react";
import TitleBox from "../../components/common/titleBox";
import AddEditServiceModal from "./modals/addEditServiceModal";
import { ExternalService, ServiceType } from "../../models/Service";
import { getExternalServices } from "../../services/Contacts";
import { McTable } from "@maersk-global/mds-react-wrapper";
import { toast } from "../../components/common/toast";
import { TableColumn } from "@maersk-global/mds-components-core/mc-table/types";
import NoDataAvailable from "../../components/common/noDataAvailable";
import DeleteModal from "../../components/common/deleteModal";
import {
  defaultDeleteEntityState,
  deleteActions,
  deleteReducer,
  onDeleteEntity,
} from "../../reducers/delete";
import { DeleteIcon } from "../../components/common/icons";
import {
  FlexGrowBox,
  SiteConfigurationWrapper,
} from "../../components/common/common.styles";
import { BrandArchetypeContext } from "../../contexts/BrandArchetypeContext";
import { loadingIndicator } from "../../components/common/loading";

export const columns: TableColumn[] = [
  { id: "name", label: "Services", sortDisabled: true, width: "60%" },
  {
    id: "configurationValue",
    label: "Configuration",
    sortDisabled: true,
    align: "left",
    width: "30%",
  },
];

export default function Contact() {
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serviceType, setServiceType] = useState<number>(0);
  const [serviceTypeName, setServiceTypeName] = useState<string>("");

  const [externalContacts, setExternalContacts] = useState<
    ExternalService[] | null
  >(null);
  const [editableService, setEditableService] = useState<ServiceType[]>([]);
  const [deleteEntityState, dispatchDelete] = useReducer(
    deleteReducer,
    defaultDeleteEntityState
  );

  const { archetypeId } = useContext(BrandArchetypeContext) as {
    brandId: string;
    archetypeId: string;
  };

  useEffect(() => {
    getAllExternalServices();
  }, []);

  const getAllExternalServices = () => {
    getExternalServices(archetypeId)
      .then((response) => {
        setExternalContacts(response.services);
        setLoading(false);
      })
      .catch((error) => {
        toast("Error", "Failed to fetch external services", "error");
      });
  };

  const onAddEditClick = (externalContact: ExternalService) => {
    setServiceType(externalContact.serviceType);
    setServiceTypeName(externalContact.serviceName);
    setEditableService(externalContact.services);
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setEditableService([]);
  };

  const onSave = () => {
    getAllExternalServices();
  };

  const renderExternalServices = (
    services: ServiceType[],
    serviceName: string
  ) => {
    return services.length === 0 ? (
      <NoDataAvailable text={`No ${serviceName} added.`} />
    ) : (
      <McTable data={services} columns={columns} disableroundedcorners>
        {services.map((row: ServiceType) => (
          <div
            key={`${row.id}_configurationValue`}
            slot={`${row.id}_configurationValue`}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              {row.configurationValue}
              <FlexGrowBox />
              <DeleteIcon
                onClick={() =>
                  onDeleteEntity(
                    dispatchDelete,
                    row.id,
                    serviceName,
                    row.name,
                    "ExternalService",
                    ""
                  )
                }
              />
            </div>
          </div>
        ))}
      </McTable>
    );
  };

  if (loading) return loadingIndicator;

  return (
    <>
      <DeleteModal
        isOpen={deleteEntityState.isModalOpen}
        onSave={onSave}
        deleteEntity={deleteEntityState.deleteEntity}
        onClose={() => {
          dispatchDelete({
            type: deleteActions.SET_DELETE_MODAL_CLOSE,
          });
        }}
      />
      <AddEditServiceModal
        isOpen={isModalOpen}
        onModalClose={onModalClose}
        editableService={editableService}
        serviceType={serviceType}
        serviceTypeName={serviceTypeName}
        onSave={onSave}
        onClose={() => {
          setIsModalOpen(false);
        }}
      />
      {externalContacts?.map((externalContact) => {
        return (
          <SiteConfigurationWrapper key={externalContact.serviceName}>
            <TitleBox
              title={externalContact.serviceName}
              shouldShowButtons={externalContact.isApplicable}
              onCreate={() => onAddEditClick(externalContact)}
              label={`Add/Edit ${externalContact.serviceName}`}
              id={externalContact.serviceName}
            />
            {!externalContact.isApplicable ? (
              <NoDataAvailable
                text={`Cannot add new ${externalContact.serviceName} as it is not applied or service under configuration is not set mandatory.`}
              />
            ) : (
              renderExternalServices(
                externalContact.services,
                externalContact.serviceName
              )
            )}
          </SiteConfigurationWrapper>
        );
      })}
    </>
  );
}
