import { useContext, useEffect, useState } from "react";
import { toastError, toastSuccess } from "../../../components/common/toast";
import { loadingIndicator } from "../../../components/common/loading";
import ProcessDependencies from "../../ProcessDependencies/ProcessDependencies";
import useSite from "../../../hooks/useSite";
import {
  getSiteDependencies,
  saveMappedSiteDependencies,
} from "../../../services/Site/Dependency";
import { UserTypes } from "../../../constants/Users";
import { ModeContext } from "../SiteBCM";
import SiteElementLayout from "../../../components/common/siteElementLayout";
import { McNotification } from "@maersk-global/mds-react-wrapper";
import Notifications from "../../../components/common/notifications";
import { StyledListItem } from "../../../components/common/StyledComponents";
import Box from "../../../components/common/box";
import { StaticIcon } from "../../../components/common/icons";
import { siteComponentKeys } from "../../../constants/Site";
import NotificationWithIcons from "../../../components/common/notificationsWithIcon";
import { SiteDependencyMappingInfo } from "../../../constants/SiteInfo";

export default function SiteProcessDependencies({
  handleStatusChange,
  sectionId,
  componentStatus,
}: Readonly<{
  handleStatusChange: () => void;
  sectionId: number;
  componentStatus: any;
}>) {
  const [processes, setProcesses] = useState<any>();
  const [dependencyCategories, setDependencyCategories] = useState<any>();
  const [dependencyMapping, setDependencyMapping] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [onCancel, setOnCancel] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const { site, siteId } = useSite();
  const mode = useContext(ModeContext);

  useEffect(() => {
    if (siteId > 0) {
      getDependenciesMapping();
    }
  }, [onCancel, siteId]);

  const getDependenciesMapping = () => {
    getSiteDependencies(site.id, site.versionId)
      .then((data) => {
        setProcesses(data.processImpactMappings);
        setDependencyCategories({
          dependencyCategories: data.dependencyCategories,
        });
        setDependencyMapping({
          mappings: data.mappings,
        });
        setLoading(false);
      })
      .catch((error) => {
        toastError(error.message);
        setLoading(false);
        setErrorMessage(error.message);
      });
  };

  const saveDependencyMapping = (mappings: any) => {
    let siteDependenciesMapping = {
      mappings: mappings.mappings,
      siteId: site.id,
      versionId: site.versionId,
      doesSubProcessExist: mappings.doesSubProcessExist,
    };
    saveMappedSiteDependencies(siteDependenciesMapping)
      .then(() => {
        toastSuccess("Dependencies saved successfully for processes.");
        handleStatusChange();
      })
      .catch((error) => {
        toastError(error.message);
      });
  };

  const displayInfoMessage = () => {
    return (
      <>
        <NotificationWithIcons
          message="To save in the interim, click the 'Save' button."
          appearance="info"
          icon="info-circle"
        />
      </>
    );
  };

  const displayWarning = () => {
    return (
      <>
        <NotificationWithIcons
          message="Missing dependencies for some processes. Please ensure all processes have at least one dependency mapped."
          appearance="warning"
          icon="exclamation-triangle"
        />
      </>
    );
  };

  if (loading) return loadingIndicator;
  if (errorMessage)
    return <Notifications description={[errorMessage]} variant={"error"} />;

  return (
    <SiteElementLayout
      heading="Dependency"
      sectionId={sectionId}
      popoverContent={SiteDependencyMappingInfo.Section_Heading}
    >
      {displayInfoMessage()}
      {!componentStatus[siteComponentKeys.dependencies].status &&
        displayWarning()}

      <ProcessDependencies
        key={onCancel}
        processes={processes}
        dependencies={dependencyCategories}
        dependencyMapping={dependencyMapping}
        onSave={saveDependencyMapping}
        onCancel={() => {
          setOnCancel(!onCancel);
        }}
        userType={UserTypes.site}
        mode={mode}
      />
    </SiteElementLayout>
  );
}
