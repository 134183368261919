import { toastError } from "../components/common/toast";
import { downloadBCP } from "../services/Site/Dashboard";
import { downloadLocationLayoutFile } from "../services/Site/SiteDetails";

export const getFileNameFromPath = (filePath: string): string => {
  return filePath.split("/").pop() || "";
};

export const downloadFile = (siteId: number, filepath: string) => {
  downloadLocationLayoutFile(siteId, filepath)
    .then((response: any) => {
      return response;
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob); // create a URL representing the Blob
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", getFileNameFromPath(filepath));
      document.body.appendChild(link);
      link.click();

      if (link.parentNode) link.parentNode.removeChild(link);
    })
    .catch((error) => {
      toastError(error.message);
    });
};

export const downloadBusinessContinuityPlan = (
  siteId: number,
  path: string,
  fileName: string
) => {
  downloadBCP(siteId, path)
    .then((response) => {
      return response;
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob); // create a URL representing the Blob
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      if (link.parentNode) link.parentNode.removeChild(link);
    })
    .catch((error) => {
      toastError(error.message);
    });
};
