import { Fit } from "@maersk-global/mds-components-core/mc-select-native/types";
import { McOption, McSelect } from "@maersk-global/mds-react-wrapper";

interface McSelectProps {
  label: string;
  value: boolean | null;
  name: string;
  clearbutton: boolean | undefined;
  fit: Fit | undefined;
  onChange: (name: string, value: boolean) => void;
  onClearButtonClick: (name: string) => void;
  children?: React.ReactNode;
}

const items = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

export const SelectBoolean: React.FC<McSelectProps> = ({
  label,
  value,
  clearbutton,
  name,
  fit,
  onClearButtonClick,
  onChange,
  children,
}) => {
  const handleChange = (event: any) => {
    onChange(name, event.detail.value === "true");
  };

  const handleClearButtonClick = (e: any) => {
    onClearButtonClick(name);
  };

  return (
    <McSelect
      data-testid="select"
      label={label}
      value={value === null || value === undefined ? null : value.toString()}
      name={name}
      optionselected={handleChange}
      clearbutton={clearbutton}
      clearbuttonclick={handleClearButtonClick}
      fit={fit}
    >
      {items.map((item) => (
        <McOption key={item.value} value={item.value.toString()}>
          {item.label}
        </McOption>
      ))}
      {children}
    </McSelect>
  );
};
