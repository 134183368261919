import { SidebarItemsProps } from "@maersk-global/community-react-sidebar";
import { navItems } from "../constants/NavItems";
import { ConcreteRoles, Roles } from "./auth-utils";
import { UserMappedSites } from "../models/SiteDashboard";

export const getNavItems = (role: string): SidebarItemsProps[] => {
  if (!role) return [];

  if (role === ConcreteRoles.GSCADMIN) return navItems[Roles.GSCADMIN];
  else if (role === ConcreteRoles.SNRADMIN) return navItems[Roles.SNRADMIN];
  else if (role === ConcreteRoles.PROCESSOWNER) return navItems[Roles.PROCESSOWNER];
  else if (role === ConcreteRoles.OFFICEUSER) return navItems[Roles.OFFICEUSER];
  else if (role === ConcreteRoles.BRADMIN) return navItems[Roles.BRADMIN];

  return [];
};

export const getSiteNavItems = (
  mappedSites: UserMappedSites[]
): SidebarItemsProps[] => {
  if (!mappedSites) return [];

  let mappedItems = mappedSites.map((item) => {
    return {
      icon: "file",
      id: `${item.id}`,
      path: "/process",
      subMenu: [
        {
          icon: "house",
          id: `${item.code}-dashboard`,
          path: `/dashboard/${item.id}`,
          title: "Dashboard",
        },
        {
          icon: "file-eye",
          id: `${item.code}-bcm`,
          path: `/business-continuity-management/${item.id}`,
          title: "BCM",
        },
      ],
      title: `${item.code}`,
    };
  });

  return mappedItems;
};
