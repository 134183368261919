import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Brands from "../pages/Brands/Brands";
import { loadingIndicator } from "../components/common/loading";
import TabsConfiguration from "../pages/Configuration/TabsConfiguration";
import { tabItems, TabItemTypes } from "../constants/TabItems";
import MasterData from "../pages/Brands/MasterData";
import Customers from "../pages/Customers/Customers";
import Users from "../pages/UserManagement/Users";
import Dashboard from "../pages/Site/CustomizableDashboard/Dashboard";
import SiteGuardCustomised from "../pages/Site/SiteGuardCustomised";

export default function SnrAdminRoutes() {
  return (
    <Suspense fallback={loadingIndicator}>
      <Routes>
        <Route
          path="/"
          element={
            <Brands
              shouldShowButtons={true}
              shoudShowRegion={true}
              NavigationLink="system-configuration"
              heading="Business Brands"
              subheading="Configure business brands here."
            />
          }
        />
        <Route
          path="/hierarchy"
          element={
            <Brands
              shouldShowButtons={true}
              shoudShowRegion={true}
              NavigationLink="system-configuration"
              heading="Business Brands"
              subheading="Configure business brands here."
            />
          }
        />
        <Route
          path="/system-configuration/:brandId/:archetypeId"
          element={
            <TabsConfiguration
              type="system"
              items={tabItems[TabItemTypes.SYSTEM]}
            />
          }
        />
        <Route path="/master-data" element={<MasterData />} />
        <Route
          path="/process-configuration/:brandId/:archetypeId"
          element={
            <TabsConfiguration
              type="process"
              items={tabItems[TabItemTypes.PROCESS]}
            />
          }
        />
        <Route path="/user-management" element={<Users />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/site-overview" element={<Dashboard />}></Route>
        <Route
          path="/business-continuity-management/:id"
          element={<SiteGuardCustomised />}
        />
      </Routes>
    </Suspense>
  );
}
