import { useEffect, useState } from "react";
import SidebarLayout from "./sidebar/SidebarLayout";
import { SidebarItemsProps } from "@maersk-global/community-react";
import { getNavItems } from "../../utils";
import AppHeader from "./appHeader";
import ThemeImporter from "../theme/themeImporter";
import { themes } from "../../constants/Themes";
import { AppContainer } from "./layout.styles";

export default function GscAdminLayout({
  userName,
  role,
}: Readonly<{
  userName: string;
  role: string;
}>) {
  const [navItems, setNavItems] = useState<SidebarItemsProps[]>([]);

  useEffect(() => {
    let navItems = getNavItems(role);
    setNavItems(navItems);
  }, [role]);

  return (
    <ThemeImporter userTheme={themes.MAERSK}>
      <AppContainer>
        <AppHeader
          userName={userName}
          role={role}
          shouldShowNotification={false}
          shouldShowSettings={false}
        />
        <SidebarLayout navItems={navItems} isExpanded={false} />{" "}
      </AppContainer>
    </ThemeImporter>
  );
}
