import { useEffect, useState } from "react";
import { getStatusActivity } from "../../services/Site/Review";
import { toast } from "../../components/common/toast";
import {
  McStepIndicator,
  McStepIndicatorItem,
  McTag,
} from "@maersk-global/mds-react-wrapper";
import { styled } from "styled-components";
import { loadingIndicator } from "../../components/common/loading";
import { formatDateTime } from "../../utils/utils";

export default function ActivityLog({
  versionId,
  onClose,
}: Readonly<{ versionId: number; onClose: () => void }>) {
  const [siteBCPStatusActivity, setSiteBCPStatusActivity] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (versionId > 0) {
      getStatusLogActivity(versionId);
    }
  }, [isOpen, versionId]);

  const getStatusLogActivity = (versionId: number) => {
    getStatusActivity(versionId)
      .then((res) => {
        setSiteBCPStatusActivity(res);
        setLoading(false);
      })
      .catch((error) => {
        toast("Error", error.message, "error");
      });
  };

  const siteStatus = {
    Started: "started",
    Submitted: "submitted",
    Approved: "approved",
    PendingValidation: "pendingvalidation",
    Validated: "validated",
    Rework: "rework",
    Rejected: "rejected",
  };

  const getSiteBcpStatusTag = (status: string) => {
    switch (status) {
      case siteStatus.Started:
        return <McTag label="Started" fit="small" appearance="neutral-weak" />;
      case siteStatus.Submitted:
        return <McTag label="Submitted" fit="small" appearance="warning" />;
      case siteStatus.Approved:
        return <McTag label="Approved" fit="small" appearance="info" />;
      case siteStatus.PendingValidation:
        return (
          <McTag label="Pending Validation" fit="small" appearance="info" />
        );
      case siteStatus.Validated:
        return <McTag label="Validated" fit="small" appearance="success" />;
      case siteStatus.Rework:
        return <McTag label="Rework" fit="small" appearance="error" />;
      case siteStatus.Rejected:
        return <McTag label="Rejected" fit="small" appearance="error" />;
      default:
        return <></>;
    }
  };

  if (loading) return loadingIndicator;

  return (
    <ActivityLayoutWrapper>
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}>
          <span className="headingText">Activity Log</span>
        </div>
        <div style={{ display: "flex", cursor: "pointer" }} onClick={onClose}>
          X
        </div>
      </div>
      <div className="bodyWrapper">
        <McStepIndicator orientation="vertical">
          {siteBCPStatusActivity?.map((activity: any, index: number) => (
            <McStepIndicatorItem
              state="completed"
              icon="clipboard-text"
              key={index}
              style={{ width: "100%" }}
            >
              <span className="mds-font--small">
                <span>
                  <b>{formatDateTime(activity.updatedAt)}</b>
                  <span className="statusTag">
                    {getSiteBcpStatusTag(activity.status)}
                  </span>
                </span>
                <span className="activityItem">{activity.updatedBy}</span>
                <span className="activityItem">{activity.comments}</span>
              </span>
            </McStepIndicatorItem>
          ))}
        </McStepIndicator>
      </div>
    </ActivityLayoutWrapper>
  );
}

const ActivityLayoutWrapper = styled.div`
  padding: 10px;
  padding-left: 20px;
  padding-bottom: 20px;
  border-radius: 6px;
  margin-bottom: 10px;
  overflow: "scroll";

  .headingText {
    color: var(--mds_brand_appearance_neutral_default_text-color);
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
  }

  .bodyWrapper {
    overflow-y: auto; /* Enable vertical scrolling when content overflows */
    margin-top: 20px;
  }

  .activityItem {
    justify-content: space-between;
    align-items: center;
    padding-top: 3px; /* Add some space between the rows */
  }

  .statusTag {
    margin-left: auto; /* Pushes the statusTag to the right */
    padding-left: 10px; /* Add some space between the date-time and the status tag */
  }

  .activityDetails {
    margin-top: 8px; /* Add some space between the rows */
  }
`;
