import React, { useEffect, useState } from "react";
import { getSiteWidgetDetails } from "../../../services/Site/Widgets";
import { toastError } from "../../../components/common/toast";
import { loadingIndicator } from "../../../components/common/loading";
import Widgets from "./Widgets";

export default function Widget({
  siteId,
  site,
  key,
}: {
  siteId: number;
  site: any;
  key: string;
}) {
  const [widgetData, setWidgetData] = useState(null);

  useEffect(() => {
    if (siteId > 0) {
      getWidgetDetails();
    }
  }, [siteId, key]);

  const getWidgetDetails = () => {
    getSiteWidgetDetails(site.id, site.versionId)
      .then((res) => {
        setWidgetData(res);
      })
      .catch((error) => {
        toastError("Error", error.message);
      });
  };

  if (!widgetData) return loadingIndicator;

  return widgetData && <Widgets details={widgetData} />;
}
