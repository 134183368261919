import { useContext, useReducer, useState } from "react";
import { McButton, McInput, McPopover } from "@maersk-global/mds-react-wrapper";
import Box from "../../../components/common/box";
import {
  defaultDeleteEntityState,
  deleteActions,
  deleteReducer,
  onDeleteEntity,
} from "../../../reducers/delete";
import {
  createSiteDependency,
  updateSiteDependency,
} from "../../../services/Site/Dependency";
import { toastError, toastSuccess } from "../../../components/common/toast";
import useSite from "../../../hooks/useSite";
import DeleteModal from "../../../components/common/deleteModal";
import { Button } from "../../../components/common/button";
import { DeleteIcon } from "../../../components/common/icons";
import { ModeContext } from "../SiteBCM";
import { SiteModes } from "../../../constants/SiteBCM";

export default function SiteDependencies({
  dependencyCategoryId,
  dependencyCategoryName,
  dependencyDropdown,
  onDependencyEdited,
}: Readonly<{
  dependencyCategoryName: string;
  dependencyDropdown: any[];
  dependencyCategoryId: any;
  onDependencyEdited: Function;
}>) {
  const mode = useContext(ModeContext);

  const [dependencyName, setDependencyName] = useState("");
  const [isDependencyNameInvalid, setIsDependencyNameInvalid] =
    useState<boolean>(false);
  const [deleteEntityState, dispatchDelete] = useReducer(
    deleteReducer,
    defaultDeleteEntityState
  );
  const [dependencyDropdowns, setDependencyDropdowns] =
    useState<any[]>(dependencyDropdown);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [deletedDependencyId, setDeletedDependencyId] = useState<number>(0);

  let site = useSite();

  const setInitialState = () => {
    setDependencyName("");
    setIsDependencyNameInvalid(false);
    setIsPopoverOpen(true);
  };

  const saveSiteDependency = () => {
    if (dependencyName.trim() !== "") {
      let addedDependencyName = dependencyName.trim();
      createSiteDependency({
        siteId: site.site.id,
        versionId: site.site.versionId,
        categoryId: dependencyCategoryId,
        dependency: addedDependencyName,
      })
        .then((response) => {
          toastSuccess(
            `Dependency created successfully for category ${dependencyCategoryName}.`
          );
          let updatedDependencyDropdowns = [
            ...dependencyDropdowns,
            {
              id: response, //use id returned by response
              label: addedDependencyName,
              isSiteLevelDependency: true,
              value: response, // use id returned by response
            },
          ];
          setDependencyDropdowns(updatedDependencyDropdowns);
          onDependencyEdited(dependencyCategoryId, updatedDependencyDropdowns);

          setIsPopoverOpen(false);
          setInitialState();
        })
        .catch((error) => {
          toastError(error.message);
        });
    } else {
      setIsDependencyNameInvalid(true);
    }
  };

  const onDeleteSiteDependency = (
    dependencyId: any,
    dependencyName: string
  ) => {
    setDeletedDependencyId(dependencyId);
    onDeleteEntity(
      dispatchDelete,
      dependencyId,
      "Dependency",
      dependencyName,
      `site/dependency/${site.site.id}/${site.site.versionId}/${dependencyCategoryId}`,
      ""
    );
  };

  const setUpdateDependencyName = (
    dependencyName: string,
    dependencyId: any
  ) => {
    let updatedDependencyDropdowns = dependencyDropdowns.map((item) => {
      if (item.id === dependencyId) {
        return {
          ...item,
          label: dependencyName,
        };
      }
      return item;
    });

    setDependencyDropdowns(updatedDependencyDropdowns);
  };

  const onUpdateSiteDependency = (dependencyId: any) => {
    let updatedDependency = dependencyDropdowns.find(
      (item) => item.id === dependencyId
    );
    updateSiteDependency({
      siteId: site.site.id,
      versionId: site.site.versionId,
      categoryId: dependencyCategoryId,
      dependencyId: dependencyId,
      dependencyName: updatedDependency.label,
    })
      .then((response) => {
        toastSuccess(
          `Dependency updated successfully for category ${dependencyCategoryName}.`
        );
        onDependencyEdited(dependencyCategoryId, dependencyDropdowns);
        setInitialState();
      })
      .catch((error) => {
        toastError(error.message);
      });
  };

  const onDeleteSuccess = () => {
    //find the item with the id and remove it from the dependency dropdown
    let updatedDependencyDropdowns = dependencyDropdowns.filter(
      (item) => item.id !== deletedDependencyId
    );

    setDependencyDropdowns(updatedDependencyDropdowns);
    onDependencyEdited(dependencyCategoryId, updatedDependencyDropdowns);
  };

  return (
    <>
      <DeleteModal
        isOpen={deleteEntityState.isModalOpen}
        onSave={onDeleteSuccess}
        deleteEntity={deleteEntityState.deleteEntity}
        onClose={() => {
          dispatchDelete({
            type: deleteActions.SET_DELETE_MODAL_CLOSE,
          });
        }}
      />
      <McPopover
        trigger="click"
        width="auto"
        open={isPopoverOpen}
        opendelay={50}
        arrow
        fit="large"
        preventcloseonblur={true}
      >
        <Button
          fit="small"
          hiddenlabel
          variant="filled"
          icon="ellipsis-horizontal"
          appearance="neutral"
          slot="trigger"
          click={(e: any) => {
            setIsPopoverOpen(false);
            setInitialState();
          }}
        />
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "0.5em",
          }}
        >
          <div style={{ lineHeight: "24px", fontWeight: "bold" }}>
            Edit dependencies for {dependencyCategoryName}
          </div>
          <div>
            {dependencyDropdowns.map((item: any) => {
              return (
                item.isSiteLevelDependency && (
                  <Box
                    display="grid"
                    gridTemplateColumns="1fr 0.1fr 0.1fr"
                    style={{ alignItems: "center" }}
                  >
                    <Box>
                      <McInput
                        fit="small"
                        label=""
                        hiddenlabel
                        input={(e: any) => {
                          setUpdateDependencyName(e.target.value, item.id);
                        }}
                        value={item.label}
                      />
                    </Box>
                    <Box>
                      <Button
                        fit="small"
                        hiddenlabel
                        variant="filled"
                        icon="check"
                        appearance="neutral"
                        click={() => {
                          onUpdateSiteDependency(item.id);
                        }}
                        disabled={mode === SiteModes.Read}
                      />
                    </Box>
                    <Box>
                      <McButton
                        fit="small"
                        hiddenlabel
                        variant="filled"
                        appearance="neutral"
                        disabled={mode === SiteModes.Read}
                      >
                        <span slot="icon">
                          <DeleteIcon
                            onClick={() => {
                              setIsPopoverOpen(false);
                              onDeleteSiteDependency(item.id, item.label);
                            }}
                          />
                        </span>
                      </McButton>
                    </Box>
                  </Box>
                )
              );
            })}
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 0.1fr",
              alignItems: "end",
            }}
          >
            <div>
              <McInput
                fit="small"
                label="Add New Dependency"
                input={(e: any) => {
                  if (isDependencyNameInvalid)
                    setIsDependencyNameInvalid(false);
                  setDependencyName(e.target.value);
                }}
                value={dependencyName}
                invalid={isDependencyNameInvalid}
              />
            </div>
            <div>
              <Button
                fit="small"
                hiddenlabel
                variant="filled"
                icon="check"
                appearance="neutral"
                click={saveSiteDependency}
                slot="trigger"
                disabled={mode === SiteModes.Read}
              />
            </div>
          </div>
        </div>
      </McPopover>
    </>
  );
}
