import React from "react";
import styled from "styled-components";

/**
 * `ExpandedTableContent` is a React functional component that renders a section of content within an expanded table.
 * It displays a title and its corresponding value, where the value can be either a string or a React node, allowing for flexible content rendering.
 * 
 * Props:
 * - `title`: string - The title of the content section. This is displayed in bold.
 * - `value`: string | React.ReactNode - The value associated with the title. This can be a simple string or any React component/node.
 * 
 * Usage:
 * <ExpandedTableContent
 *   title="Section Title"
 *   value="This is the detailed content or can be a React component."
 * />
 * 
 * The component uses styled-components for styling, with specific styles applied to the title and value sections.
 * The title is displayed in bold, and the value content is wrapped within a div that has a maximum width of 500px and uses word-wrap to handle overflow.
 */
export default function ExpandedTableContent({
  title,
  value,
}: Readonly<{
  title: string;
  value: string | React.ReactNode;
}>) {
  return (
    <ExpandedTableContentWrapper>
      <div className="content-title">{title}</div>
      <div className="content-value-wrapper">{value}</div>
    </ExpandedTableContentWrapper>
  );
}

const ExpandedTableContentWrapper = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;

  .content-title {
    font-weight: bold;
  }

  .content-value-wrapper {
    max-width: 500px;
    word-wrap: break-word;
  }
`;
