import { useEffect, useState } from "react";
import { loadingIndicator } from "../../../components/common/loading";
import { getProcesses } from "../../../services/Processes";
import { ProcessImpactMappings } from "../../../models/Processes";
import { toast } from "../../../components/common/toast";
import EditableProcessTable from "../../Process/editableProcessTable";

export default function CurrentRecomendation(props: any) {
  const [loading, setLoading] = useState<boolean>(true);
  const [processes, setProcesses] = useState<ProcessImpactMappings[]>();

  useEffect(() => {
    if (props.isOpen)
      getProcesses(props.archetypeId)
        .then((res) => {
          if (!props.isProcess) {
            let selectedProcess = res.processImpactMappings.find(
              (item: ProcessImpactMappings) =>
                item.name === props.selectedProcessName
            );
            if (selectedProcess) {
              setProcesses(selectedProcess.subProcessImpactMappings);
            }
          } else {
            setProcesses(res.processImpactMappings);
          }
          setLoading(false);
        })
        .catch((error) => {
          toast("error", error.message, "error");
        });
  }, [props.isOpen, props.selectedProcessName]);

  return (
    <>
      {loading ? (
        loadingIndicator
      ) : (
        <EditableProcessTable
          processes={processes}
          impacts={props.impacts}
          tableColumns={props.tableColumns}
          isProcessEditEnabled={false}
          archetypeId={props.archetypeId}
          isProcess={false}
          isReadOnly={true}
        />
      )}
    </>
  );
}