import { Roles, SearchUser } from "../../models/UserManagement";
import { doGet, doPost, doPut } from "../../utils";
import { getApiUrl } from "../../utils/application-utils";

export const searchUsers = (searchTerm: string): Promise<SearchUser[]> => {
  return doGet(`${getApiUrl()}/user/searchuser/${searchTerm}/0/1000`);
};

export const getRoles = (): Promise<Roles[]> => {
  return doGet(`${getApiUrl()}/user/getallazureroles`);
};

export const createOrUpdateUser = (user: any): Promise<any> => {
  return user.id === 0
    ? doPost(`${getApiUrl()}/user/createnewuser`, user)
    : doPut(`${getApiUrl()}/user/updateuser`, user);
};

export const getUsers = (): Promise<any> => {
  return doGet(`${getApiUrl()}/user/getallusers/1/1000`);
};
