import SiteElementLayout from "../../components/common/siteElementLayout";
import Box from "../../components/common/box";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { loadingIndicator } from "../../components/common/loading";
import { Button } from "../../components/common/button";
import {
  getStaffingSecurity,
  saveStaffingSecurity,
} from "../../services/Site/OperationalParameters";
import { toast } from "../../components/common/toast";
import { configuration } from "../../constants/Site";
import {
  McNumberStepper,
  McOption,
  McSelect,
} from "@maersk-global/mds-react-wrapper";
import Notifications from "../../components/common/notifications";
import useSite from "../../hooks/useSite";
import { yesOrNoDropdownWithBooleanOptions } from "../../constants/SelectItems";
import SiteElementsRenderer from "../../components/common/siteElementsRenderer";
import { ModeContext } from "./SiteBCM";

const allowableStaffMembers = 15000;

export default function StaffingSecurity({
  onSubmit,
  sectionId,
}: {
  onSubmit: () => void;
  sectionId: number;
}) {
  const [isApplicable, setIsApplicable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [staffingSecurity, setStaffingSecurity] = useState<any[]>([]);

  const { siteId, site } = useSite();
  const mode = useContext(ModeContext);

  useEffect(() => {
    if (siteId > 0) {
      getStaffingSecurity(site.id, site.versionId)
        .then((response) => {
          setIsApplicable(response.isApplicable);
          if (response.isApplicable) {
            setStaffingSecurity(response.staffingSecurities);
          }
          setLoading(false);
        })
        .catch((error) => {
          toast("Error", error.message, "error");
        });
    }
  }, [siteId]);

  const save = useCallback(() => {
    let errorMessages = validate();
    if (errorMessages.length > 0) {
      toast("Error", errorMessages, "error");
      return;
    }
    let staffingAndSecurity = staffingSecurity.map((item) => {
      return {
        attributeKey: item.key,
        attributeValues: item.attributeValues
          ? item.attributeValues.toString()
          : null,
      };
    });

    let saveStaffingAndSecurityModel = {
      siteId: site.id,
      staffingSecurities: staffingAndSecurity,
    };

    saveStaffingSecurity(saveStaffingAndSecurityModel)
      .then(() => {
        toast("Success", "Staffing & Security saved successfully", "success");
        onSubmit();
      })
      .catch((error) => {
        toast("Error", error, "error");
      });
  }, [staffingSecurity]);

  const validate = useCallback(() => {
    for (let item of staffingSecurity) {
      let attributeValues = item.attributeValues;
      if (item.configurationValue === configuration.Mandatory) {
        if (
          attributeValues === null ||
          attributeValues === "" ||
          attributeValues === false
        ) {
          return `Value for ${item.name} is mandatory.`;
        }
      }
      if (
        attributeValues !== null &&
        attributeValues !== "" &&
        attributeValues > allowableStaffMembers
      ) {
        return `Quantity for ${item.name} should not be more than ${allowableStaffMembers}.`;
      }
    }
    return "";
  }, [staffingSecurity]);

  const setAttribute = useCallback((input: any, index: number) => {
    setStaffingSecurity((prevStaffingSecurity) => {
      let value = input;

      const updatedStaffingSecurity = [...prevStaffingSecurity];
      updatedStaffingSecurity[index].attributeValues = value;

      return updatedStaffingSecurity;
    });
  }, []);

  const buildStaffingSecurity = useCallback(() => {
    return (
      <Box display="grid" gridTemplateColumns="repeat(6, 1fr)" gap="10px">
        {staffingSecurity.map((item: any, index: number) => {
          return item.configurationValue === configuration.Mandatory ||
            item.configurationValue === configuration.Optional ? (
            <Box
              key={index}
              display="grid"
              gridTemplateRows="1fr auto"
              style={{
                alignItems: "center",
              }}
            >
              {item.key === "security-onsite-day" ||
              item.key === "security-onsite-night" ? (
                <>
                  <Box>
                    {item.configurationValue === configuration.Mandatory && (
                      <span
                        style={{
                          color:
                            "var(--mds_brand_appearance_error_default_text-color)",
                        }}
                      >
                        *
                      </span>
                    )}{" "}
                    {item.name}
                  </Box>
                  <Box>
                    <div style={{ width: "60%" }}>
                      <McSelect
                        label=""
                        fit="medium"
                        value={item.attributeValues}
                        optionselected={(e: CustomEvent) => {
                          let detail = e.detail.value;
                          setAttribute(detail, index);
                        }}
                        clearbutton
                        clearbuttonclick={(e: CustomEvent) => {
                          setAttribute(null, index);
                        }}
                      >
                        {yesOrNoDropdownWithBooleanOptions.map((option) => {
                          return (
                            <McOption key={option.label} value={option.value}>
                              {option.label}
                            </McOption>
                          );
                        })}
                      </McSelect>
                    </div>
                  </Box>
                </>
              ) : (
                <>
                  <Box>
                    {item.configurationValue === configuration.Mandatory && (
                      <span
                        style={{
                          color: "var(--mds_brand_appearance_error_default_text-color)",
                        }}
                      >
                        *
                      </span>
                    )}{" "}
                    {item.name}
                  </Box>
                  <Box>
                    <div style={{ width: "180%" }}>
                      <McNumberStepper
                        name="username"
                        label=""
                        placeholder=""
                        min="0"
                        max={allowableStaffMembers}
                        step="1"
                        width="20"
                        input={(e: any) => {
                          setAttribute(e.target.value, index);
                        }}
                        value={item.attributeValues}
                        fit="medium"
                      />
                    </div>
                  </Box>
                </>
              )}
            </Box>
          ) : null;
        })}
      </Box>
    );
  }, [staffingSecurity]);

  const staffingAndSecurity = useMemo(
    () => buildStaffingSecurity(),
    [staffingSecurity]
  );

  if (loading) return loadingIndicator;

  if (!isApplicable) {
    return (
      <Notifications
        description={[
          "Staffing & Security is not applicable for your site.",
        ]}
        variant="warning"
      />
    );
  }

  return (
    <SiteElementLayout heading="Staffing & Security" sectionId={sectionId}>
      <>
        {staffingSecurity.length > 0 ? (
          <>
            {staffingAndSecurity}
            <SiteElementsRenderer mode={mode}>
              <Box
                display="flex"
                style={{ alignItems: "flex-end", paddingTop: "8px" }}
              >
                <Button fit="small" label="Save" click={() => save()} />
              </Box>
            </SiteElementsRenderer>
          </>
        ) : null}
      </>
    </SiteElementLayout>
  );
}
