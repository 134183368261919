export const recommendationsActions = {
  SET_MODAL_OPEN: "SET_MODAL_OPEN",
  SET_SELECTED_PROCESS: "SET_SELECTED_PROCESS",
  SET_MODAL_CLOSE: "SET_MODAL_CLOSE",
  REMOVE_SELECTED_PROCESS: "REMOVE_SELECTED_PROCESS",
};

export const recommendationsInitialState = {
  isRecommendationsModalOpen: false,
  selectedProcessName: "",
  isProcess: false,
  isConfiguredArchetypeLevel: false,
};

export const recommendationsReducer = (state: any, action: any) => {
  switch (action.type) {
    case recommendationsActions.SET_MODAL_OPEN:
      return {
        ...state,
        isRecommendationsModalOpen: true,
      };
    case recommendationsActions.SET_SELECTED_PROCESS:
      return {
        ...state,
        selectedProcessName: action.selectedProcessName,
        isProcess: action.isProcess,
        isConfiguredArchetypeLevel: action.isConfiguredArchetypeLevel,
      };
    case recommendationsActions.SET_MODAL_CLOSE:
      return {
        ...state,
        isRecommendationsModalOpen: false,
      };
    case recommendationsActions.REMOVE_SELECTED_PROCESS:
      return {
        ...state,
        selectedProcessId: 0,
      };
    default:
      return state;
  }
};
