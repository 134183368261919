import { doGet, doPostWithMultipart } from "../../utils";
import { getApiUrl } from "../../utils/application-utils";

export const getLocationLayout = (
  siteId: number,
  version: number
): Promise<any> => doGet(`${getApiUrl()}/locationlayout/${siteId}/${version}`);

export const downloadLocationLayoutFile = (
  siteId: number,
  path: string
): Promise<any> =>
  doGet(`${getApiUrl()}/file/download/${siteId}?filepath=${path}`);

export const saveLocationLayout = (request: any): Promise<any> =>
  doPostWithMultipart(`${getApiUrl()}/locationlayout`, request);
