import { doGet, doPost, doPostWithoutBody } from "../../utils";
import { getApiUrl } from "../../utils/application-utils";

export const startBCMReview = (siteId: number): Promise<number> =>
  doPostWithoutBody(`${getApiUrl()}/sites/${siteId}/review/start`);

export const getTabsStatus = (versionId: number): Promise<any> =>
  doGet(`${getApiUrl()}/bcm/componentstatus/get/${versionId}`);

export const submitBCMReview = (submitRequest: any): Promise<any> =>
  doPost(`${getApiUrl()}/sites/review/submit`, submitRequest);

export const updateBCMStatus = (statusRequest: any): Promise<any> =>
  doPost(`${getApiUrl()}/sites/status/update`, statusRequest);

export const getStatusActivity = (versionId: number): Promise<any> =>
  doGet(`${getApiUrl()}/bcm/${versionId}/status/activity`);
