import { StaticIcon } from "../components/common/icons";
import { Info } from "./Info";

export const OperationalScheduleInfo: Info = {
  Section_Heading: (
    <ul style={{ listStyle: "none" }}>
      <li>
        Enter the operating hours for the site and no of shifts for each day for
        a site
      </li>
      <li>
        <span
          style={{
            color: "var(--mds_brand_appearance_error_default_text-color)",
          }}
        >
          *
        </span>{" "}
        denotes that information for the day is mandatory.
      </li>
      <li>
        <strong>NOTE: </strong> To mark a site operating 24 hours, select the
        operating hours <strong>from</strong> and operating hours{" "}
        <strong>to</strong> as 00:00
      </li>
    </ul>
  ),
};

export const ContinuityPlanInfo: Info = {
  Section_Heading: (
    <ul style={{ listStyle: "none" }}>
      <li>
        <strong>NOTE: </strong> Expand the dependency first and then click on
        edit to edit the dependency.{" "}
      </li>
      <li>Continuity plan for a dependency must not exceed 10 MB.</li>
    </ul>
  ),
};

export const SiteDependencyMappingInfo: Info = {
  Section_Heading: (
    <ul style={{ listStyle: "none" }}>
      <li>
        All processes that meet the configured dependency RTO are displayed{" "}
      </li>
      <li>All Process must have at least one dependency mapped to it.</li>
    </ul>
  ),
};

export const ContactsInfo: Info = {
  Section_Heading: (
    <ul style={{ listStyle: "none" }}>
      <li>Internal contacts must have at least one site owner.</li>
      <li>
        External and emergency contacts designated as mandatory by the admin
        must be completed.
      </li>
      <li>
        <div style={{ display: "flex", alignItems: "center" }}>
          An exclamation mark &nbsp;
          <StaticIcon icon="exclamation-circle" /> &nbsp; denotes that mandatory
          contact fields, set by the admin, are not filled.
        </div>
      </li>
      <li>
        {" "}
        Click "Edit" in respective sections to update and complete the required
        fields
      </li>
      <li>To add multiple phone numbers, separate them with a comma.</li>
    </ul>
  ),
};

export const ThemesInfo: Info = {
  Section_Heading: (
    <ul style={{ listStyle: "none" }}>
      <li>Select a theme from the one below.</li>
      <li>Theme changes applied for future visits.</li>
    </ul>
  ),
};
