export const customerActions = {
  ON_INPUT_CHANGE: "ON_INPUT_CHANGE",
  RESET_STATE: "RESET_STATE",
  SET_CUSTOMER: "SET_CUSTOMER",
};

export const defaultCustomer = {
  id: 0,
  customerName: "",
  customerService: "",
  concernCode: "",
  typeOfCustomer: 0,
  vertical: 0,
  keyClientDirector: "",
  cpd: "",
  cpm: "",
  scdManager: "",
  bcpRequirement: null,
  bcpRequirementComment: "",
  bcpTestRequirement: null,
  bcpTestRequirementComment: "",
  itRecoveryRequirement: null,
  itRecoveryRequirementComment: "",
  bcTolerance: null,
  bcToleranceTimeFrame: null,
  bcToleranceValue: "",
  incidentNotification: null,
  incidentNotificationTimeFrame: null,
  incidentNotificationValue: "",
  incidentNotificationMethod: "",
  otherBCPRequirement: "",
  avgWklyInboudOutBoundVolume: null,
  avgWeeklyVesselCalls: null,
  commitmentService: "",
  contractualLiabilityToFailure: null,
  minBusinessContinuityLevel: "",
  isApplicable: false,
  isGlobalKeyCustomer: false,
};


export const customerReducer = (state: any, action: any) => {
  switch (action.type) {
    case customerActions.ON_INPUT_CHANGE:
      return { ...state, [action.name]: action.value };
    case customerActions.SET_CUSTOMER:
      let editableCustomer = action.customer;
      let customer = { ...state };
      customer.id = editableCustomer.id;
      customer.typeOfCustomer = editableCustomer.typeOfCustomerId;
      customer.vertical = editableCustomer.verticalId;
      customer.bcpRequirement = getRequirementValue(
        editableCustomer.bcpRequirement
      );
      customer.bcpTestRequirement = getRequirementValue(
        editableCustomer.bcpTestRequirement
      );
      customer.itRecoveryRequirement = getRequirementValue(
        editableCustomer.itRecoveryRequirement
      );
      customer.avgWklyInboundOutBoundVolume =
        editableCustomer.avgWklyInboudOutBoundVolume;
      customer.incidentNotificationTimeframe =
        editableCustomer.incidentNotificationTimeFrame;
      customer.customerName = editableCustomer.customerName;
      customer.customerService = editableCustomer.customerService;
      customer.concernCode = editableCustomer.concernCode;
      customer.keyClientDirector = editableCustomer.keyClientDirector;
      customer.cpd = editableCustomer.cpd;
      customer.cpm = editableCustomer.cpm;
      customer.scdManager = editableCustomer.scdManager;
      customer.bcpRequirementComment = editableCustomer.bcpRequirementComment;
      customer.bcpTestRequirementComment =
        editableCustomer.bcpTestRequirementComment;
      customer.itRecoveryRequirementComment =
        editableCustomer.itRecoveryRequirementComment;
      customer.bcTolerance = editableCustomer.bcTolerance;
      customer.bcToleranceTimeFrame = editableCustomer.bcToleranceTimeFrame;
      customer.bcToleranceValue = editableCustomer.bcToleranceValue;
      customer.incidentNotification = editableCustomer.incidentNotification;
      customer.incidentNotificationTimeFrame =
        editableCustomer.incidentNotificationTimeFrame;
      customer.incidentNotificationValue =
        editableCustomer.incidentNotificationValue;
      customer.incidentNotificationMethod =
        editableCustomer.incidentNotificationMethod;
      customer.otherBCPRequirement = editableCustomer.otherBCPRequirement;
      customer.avgWeeklyVesselCalls = editableCustomer.avgWeeklyVesselCalls;
      customer.avgWklyInboudOutBoundVolume =
        editableCustomer.avgWklyInboudOutBoundVolume;
      customer.commitmentService = editableCustomer.commitmentService;
      customer.contractualLiabilityToFailure =
        editableCustomer.contractualLiabilityToFailure;
      customer.minBusinessContinuityLevel =
        editableCustomer.minBusinessContinuityLevel ?? "";
      customer.isApplicable = editableCustomer.isApplicable;
      customer.isGlobalKeyCustomer = editableCustomer.isGlobalKeyCustomer;
      return { ...customer };
    case customerActions.RESET_STATE:
      return defaultCustomer;
    default:
      return state;
  }
};

export const getRequirementValue = (requirement: any) => {
  if (requirement === null) {
    return null;
  }

  return requirement ? 1 : 2;
};
