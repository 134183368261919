import { Box } from "@maersk-global/community-react";
import { FlexGrowBox } from "../../../components/common/common.styles";
import { McButton, McOption, McSelect } from "@maersk-global/mds-react-wrapper";
import { useState } from "react";

const tableRows = [
  {
    label: "20",
    value: 20,
  },
  {
    label: "50",
    value: 50,
  },
  {
    label: "100",
    value: 100,
  },
  {
    label: "150",
    value: 150,
  },
];

export default function CustomisableTableHeader({
  onCustomiseClick,
  onRowsChange,
}: Readonly<{
  onCustomiseClick: () => void;
  onRowsChange: (rows: number) => void;
}>) {
  const [totalRows, setTotalRows] = useState<number>(20);

  const handleInputChange = (e: any) => {
    setTotalRows(Number(e.target.value));
    onRowsChange(Number(e.target.value));
  };

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "flex-end",
        padding: "10px 0px 10px 0px",
      }}
    >
      <Box>List of Sites</Box>
      <FlexGrowBox />
      <Box style={{ display: "flex", gap: "10px" }}>
        <McSelect
          fit="small"
          label="Show Rows"
          labelposition="left"
          name="rows"
          optionselected={(e: any) => handleInputChange(e)}
          value={totalRows.toString()}
        >
          {tableRows.map((row) => (
            <McOption key={row.value} value={row.value.toString()}>
              {row.label}
            </McOption>
          ))}
        </McSelect>
        <McButton
          fit="small"
          variant="outlined"
          appearance="neutral"
          icon="cog"
          label="Customize"
          click={onCustomiseClick}
        />
      </Box>
    </Box>
  );
}
