import {
  OperationSchedules,
  SaveOperationSchedules,
} from "../../models/OperationSchedule";
import { doGet, doPost } from "../../utils";
import { getApiUrl } from "../../utils/application-utils";

export const getOperationSchedule = (
  siteId: number,
  version: number
): Promise<OperationSchedules> =>
  doGet(`${getApiUrl()}/operationschedule/${siteId}/${version}`);

export const saveOperationSchedules = (
  operationSchedule: SaveOperationSchedules
): Promise<any> =>
  doPost(`${getApiUrl()}/operationschedule`, operationSchedule);

export const getStaffingSecurity = (
  siteId: number,
  version: number
): Promise<any> =>
  doGet(`${getApiUrl()}/staffingsecurity/${siteId}/${version}`);

export const saveStaffingSecurity = (staffingSecurity: any): Promise<any> =>
  doPost(`${getApiUrl()}/staffingsecurity`, staffingSecurity);

export const getSeasonalVariation = (
  siteId: number,
  version: number
): Promise<any> =>
  doGet(`${getApiUrl()}/seasonalvariation/${siteId}/${version}`);

export const saveSeasonalVariation = (seasonalVariation: any): Promise<any> =>
  doPost(`${getApiUrl()}/seasonalvariation/create`, seasonalVariation);
