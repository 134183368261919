import { ExternalContactsResponse, SaveServicesRequest } from "../../models/Service";
import { doGet, doPost } from "../../utils";
import { getApiUrl } from "../../utils/application-utils";

export const createOrUpdateService = (
  service: SaveServicesRequest,
  archetypeId: string 
): Promise<any> => doPost(`${getApiUrl()}/externalservice/${archetypeId}`, service);

export const getExternalServices = (archetypeId: string): Promise<ExternalContactsResponse> =>
  doGet(`${getApiUrl()}/externalservice/${archetypeId}`);
