import { SidebarItemsProps } from "@maersk-global/community-react";
import React, { useEffect, useState } from "react";
import ThemeImporter from "../theme/themeImporter";
import { AppContainer } from "./layout.styles";
import AppHeader from "./appHeader";
import SidebarLayout from "./sidebar/SidebarLayout";
import { themes } from "../../constants/Themes";
import { getUserTheme } from "../../services/Theme";
import { getNavItems } from "../../utils";
import { loadingIndicator } from "../common/loading";

export default function SiteAdminLayout({
  userName,
  role,
}: Readonly<{
  userName: string;
  role: string;
}>) {
  const [navItems, setNavItems] = useState<SidebarItemsProps[]>([]);
  const [theme, setTheme] = useState<string>(themes.MAERSK);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getUserTheme().then((response) => {
      setTheme(response);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    let navItems = getNavItems(role);
    setNavItems(navItems);
  }, [role]);

  if (loading) return loadingIndicator;

  return (
    <ThemeImporter userTheme={theme}>
      <AppContainer>
        <AppHeader
          userName={userName}
          role={role}
          shouldShowNotification={true}
          shouldShowSettings={true}
        />
        <SidebarLayout navItems={navItems} isExpanded={false} />
      </AppContainer>
    </ThemeImporter>
  );
}
