import SiteElementLayout from "../../components/common/siteElementLayout";
import { McInput } from "@maersk-global/mds-react-wrapper/components-core/mc-input";
import styled from "styled-components";
import { Button } from "../../components/common/button";
import { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "../../components/common/toast";
import {
  getSeasonalVariation,
  saveSeasonalVariation,
} from "../../services/Site/OperationalParameters";
import { loadingIndicator } from "../../components/common/loading";
import { configuration } from "../../constants/Site";
import Notifications from "../../components/common/notifications";
import useSite from "../../hooks/useSite";
import SiteElementsRenderer from "../../components/common/siteElementsRenderer";
import { ModeContext } from "./SiteBCM";
import Box from "../../components/common/box";
import {
  McLabel,
  McMultiSelect,
  McOption,
} from "@maersk-global/mds-react-wrapper";
import { IMcMultiSelectOptionsSelectedDetail } from "@maersk-global/mds-components-core/mc-multi-select/types";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function SeasonalVariation({
  onSubmit,
  sectionId,
}: Readonly<{
  onSubmit: () => void;
  sectionId: number;
}>) {
  const [loading, setLoading] = useState<boolean>(true);
  const [seasonalVariation, setSeasonalVariation] = useState<any[]>([]);
  const [isApplicable, setIsApplicable] = useState<boolean>(false);
  const [peakMonthConfiguration, setPeakMonthConfiguration] =
    useState<number>();

  const { siteId, site } = useSite();
  const mode = useContext(ModeContext);

  useEffect(() => {
    if (siteId > 0) {
      getSeasonalVariation(site.id, site.versionId)
        .then((response) => {
          setIsApplicable(response.isApplicable);
          if (response.isApplicable) {
            let mappedSeasonalVariations: any[] = [];
            months.map((month, index) => {
              const monthData = response.seasonalVariations.find(
                (item: any) => item.month === month
              );
              if (!monthData) {
                mappedSeasonalVariations.push({
                  month: month,
                  isSelected: false,
                  peakVolumeAverage: "",
                  id: index,
                });
              } else {
                mappedSeasonalVariations.push({
                  month: month,
                  isSelected: monthData.isSelected,
                  peakVolumeAverage: monthData.peakVolumeAverage,
                  id: index,
                });
              }
            });
            setSeasonalVariation(mappedSeasonalVariations);
            setPeakMonthConfiguration(response.peakMonthConfiguration);
          }
          setLoading(false);
        })
        .catch((error) => {
          toast("Error", error.message, "error");
        });
    }
  }, [siteId]);

  const save = useCallback(() => {
    let errorMessages = validate();
    if (errorMessages.length > 0) {
      toast("Error", errorMessages, "error");
      return;
    }

    let filteredSeasonalVariations = seasonalVariation.filter(
      (item) => item.isSelected
    );

    let saveSeasonalVariationModel = {
      siteId: site.id,
      seasonalVariations: filteredSeasonalVariations,
    };

    saveSeasonalVariation(saveSeasonalVariationModel)
      .then(() => {
        toast("Success", "Seasonal Variation saved successfully.", "success");
        onSubmit();
      })
      .catch((error) => {
        toast("Error", error.message, "error");
      });
  }, [seasonalVariation, site.id]);

  const validate = useCallback(() => {
    if (peakMonthConfiguration == configuration.Mandatory) {
      let peakMonthCount = seasonalVariation.filter(
        (item) => item.isSelected
      ).length;
      if (peakMonthCount === 0) {
        return "At least one peak month selection is mandatory.";
      }
    }

    for (let item of seasonalVariation) {
      if (item.isSelected && item.peakVolumeAverage === "") {
        return `Peak Volume Above Average (%) is mandatory when a month is selected.`;
      }

      if (item.peakVolumeAverage < 0 || item.peakVolumeAverage > 100) {
        return `Peak Volume Above Average (%) should be between 0 and 100 for ${item.month}.`;
      }
    }

    const hasInvalidPeakVolume = seasonalVariation.some(
      (item) => item.peakVolumeAverage < 0 || item.peakVolumeAverage > 100
    );

    if (hasInvalidPeakVolume) {
      return "Peak Volume Above Average (%) should be between 0 and 100 for all months.";
    }

    return "";
  }, [seasonalVariation, peakMonthConfiguration]);

  const onItemsSelected = (selectedItems: any) => {
    let selectedMonths = selectedItems.map((item: any) => item.label);
    let updatedSeasonalVariation = seasonalVariation.map((item) => {
      if (selectedMonths.includes(item.month)) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
        item.peakVolumeAverage = "";
      }
      return item;
    });

    setSeasonalVariation(updatedSeasonalVariation);
  };

  const setInputChange = (month: string, value: string) => {
    let selectedMonth = seasonalVariation?.find((data) => data.month === month);
    let updatedValue = value === "" ? "" : value;

    if (selectedMonth) {
      selectedMonth.peakVolumeAverage = updatedValue;
    } else {
      seasonalVariation?.push({
        month: month,
        isSelected: false,
        peakVolumeAverage: updatedValue,
      });
    }

    setSeasonalVariation([...seasonalVariation]);
  };

  const setOnKeyDown = (e: React.KeyboardEvent) => {
    if (!/\d|\./.test(e.key) && e.key !== "Backspace") {
      e.preventDefault();
    }
  };

  const buildSeasonalVariation = useCallback(() => {
    return (
      seasonalVariation && (
        <Box display="grid" gridTemplateColumns="0.5fr 1.5fr">
          <Box>
            <McMultiSelect
              fit={"medium"}
              label="Peak Months"
              optionselected={(
                e: CustomEvent<IMcMultiSelectOptionsSelectedDetail>
              ) => {
                onItemsSelected(e.detail);
              }}
              value={seasonalVariation
                .filter((item) => item.isSelected)
                .map((item) => item.month)
                .join(",")}
            >
              {seasonalVariation.map((item: any) => {
                return (
                  <McOption key={item.id} value={item.month}>
                    {item.month}
                  </McOption>
                );
              })}
            </McMultiSelect>
          </Box>
          <Box>
            <Box display="grid" gridTemplateColumns="0.5fr 1.5fr">
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "baseline",
                  marginLeft: "50px",
                  marginRight: "50px",
                }}
              >
                <McLabel label="Peak Volume Above Average (%)" />
              </Box>
              <Box
                display="grid"
                gridTemplateColumns="repeat(4, 1fr)"
                style={{ gap: "20px" }}
              >
                {seasonalVariation.map((month) => {
                  return (
                    month.isSelected && (
                      <Box key={month.month} display="grid">
                        <InputWrapper>
                          <McInput
                            name={`${month.month}-peak-volume`}
                            label={month.month}
                            placeholder=""
                            value={month.peakVolumeAverage}
                            input={(e: any) => {
                              setInputChange(month.month, e.target.value);
                            }}
                            onKeyDown={(e: any) => {
                              setOnKeyDown(e);
                            }}
                            fit="medium"
                            type="number"
                            disabled={!month.isSelected}
                          />
                        </InputWrapper>
                      </Box>
                    )
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      )
    );
  }, [seasonalVariation]);

  if (loading) return loadingIndicator;

  if (!isApplicable) {
    return (
      <Notifications
        description={[
          "Seasonal Variation is not applicable for your site.",
        ]}
        variant="warning"
      />
    );
  }

  return (
    <SiteElementLayout heading="Seasonal Variation" sectionId={sectionId}>
      <>
        {buildSeasonalVariation()}
        <SiteElementsRenderer mode={mode}>
          <ActionButtonWrapper>
            <Button fit="small" label="Save" click={() => save()} />
          </ActionButtonWrapper>
        </SiteElementsRenderer>
      </>
    </SiteElementLayout>
  );
}

export const Wrapper = styled.div`
  padding: 0px 6px 5px 6px;
`;
export const LabelWrapper = styled.div`
  padding: 5px 8px 5px 0px;
  white-space: nowrap;
  align-items: center;
`;
export const InputWrapper = styled.div`
  padding: 0px 8px 5px 8px;
`;
export const ActionButtonWrapper = styled(Box)`
  display: flex;
  align-items: flex-end;
  padding-top: 10px;
`;
