import {
  Processes,
  ProcessImpactConfiguration,
  SaveProcessNames,
} from "../../models/Processes";
import { doPost, doPut, doGet } from "../../utils";
import { getApiUrl } from "../../utils/application-utils";

export const createOrUpdateProcess = (
  archetypeId: string,
  process: SaveProcessNames
): Promise<any> => {
  return process.processes[0].id === 0
    ? doPost(`${getApiUrl()}/process/${archetypeId}/create/`, process)
    : doPut(`${getApiUrl()}/process/${archetypeId}/update/`, process);
};

export const getProcesses = (archetypeId: string): Promise<Processes> =>
  doGet(`${getApiUrl()}/process/${archetypeId}`);

export const getLastVersionProcesses = (siteId: string): Promise<Processes> =>
  doGet(`${getApiUrl()}/siteprocess/get-previous-version-process/${siteId}`);

export const configureProcesssImpact = (
  archetypeId: string,
  processImpact: ProcessImpactConfiguration
): Promise<any> =>
  doPost(
    `${getApiUrl()}/process/${archetypeId}/impact/configure/`,
    processImpact
  );

export const getMappedDependencies = (archetypeId: string): Promise<any> =>
  doGet(`${getApiUrl()}/process/${archetypeId}/dependencies/`);

export const saveMappedDependencies = (archetypeId: string, mappings: any): Promise<any> =>
doPost(`${getApiUrl()}/process/${archetypeId}/dependency/configure`, mappings);