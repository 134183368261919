import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { SiteContext } from "./SiteGuard";
import { UserMappedSites } from "../../models/SiteDashboard";
import { loadingIndicator } from "../../components/common/loading";
import SiteBCM from "./SiteBCM";
import { getSingleSiteInformation } from "../../services/SiteDashboard/Index";
import { toastError } from "../../components/common/toast";

export default function SiteGuardCustomised() {
  const { id } = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [isUserAssignedToSite, setIsUserAssignedToSite] =
    useState<boolean>(false);
  const [userMappedSite, setUserMappedSite] = useState<any>();

  useEffect(() => {
    getSiteInformation(Number(id));
  }, [id]);

  const getSiteInformation = (id: number) => {
    setLoading(true);
    getSingleSiteInformation(Number(id))
      .then((data) => {
        setUserMappedSite(data);
        setIsUserAssignedToSite(data.isAssignedToUser);
        setLoading(false);
      })
      .catch((error) => {
        toastError(error);
      });
  };

  const updateSite = (site: UserMappedSites) => {
    getSiteInformation(site.id);
  };

  const contextValue = useMemo(() => {
    return {
      site: userMappedSite ?? ({} as UserMappedSites),
      updateSite,
    };
  }, [userMappedSite]);

  if (loading) return loadingIndicator;

  return (
    <SiteContext.Provider value={contextValue}>
      <SiteBCM isSiteAssignedToUser={isUserAssignedToSite} />
    </SiteContext.Provider>
  );
}
